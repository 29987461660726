import React, { useEffect } from "react";
import Banner from "./Banner";
import NewArrivals from "./NewArrivals";
import FeaturedProducts from "./FeaturedProducts";
import TopSellingProducts from "./TopSellingProducts";
import VideoSection from "./VideoSection";

function Home() {

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        <Banner></Banner>
        <VideoSection></VideoSection>
        <NewArrivals></NewArrivals>
        <FeaturedProducts></FeaturedProducts>
        <TopSellingProducts></TopSellingProducts>
        <section className="facility-area pb-70">
          <div className="container">
            <div className="facility-slides owl-carousel owl-theme">
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-tracking" />
                </div>
                <h3>Free Shipping Worldwide</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-return" />
                </div>
                <h3>Easy Return Policy</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-shuffle" />
                </div>
                <h3>7 Day Exchange Policy</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-sale" />
                </div>
                <h3>Weekend Discount Coupon</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-credit-card" />
                </div>
                <h3>Secure Payment Methods</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-location" />
                </div>
                <h3>Track Your Package</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-customer-service" />
                </div>
                <h3>24/7 Customer Support</h3>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
