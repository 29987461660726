import React, { useEffect, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const ProductDetails = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const location = useLocation();
  const [product, setProduct] = useState(location.state?.product);

  useEffect(() => {
    if (!product) {
      const storedProduct = localStorage.getItem("selectedProduct");
      if (storedProduct) {
        setProduct(JSON.parse(storedProduct));
      }
    }
  }, [product]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/main.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!product) {
    return <div>No product data found.</div>;
  }

  // Extract variant images
  const variantImages = product.variants?.[0]?.digital_assets?.filter(
    (asset) => asset.type === "image"
  );

  return (
    <div>
      <section className="product-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="products-details-image">
                <ul className="products-details-image-slides">
                  {variantImages?.map((image, index) => (
                    <li key={index}>
                      <img src={image.url} alt={`Product ${index + 1}`} />
                    </li>
                  ))}
                </ul>
                <div className="slick-thumbs">
                  <ul>
                    {variantImages?.map((image, index) => (
                      <li key={index}>
                        <img src={image.url} alt={`Thumbnail ${index + 1}`} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="products-details-desc">
                <h3>{product.product_name || "Product Name"}</h3>
                <div className="price">
                  <span className="new-price">
                    {product.variants?.[0]?.sku || "Price N/A"}
                  </span>
                  {/* Display old price if available */}
                </div>
                <ul className="products-info">
                  <li>
                    <span>Master Code : </span>{" "}
                    <Link to="/Home">{product.master_code || "N/A"}</Link>
                  </li>
                  <li>
                    <span>Minimum Order Quantity: </span>{" "}
                    <Link to="/Home">{50 || "In stock"}</Link>
                  </li>
                  <li>
                    <span>Product Type:</span>{" "}
                    <Link to="/">{product.product_class || "N/A"}</Link>
                  </li>
                  <li>
                    <span>Material : </span> {product.material || "N/A"}
                  </li>
                  <li>
                    <span>Dimensions : </span> {product.dimensions || "N/A"}
                  </li>
                  <li>
                    <span>Available Stock : </span>{" "}
                    {product.variants?.[0]?.available_quantity || "N/A"}
                  </li>
                  <li>
                    <span>Unit Price : </span>
                    {product.variants?.[0]?.final_price_with_margin} AED
                  </li>
                  <li>
                    <span>Shipping Cost : </span> 150 AED Inside UAE | 300 AED
                    Outside UAE
                  </li>
                </ul>

                <div className="products-info-btn"></div>
                <div className="products-add-to-cart">
                  <div className="input-counter">
                    <span className="minus-btn">
                      <i className="bx bx-minus" />
                    </span>
                    <input type="text" defaultValue={1} />
                    <span className="plus-btn">
                      <i className="bx bx-plus" />
                    </span>
                  </div>
                  <button type="submit" className="default-btn">
                    <i className="fas fa-cart-plus" /> Add to Cart
                  </button>
                </div>
                <div className="buy-checkbox-btn">
                  <div className="item">
                    <Link to="/CheckOut" className="default-btn">
                      Buy it now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab products-details-tab">
            <ul className="tabs">
              <li>
                <Link to="/Home">
                  <div className="dot" /> Description
                </Link>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tabs-item">
                <div className="products-details-tab-content">
                  <p>
                    1.{" "}
                    {product.short_description || "No description available."}
                  </p>
                  <br></br>
                  <p>
                    2. {product.long_description || "No description available."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Product Details Area */}
    </div>
  );
};

export default ProductDetails;
