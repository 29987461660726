import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Contact() {

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        {/* Start Page Title */}
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>Contact Us</h2>
              <ul>
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Title */}
        {/* Start Contact Area */}
        <section className="contact-area ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="contact-form">
                  <h3>Drop Us A Line</h3>
                  <p>
                    We're happy to answer any questions you have or provide you
                    with an estimate. Just send us a message in the form below
                    with any questions you may have.
                  </p>
                  <form id="contactForm">
                    <div className="row">
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Name <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            required
                            data-error="Please enter your name"
                            placeholder="Your name"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Email <span>*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            required
                            data-error="Please enter your email"
                            placeholder="Your email address"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Phone Number <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            className="form-control"
                            required
                            data-error="Please enter your phone number"
                            placeholder="Your phone number"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Your Message <span>*</span>
                          </label>
                          <textarea
                            name="message"
                            id="message"
                            cols={30}
                            rows={5}
                            required
                            data-error="Please enter your message"
                            className="form-control"
                            placeholder="Write your message..."
                            defaultValue={""}
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button type="submit" className="default-btn">
                          Send Message
                        </button>
                        <div id="msgSubmit" className="h3 text-center hidden" />
                        <div className="clearfix" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Area */}
      </div>
    </div>
  );
}

export default Contact;
