import React, { useEffect } from "react";

function Cart() {

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>Cart</div>
  )
}

export default Cart