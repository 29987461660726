const AccessoriesProducts = [
  {
    main_category_name: "Games",
    category_name: "Bird-House-Kit",
    master_code: "MO2130",
    master_id: "40009690",
    type_of_products: "stock",
    commodity_code: "4421 9999",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "PAINTHOUSE",
    category_code: "MOBWRI_WRC",
    product_class: "Sport & receation accessories",
    dimensions: "10X10X13CM",
    length: "10",
    length_unit: "cm",
    width: "10",
    width_unit: "cm",
    height: "13",
    height_unit: "cm",
    volume: "0.51",
    volume_unit: "cdm3",
    gross_weight: "0.125",
    gross_weight_unit: "kg",
    net_weight: "0.115",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.36",
    carton_length_unit: "m",
    carton_width: "0.26",
    carton_width_unit: "m",
    carton_height: "0.545",
    carton_height_unit: "m",
    carton_volume: "0.051",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2130-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2130-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2130-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo2130-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "DIY wooden bird house kit",
    long_description:
      "DIY wooden birdhouse kit in poplar wood. Includes: 1 brushes and 6 water-based pigments. Pre-cut wooden pieces for self-assembly.",
    material: "Wood",
    printable: "yes",
    variants: [
      {
        variant_id: "10235980",
        sku: "MO2130-40",
        release_date: "2023-12-22",
        product_proposition_category: "242",
        category_level1: "Sports & Recreation",
        category_level2: "Outdoor",
        category_level3: "Gardening",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941069350",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/1-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/1-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2130-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2130-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2130-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2130-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2130-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2130-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2130-40-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2130-40-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2130-40-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2130-40-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2130-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2130-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2130-40-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2130-40-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2130-40-picture1.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2130-40-picture1.jpg",
            type: "image",
            subtype: "item_picture_1",
          },
        ],
        available_quantity: 24883,
        price_in_aed: "8.68",
        final_price_with_margin: "26.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Grow-House-Kit",
    master_code: "MO2223",
    master_id: "40009780",
    type_of_products: "stock",
    commodity_code: "1209 9999",
    number_of_print_positions: "5",
    country_of_origin: "PL",
    brand: "midocean",
    product_name: "FULLHOUSE",
    category_code: "MOBL&G_PLA",
    product_class: "Kids & Games accessories",
    dimensions: "6,3X6,3X11,5 CM",
    length: "6.3",
    length_unit: "cm",
    width: "6.3",
    width_unit: "cm",
    height: "11.5",
    height_unit: "cm",
    volume: "0.46",
    volume_unit: "cdm3",
    gross_weight: "0.026",
    gross_weight_unit: "kg",
    net_weight: "0.02",
    net_weight_unit: "kg",
    outer_carton_quantity: "200",
    carton_length: "0.64",
    carton_length_unit: "m",
    carton_width: "0.6",
    carton_width_unit: "m",
    carton_height: "0.24",
    carton_height_unit: "m",
    carton_volume: "0.092",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.3",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2223-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo2223-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "House shaped seeds grow set",
    long_description:
      "Grow house set for kids. Including honey flower seeds and 424colour wooden pencil.",
    material: "Paper",
    themes: "Promotion7",
    printable: "yes",
    variants: [
      {
        variant_id: "10236121",
        sku: "MO2223-99",
        release_date: "2023-12-22",
        product_proposition_category: "242",
        category_level1: "Sports & Recreation",
        category_level2: "Outdoor",
        category_level3: "Gardening",
        color_description: "Multicolour",
        color_group: "Mix",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941071292",
        color_code: "99",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/2-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/2-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2223-99.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2223-99.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2223-99-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2223-99-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2223-99-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2223-99-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2223-99-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2223-99-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2223-99-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2223-99-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2223-99-picture2.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2223-99-picture2.jpg",
            type: "image",
            subtype: "item_picture_2",
          },
        ],
        available_quantity: 4424,
        price_in_aed: "7.16",
        final_price_with_margin: "23.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Ball-Catch-Game",
    master_code: "KC1312",
    master_id: "40000938",
    type_of_products: "stock",
    commodity_code: "9506 9990",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SYDNEY",
    category_code: "MOBL&G_GAMBEA",
    product_class: "Kids & Games accessories",
    dimensions: "Ø23X2 CM",
    length: "0",
    length_unit: "cm",
    width: "23",
    width_unit: "cm",
    height: "2",
    height_unit: "cm",
    volume: "0.371",
    volume_unit: "cdm3",
    gross_weight: "0.075",
    gross_weight_unit: "kg",
    net_weight: "0.067",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "100",
    carton_length: "0.49",
    carton_length_unit: "m",
    carton_width: "0.25",
    carton_width_unit: "m",
    carton_height: "0.31",
    carton_height_unit: "m",
    carton_volume: "0.038",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.1",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/kc1312-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/kc1312-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/kc1312-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Frisbee 23 cm",
    long_description: "Frisbee 23 cm.",
    material: "PP",
    printable: "yes",
    variants: [
      {
        variant_id: "10131933",
        sku: "KC1312-06",
        release_date: "2015-01-01",
        product_proposition_category: "255",
        category_level1: "Sports & Recreation",
        category_level2: "Beach items",
        category_level3: "Beach games",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941015012",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/3-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/3-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc1312-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc1312-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
        ],
        available_quantity: 7049,
        price_in_aed: "3.48",
        final_price_with_margin: "17.50",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Frisbee",
    master_code: "IT3852",
    master_id: "40000859",
    type_of_products: "stock",
    commodity_code: "9506 9990",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "CATCH&PLAY",
    category_code: "MOBL&G_GAMBEA",
    product_class: "Kids & Games accessories",
    dimensions: "Ø15X2 CM",
    length: "0",
    length_unit: "cm",
    width: "15",
    width_unit: "cm",
    height: "2",
    height_unit: "cm",
    volume: "0.752",
    volume_unit: "cdm3",
    gross_weight: "0.069",
    gross_weight_unit: "kg",
    net_weight: "0.054",
    net_weight_unit: "kg",
    inner_carton_quantity: "100",
    outer_carton_quantity: "200",
    carton_length: "0.76",
    carton_length_unit: "m",
    carton_width: "0.33",
    carton_width_unit: "m",
    carton_height: "0.63",
    carton_height_unit: "m",
    carton_volume: "0.158",
    carton_volume_unit: "m3",
    carton_gross_weight: "13.22",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/it3852-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/it3852-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/it3852-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/it3852-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Suction ball catch set",
    long_description:
      "Ball catch game including one suction ball and a pair of suction ball holders.",
    material: "PP",
    printable: "yes",
    variants: [
      {
        variant_id: "10168090",
        sku: "IT3852-04",
        release_date: "2015-01-01",
        product_proposition_category: "255",
        category_level1: "Sports & Recreation",
        category_level2: "Beach items",
        category_level3: "Beach games",
        color_description: "Blue",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941014824",
        color_code: "04",
        pms_color: "2171C",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/4-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/4-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it3852-04.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it3852-04.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it3852-04-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it3852-04-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it3852-04-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it3852-04-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 19326,
        price_in_aed: "5.28",
        final_price_with_margin: "22.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Beach-Tennis-Set",
    master_code: "IT1911",
    master_id: "40000305",
    type_of_products: "stock",
    commodity_code: "9506 9990",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "MATCH",
    category_code: "MOBL&G_GAMBEA",
    product_class: "Kids & Games accessories",
    dimensions: "37,5X23,6X0,6 CM",
    length: "37.5",
    length_unit: "cm",
    width: "23.6",
    width_unit: "cm",
    height: "0.6",
    height_unit: "cm",
    volume: "1.84",
    volume_unit: "cdm3",
    gross_weight: "0.575",
    gross_weight_unit: "kg",
    net_weight: "0.536",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.39",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.24",
    carton_height_unit: "m",
    carton_volume: "0.036",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-02T12:10:57",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/it1911-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/it1911-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/it1911-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Beach tennis set",
    long_description:
      "Beach tennis set consisting of 2 MDF rackets and 1 soft ball.",
    material: "MDF",
    printable: "yes",
    variants: [
      {
        variant_id: "10175000",
        sku: "IT1911-03",
        release_date: "2015-01-01",
        product_proposition_category: "255",
        category_level1: "Sports & Recreation",
        category_level2: "Beach items",
        category_level3: "Beach games",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941013360",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/5-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/5-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it1911-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it1911-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
        ],
        available_quantity: 9977,
        price_in_aed: "23.60",
        final_price_with_margin: "55.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Beach-Ball",
    master_code: "IT1627",
    master_id: "40000291",
    type_of_products: "stock",
    commodity_code: "9503 0095",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "PLAYTIME",
    category_code: "MOBL&G_BEABLL",
    product_class: "Sport & receation accessories",
    dimensions: "Ø23,5 CM",
    length: "0",
    length_unit: "cm",
    width: "23.5",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.15",
    volume_unit: "cdm3",
    gross_weight: "0.039",
    gross_weight_unit: "kg",
    net_weight: "0.032",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "300",
    carton_length: "0.415",
    carton_length_unit: "m",
    carton_width: "0.34",
    carton_width_unit: "m",
    carton_height: "0.31",
    carton_height_unit: "m",
    carton_volume: "0.044",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.4",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/it1627-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/it1627-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/it1627-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/it1627-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Inflatable beach ball",
    long_description:
      "Inflatable beach ball with coloured stripes. Inflated: Ø23,5cm",
    material: "PVC",
    printable: "yes",
    variants: [
      {
        variant_id: "10114013",
        sku: "IT1627-04",
        release_date: "2015-01-01",
        product_proposition_category: "252",
        category_level1: "Sports & Recreation",
        category_level2: "Beach items",
        category_level3: "Inflatables",
        color_description: "Blue",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941013179",
        color_code: "04",
        pms_color: "285C",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/6-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/6-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it1627-04.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it1627-04.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it1627-04-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it1627-04-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
        ],
        available_quantity: 19696,
        price_in_aed: "2.92",
        final_price_with_margin: "14.50",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Color-Pencil-Set",
    master_code: "IT2691",
    master_id: "40000391",
    type_of_products: "stock",
    commodity_code: "9609 1090",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "COLORET",
    category_code: "MOBWRI_PECCOL",
    product_class: "Art paint set",
    dimensions: "Ø3,5X13,5 CM",
    length: "0",
    length_unit: "cm",
    width: "3.5",
    width_unit: "cm",
    height: "13.5",
    height_unit: "cm",
    volume: "0.256",
    volume_unit: "cdm3",
    gross_weight: "0.074",
    gross_weight_unit: "kg",
    net_weight: "0.064",
    net_weight_unit: "kg",
    inner_carton_quantity: "25",
    outer_carton_quantity: "200",
    carton_length: "0.41",
    carton_length_unit: "m",
    carton_width: "0.41",
    carton_width_unit: "m",
    carton_height: "0.3",
    carton_height_unit: "m",
    carton_volume: "0.05",
    carton_volume_unit: "m3",
    carton_gross_weight: "14.64",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/it2691-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/it2691-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/it2691-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/it2691-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "12 pencils in wooden box",
    long_description: "12 wooden pencils in pencil shaped wooden box.",
    material: "Wood",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10139201",
        sku: "IT2691-40",
        release_date: "2015-01-01",
        product_proposition_category: "262",
        category_level1: "Kids & Games",
        category_level2: "Painting",
        category_level3: "Colour pencils",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941013759",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/7-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/7-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it2691-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it2691-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it2691-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it2691-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it2691-40c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it2691-40c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it2691-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it2691-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
        available_quantity: 16577,
        price_in_aed: "5.24",
        final_price_with_margin: "19.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Yoyo",
    master_code: "KC2937",
    master_id: "40001050",
    type_of_products: "stock",
    commodity_code: "9503 0099",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "NATUS",
    category_code: "MOBL&G_GAMOTH",
    product_class: "Games & Events",
    dimensions: "Ø5X2.5CM",
    length: "0",
    length_unit: "cm",
    width: "5",
    width_unit: "cm",
    height: "2.5",
    height_unit: "cm",
    volume: "0.085",
    volume_unit: "cdm3",
    gross_weight: "0.03",
    gross_weight_unit: "kg",
    net_weight: "0.024",
    net_weight_unit: "kg",
    outer_carton_quantity: "200",
    carton_length: "0.27",
    carton_length_unit: "m",
    carton_width: "0.22",
    carton_width_unit: "m",
    carton_height: "0.29",
    carton_height_unit: "m",
    carton_volume: "0.017",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.84",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/kc2937-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/kc2937-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/kc2937-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Wooden yoyo",
    long_description: "Wooden yoyo.",
    material: "Wood",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10100543",
        sku: "KC2937-40",
        release_date: "2015-01-01",
        product_proposition_category: "260",
        category_level1: "Kids & Games",
        category_level2: "Games",
        category_level3: "Outdoor",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941015623",
        color_code: "40",
        pms_color: "WOOD",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/8-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/8-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2937-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2937-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2937-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2937-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2937-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2937-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
        available_quantity: 251420,
        price_in_aed: "1.80",
        final_price_with_margin: "12.50",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "4-In-1-Game",
    master_code: "KC2941",
    master_id: "40001053",
    type_of_products: "stock",
    commodity_code: "9504 9080",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TRIKES",
    category_code: "MOBL&G_GAMTAB",
    product_class: "Games & Events",
    dimensions: "16X16X3CM",
    length: "16",
    length_unit: "cm",
    width: "16",
    width_unit: "cm",
    height: "3",
    height_unit: "cm",
    volume: "1.06",
    volume_unit: "cdm3",
    gross_weight: "0.246",
    gross_weight_unit: "kg",
    net_weight: "0.223",
    net_weight_unit: "kg",
    inner_carton_quantity: "25",
    outer_carton_quantity: "50",
    carton_length: "0.355",
    carton_length_unit: "m",
    carton_width: "0.355",
    carton_width_unit: "m",
    carton_height: "0.42",
    carton_height_unit: "m",
    carton_volume: "0.053",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.9",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/kc2941-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/kc2941-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/kc2941-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/kc2941-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "4 games in wooden box",
    long_description:
      "Wooden box including domino, chess, drafts and wooden sticks games.",
    material: "Wood",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10101188",
        sku: "KC2941-40",
        release_date: "2015-01-01",
        product_proposition_category: "404",
        category_level1: "Kids & Games",
        category_level2: "Games",
        category_level3: "Games",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941015647",
        color_code: "40",
        pms_color: "WOOD",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/9-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/9-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2941-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2941-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2941-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2941-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
        ],
        available_quantity: 18862,
        price_in_aed: "16.92",
        final_price_with_margin: "40.50",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Ludo-Game",
    master_code: "MO6110",
    master_id: "40007460",
    type_of_products: "stock",
    commodity_code: "9504 9080",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "LUDO",
    category_code: "MOBL&G_GAMOTH",
    product_class: "Games & Events",
    dimensions: "12X12X1,5 CM",
    length: "12",
    length_unit: "cm",
    width: "12",
    width_unit: "cm",
    height: "1.5",
    height_unit: "cm",
    volume: "0.536",
    volume_unit: "cdm3",
    gross_weight: "0.113",
    gross_weight_unit: "kg",
    net_weight: "0.085",
    net_weight_unit: "kg",
    outer_carton_quantity: "80",
    carton_length: "0.515",
    carton_length_unit: "m",
    carton_width: "0.265",
    carton_width_unit: "m",
    carton_height: "0.3",
    carton_height_unit: "m",
    carton_volume: "0.041",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.06",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6110-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6110-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6110-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo6110-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Ludo game",
    long_description: "Wooden Ludo game. Presented in cotton pouch.",
    material: "Wood",
    commercial_description:
      "A classic strategy board game on a compact set. Ludo is a fast and exciting race to the finish line strategy-based board game for two to four players. Players race their four coloured tokens from start to finish according to the rolls of a single dice. The aim is to get your tokens to designated home space first. The game is presented in a cotton pouch and includes 1 dice and 16 tokens in various colours.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10214382",
        sku: "MO6110-40",
        release_date: "2020-01-01",
        product_proposition_category: "404",
        category_level1: "Kids & Games",
        category_level2: "Games",
        category_level3: "Games",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941047976",
        color_code: "40",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/10-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/10-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6110-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6110-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6110-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6110-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6110-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6110-40-side.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6110-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6110-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6110-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6110-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 16952,
        price_in_aed: "10.36",
        final_price_with_margin: "27.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Pine-Wood-Throwing-Game",
    master_code: "MO6396",
    master_id: "40008293",
    type_of_products: "stock",
    commodity_code: "9504 9080",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "KING",
    category_code: "MOBL&G_GAMOTH",
    product_class: "Kids & Games accessories",
    dimensions: "34,3X34CM",
    length: "34.3",
    length_unit: "cm",
    width: "34",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "5.375",
    volume_unit: "cdm3",
    gross_weight: "1.725",
    gross_weight_unit: "kg",
    net_weight: "1.66",
    net_weight_unit: "kg",
    outer_carton_quantity: "8",
    carton_length: "0.39",
    carton_length_unit: "m",
    carton_width: "0.39",
    carton_width_unit: "m",
    carton_height: "0.28",
    carton_height_unit: "m",
    carton_volume: "0.043",
    carton_volume_unit: "m3",
    carton_gross_weight: "13.32",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6396-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6396-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6396-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo6396-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Pinewood outdoor throwing game",
    long_description:
      "Pine wood throwing game in cotton drawcord bag. Includes 10pcs short square stick, 4pcs pointy stick, 6pcs round stick and 1pc King stick.",
    material: "Wood",
    commercial_description:
      "Pine wood throwing game presented in a cotton drawcord bag. This game, ofSwedish and Scandinavian origin, is fun for kids, families or a group of friendsand is commonly played outdoors. The goal is to knock over the larger woodensticks by throwing the smaller ones at them. The centre piece, the king, will beknocked over last. The first one to knock them all over before the opponentdoes, wins. Includes 10pcs short square stick, 4pcs pointy stick, 6pcs roundstick and 1pc King stick.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10221862",
        sku: "MO6396-40",
        release_date: "2021-12-24",
        product_proposition_category: "260",
        category_level1: "Kids & Games",
        category_level2: "Games",
        category_level3: "Outdoor",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941055438",
        color_code: "40",
        pms_color: "WOOD",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/11-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/11-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6396-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6396-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6396-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6396-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6396-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6396-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6396-40-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6396-40-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6396-40-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6396-40-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6396-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6396-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6396-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6396-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6396-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6396-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 2107,
        price_in_aed: "85.60",
        final_price_with_margin: "173.50",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Puzzle-Game",
    master_code: "MO6693",
    master_id: "40008790",
    type_of_products: "stock",
    commodity_code: "9503 0061",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TANGRAM",
    category_code: "MOBL&G_GAMWOD",
    product_class: "Kids & Games accessories",
    dimensions: "10X10X0,6CM",
    length: "10",
    length_unit: "cm",
    width: "10",
    width_unit: "cm",
    height: "0.6",
    height_unit: "cm",
    volume: "0.1",
    volume_unit: "cdm3",
    gross_weight: "0.04",
    gross_weight_unit: "kg",
    net_weight: "0.032",
    net_weight_unit: "kg",
    outer_carton_quantity: "300",
    carton_length: "0.435",
    carton_length_unit: "m",
    carton_width: "0.225",
    carton_width_unit: "m",
    carton_height: "0.425",
    carton_height_unit: "m",
    carton_volume: "0.042",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.82",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6693-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6693-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6693-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo6693-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Tangram puzzle in wood",
    long_description: "Coloured wooden tangram puzzle game. 7 pieces.",
    material: "Wood",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223029",
        sku: "MO6693-40",
        release_date: "2022-12-26",
        product_proposition_category: "404",
        category_level1: "Kids & Games",
        category_level2: "Games",
        category_level3: "Games",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941060951",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/12-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/12-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6693-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6693-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6693-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6693-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6693-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6693-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6693-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6693-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6693-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6693-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 43960,
        price_in_aed: "2.44",
        final_price_with_margin: "13.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Labyrinth-Game",
    master_code: "MO6696",
    master_id: "40008793",
    type_of_products: "stock",
    commodity_code: "9504 9080",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ZUKY",
    category_code: "MOBL&G_GAMWOD",
    product_class: "Kids & Games accessories",
    dimensions: "9X9X2,5CM",
    length: "9",
    length_unit: "cm",
    width: "9",
    width_unit: "cm",
    height: "2.5",
    height_unit: "cm",
    volume: "0.3",
    volume_unit: "cdm3",
    gross_weight: "0.05",
    gross_weight_unit: "kg",
    net_weight: "0.041",
    net_weight_unit: "kg",
    outer_carton_quantity: "250",
    carton_length: "0.5",
    carton_length_unit: "m",
    carton_width: "0.21",
    carton_width_unit: "m",
    carton_height: "0.85",
    carton_height_unit: "m",
    carton_volume: "0.089",
    carton_volume_unit: "m3",
    carton_gross_weight: "15.62",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6696-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6696-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6696-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo6696-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Pine wooden labyrinth game",
    long_description:
      "Pine wood labyrinth game. It has 14 holes with 1 metal ball. Roll the ball around the course avoiding the holes.",
    material: "Wood",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223032",
        sku: "MO6696-40",
        release_date: "2022-12-26",
        product_proposition_category: "404",
        category_level1: "Kids & Games",
        category_level2: "Games",
        category_level3: "Games",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941060982",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/13-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/13-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6696-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6696-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6696-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6696-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6696-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6696-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6696-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6696-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6696-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6696-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 24452,
        price_in_aed: "5.48",
        final_price_with_margin: "18.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Piggy-Banks",
    master_code: "MO8132",
    master_id: "40003217",
    type_of_products: "stock",
    commodity_code: "3926 9097",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SOFTCO",
    category_code: "MOBH&L_DECOTH",
    product_class: "Money box",
    dimensions: "9X7X7 CM",
    length: "9",
    length_unit: "cm",
    width: "7",
    width_unit: "cm",
    height: "7",
    height_unit: "cm",
    volume: "0.71",
    volume_unit: "cdm3",
    gross_weight: "0.078",
    gross_weight_unit: "kg",
    net_weight: "0.061",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "100",
    carton_length: "0.5",
    carton_length_unit: "m",
    carton_width: "0.405",
    carton_width_unit: "m",
    carton_height: "0.35",
    carton_height_unit: "m",
    carton_volume: "0.071",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.14",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo8132-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8132-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo8132-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Piggy bank",
    long_description: "Piggy bank in PVC with ABS stopper on the bottom.",
    material: "PVC",
    printable: "yes",
    variants: [
      {
        variant_id: "10180876",
        sku: "MO8132-06",
        release_date: "2015-01-01",
        product_proposition_category: "308",
        category_level1: "Office & Writing",
        category_level2: "Office accessories ",
        category_level3: "Desk lights & Accessories",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941020290",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/14-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/14-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8132-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8132-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8132-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8132-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8132-06-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8132-06-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8132-06-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8132-06-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 9097,
        price_in_aed: "4.84",
        final_price_with_margin: "21.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Kids-Fleece-Blanket",
    master_code: "MO8252",
    master_id: "40003332",
    type_of_products: "stock",
    commodity_code: "6301 4010",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "MANTA",
    category_code: "MOBH&L_DECBLK",
    product_class: "Travel accessories",
    dimensions: "120X80 CM",
    length: "120",
    length_unit: "cm",
    width: "80",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "2.5",
    volume_unit: "cdm3",
    gross_weight: "0.241",
    gross_weight_unit: "kg",
    net_weight: "0.22",
    net_weight_unit: "kg",
    outer_carton_quantity: "30",
    carton_length: "0.41",
    carton_length_unit: "m",
    carton_width: "0.41",
    carton_width_unit: "m",
    carton_height: "0.43",
    carton_height_unit: "m",
    carton_volume: "0.072",
    carton_volume_unit: "m3",
    carton_gross_weight: "6.74",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo8252-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8252-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo8252-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Fleece blanket with bear",
    long_description:
      "Children's fleece blanket with teddy bear toy holding the blanket with fastening tape hands.",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10181299",
        sku: "MO8252-06",
        release_date: "2015-01-01",
        product_proposition_category: "267",
        category_level1: "Kids & Games",
        category_level2: "Stuffed animals",
        category_level3: "Blankets",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941020795",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/15-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/15-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8252-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8252-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8252-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8252-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
        ],
        available_quantity: 2425,
        price_in_aed: "21.92",
        final_price_with_margin: "52.50",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Teddy-Bear-Push",
    master_code: "MO8253",
    master_id: "40003333",
    type_of_products: "stock",
    commodity_code: "9503 0041",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "NIL",
    category_code: "MOBP&S_SAN",
    product_class: "Kids & Games accessories",
    dimensions: "5X9 CM",
    length: "5",
    length_unit: "cm",
    width: "9",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.256",
    volume_unit: "cdm3",
    gross_weight: "0.022",
    gross_weight_unit: "kg",
    net_weight: "0.018",
    net_weight_unit: "kg",
    outer_carton_quantity: "250",
    carton_length: "0.55",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.37",
    carton_height_unit: "m",
    carton_volume: "0.061",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.36",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8253-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo8253-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Teddy bear key ring",
    long_description:
      "Teddy bear plush key ring. 100% cotton t-shirt for logo imprint.",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10181302",
        sku: "MO8253-06",
        release_date: "2015-01-01",
        product_proposition_category: "266",
        category_level1: "Kids & Games",
        category_level2: "Stuffed animals",
        category_level3: "Teddy bears & Others",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941020825",
        color_code: "06",
        pms_color: "BODY 728C, SHIRT WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/16-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/16-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8253-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8253-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8253-06-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8253-06-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
        available_quantity: 20404,
        price_in_aed: "5.28",
        final_price_with_margin: "18.00",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Brain-Teaser",
    master_code: "MO8931",
    master_id: "40006168",
    type_of_products: "stock",
    commodity_code: "9503 0061",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "STARNATS",
    category_code: "MOBL&G_GAMWOD",
    product_class: "Games & Events",
    dimensions: "5X5CM",
    length: "5",
    length_unit: "cm",
    width: "5",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.255",
    volume_unit: "cdm3",
    gross_weight: "0.063",
    gross_weight_unit: "kg",
    net_weight: "0.059",
    net_weight_unit: "kg",
    outer_carton_quantity: "120",
    carton_length: "0.4",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.23",
    carton_height_unit: "m",
    carton_volume: "0.028",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.44",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo8931-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8931-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo8931-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Star brain teaser",
    long_description:
      "Wooden brain teaser in star shape. Presented in a cotton pouch.",
    material: "Wood",
    commercial_description:
      "A fun puzzle that leaves you with a nice decoration for your desk at the office or at home  It is important to pay attention to how the star comes apart in order to put it back together again. This game is presented in a cotton pouch.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10205645",
        sku: "MO8931-40",
        release_date: "2017-01-01",
        product_proposition_category: "404",
        category_level1: "Kids & Games",
        category_level2: "Games",
        category_level3: "Games",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941027862",
        color_code: "40",
        pms_color: "WOOD COLOR",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/17-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/17-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8931-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8931-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8931-40a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8931-40a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8931-40c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8931-40c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8931-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8931-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8931-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8931-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 53607,
        price_in_aed: "5.04",
        final_price_with_margin: "18.50",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Mini-Football-Table-Game",
    master_code: "MO9192",
    master_id: "40006681",
    type_of_products: "stock",
    commodity_code: "9504 9080",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "FUTBOL#N",
    category_code: "MOBL&G_GAMOTH",
    product_class: "Kitchen accessories",
    dimensions: "50X52X9CM",
    length: "50",
    length_unit: "cm",
    width: "52",
    width_unit: "cm",
    height: "9",
    height_unit: "cm",
    volume: "14.6",
    volume_unit: "cdm3",
    gross_weight: "2.7",
    gross_weight_unit: "kg",
    net_weight: "1.97",
    net_weight_unit: "kg",
    outer_carton_quantity: "5",
    carton_length: "0.54",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.069",
    carton_volume_unit: "m3",
    carton_gross_weight: "13.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9192-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9192-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9192-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo9192-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Mini football table",
    long_description: "Mini football table game. Some assembly required.",
    material: "MDF",
    printable: "yes",
    variants: [
      {
        variant_id: "10208374",
        sku: "MO9192-99",
        release_date: "2018-01-01",
        product_proposition_category: "248",
        category_level1: "Sports & Recreation",
        category_level2: "Events",
        category_level3: "Football",
        color_description: "Multicolour",
        color_group: "Mix",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941004221",
        color_code: "99",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/18-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/18-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9192-99.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9192-99.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9192-99a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9192-99a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9192-99c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9192-99c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
        available_quantity: 1084,
        price_in_aed: "128.04",
        final_price_with_margin: "273.50",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Tic-Tac-Toe",
    master_code: "MO9493",
    master_id: "40007054",
    type_of_products: "stock",
    commodity_code: "9504 9080",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TIC TAC TOE",
    category_code: "MOBL&G_GAMOTH",
    product_class: "Kids & Games accessories",
    dimensions: "7X7X2.5CM",
    length: "7",
    length_unit: "cm",
    width: "7",
    width_unit: "cm",
    height: "2.5",
    height_unit: "cm",
    volume: "0.253",
    volume_unit: "cdm3",
    gross_weight: "0.064",
    gross_weight_unit: "kg",
    net_weight: "0.058",
    net_weight_unit: "kg",
    inner_carton_quantity: "40",
    outer_carton_quantity: "80",
    carton_length: "0.32",
    carton_length_unit: "m",
    carton_width: "0.065",
    carton_width_unit: "m",
    carton_height: "0.29",
    carton_height_unit: "m",
    carton_volume: "0.006",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.08",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9493-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9493-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9493-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo9493-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Wooden tic tac toe",
    long_description: "Wooden tic tac toe game set.",
    material: "Wood",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10210395",
        sku: "MO9493-40",
        release_date: "2019-01-01",
        product_proposition_category: "404",
        category_level1: "Kids & Games",
        category_level2: "Games",
        category_level3: "Games",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941035812",
        color_code: "40",
        pms_color: "NATURE WOOD COLOR",
        digital_assets: [
          {
            url: "/assets/img/accessories/games/19-with-logo.jpg",
            url_highress: "/assets/img/accessories/games/19-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9493-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9493-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9493-40a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9493-40a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9493-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9493-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
        available_quantity: 38030,
        price_in_aed: "4.48",
        final_price_with_margin: "18.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Backpacks-Laptop-Bags",
    master_code: "MO2125",
    master_id: "40009686",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "COOLPACK",
    category_code: "MOBT&B_LAP",
    product_class: "Laptop bag",
    dimensions: "45X19X31CM",
    length: "45",
    length_unit: "cm",
    width: "19",
    width_unit: "cm",
    height: "31",
    height_unit: "cm",
    volume: "7.6",
    volume_unit: "cdm3",
    gross_weight: "0.325",
    gross_weight_unit: "kg",
    net_weight: "0.315",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.4",
    carton_length_unit: "m",
    carton_width: "0.6",
    carton_width_unit: "m",
    carton_height: "0.48",
    carton_height_unit: "m",
    carton_volume: "0.152",
    carton_volume_unit: "m3",
    carton_gross_weight: "6.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2125-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2125-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2125-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "300D RPET Cooling backpack",
    long_description:
      "300D RPET cooling backpack with 15 inch laptop compartment. Front zipper pocket and one side mesh compartment.",
    material: "RPET",
    printable: "yes",
    variants: [
      {
        variant_id: "10235909",
        sku: "MO2125-03",
        release_date: "2023-12-22",
        product_proposition_category: "468",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Travel bags & trolleys",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941069176",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/1-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/1-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03-picture1.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03-picture1.jpg",
            type: "image",
            subtype: "item_picture_1",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03-picture2.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03-picture2.jpg",
            type: "image",
            subtype: "item_picture_2",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03-picture3.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03-picture3.jpg",
            type: "image",
            subtype: "item_picture_3",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2125-03-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2125-03-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 2170,
        price_in_aed: "47.36",
        final_price_with_margin: "112.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Backpacks-Laptop-Bags",
    master_code: "MO2231",
    master_id: "40009788",
    type_of_products: "stock",
    commodity_code: "4202 9211",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BAI BACKPACK",
    category_code: "MOBT&B_BAC",
    product_class: "Backpack",
    dimensions: "28X15X40CM",
    length: "28",
    length_unit: "cm",
    width: "15",
    width_unit: "cm",
    height: "40",
    height_unit: "cm",
    volume: "5.9",
    volume_unit: "cdm3",
    gross_weight: "0.6",
    gross_weight_unit: "kg",
    net_weight: "0.54",
    net_weight_unit: "kg",
    outer_carton_quantity: "10",
    carton_length: "0.44",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.43",
    carton_height_unit: "m",
    carton_volume: "0.059",
    carton_volume_unit: "m3",
    carton_gross_weight: "6",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2231-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2231-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2231-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: 'Laptop 15" soft PU backpack',
    long_description:
      "15 inch laptop backpack in soft PU with padded back and reinforced base. Front zipped pocket. The lining is 210D RPET.",
    material: "PU",
    printable: "yes",
    variants: [
      {
        variant_id: "10236136",
        sku: "MO2231-03",
        release_date: "2023-12-22",
        product_proposition_category: "468",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Travel bags & trolleys",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941071452",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/2-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/2-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2231-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2231-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2231-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2231-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2231-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2231-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2231-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2231-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2231-03-picture2.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2231-03-picture2.jpg",
            type: "image",
            subtype: "item_picture_2",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2231-03-picture5.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2231-03-picture5.jpg",
            type: "image",
            subtype: "item_picture_5",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2231-03-picture6.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2231-03-picture6.jpg",
            type: "image",
            subtype: "item_picture_6",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2231-03-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2231-03-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 3059,
        price_in_aed: "56.60",
        final_price_with_margin: "113.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Backpacks-Laptop-Bags",
    master_code: "MO6329",
    master_id: "40008161",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SINGAPORE",
    category_code: "MOBT&B_LAP",
    product_class: "Laptop bag",
    dimensions: "28X12X42CM",
    length: "28",
    length_unit: "cm",
    width: "12",
    width_unit: "cm",
    height: "42",
    height_unit: "cm",
    volume: "5.2",
    volume_unit: "cdm3",
    gross_weight: "0.5",
    gross_weight_unit: "kg",
    net_weight: "0.434",
    net_weight_unit: "kg",
    outer_carton_quantity: "15",
    carton_length: "0.48",
    carton_length_unit: "m",
    carton_width: "0.36",
    carton_width_unit: "m",
    carton_height: "0.6",
    carton_height_unit: "m",
    carton_volume: "0.104",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.42",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6329-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6329-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6329-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6329-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Laptop backpack in 300D RPET",
    long_description:
      "Laptop backpack in 300D RPET Polyester with padded shoulder strap including one main internal compartment for 16 inch laptop and a USB charging cable.",
    material: "PET-PU",
    printable: "yes",
    variants: [
      {
        variant_id: "10221395",
        sku: "MO6329-03",
        release_date: "2021-08-01",
        product_proposition_category: "204",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Laptop bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941054479",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/3-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/3-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6329-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6329-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6329-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6329-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6329-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6329-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6329-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6329-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6329-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6329-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6329-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6329-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6329-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6329-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6329-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6329-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 4477,
        price_in_aed: "69.48",
        final_price_with_margin: "129.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Backpacks-Laptop-Bags",
    master_code: "MO6939",
    master_id: "40009153",
    type_of_products: "stock",
    commodity_code: "4202 9211",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "JAYA BAG",
    category_code: "MOBT&B_BAC",
    product_class: "Backpack",
    dimensions: "29X13X37CM",
    length: "29",
    length_unit: "cm",
    width: "13",
    width_unit: "cm",
    height: "37",
    height_unit: "cm",
    volume: "7.2",
    volume_unit: "cdm3",
    gross_weight: "0.75",
    gross_weight_unit: "kg",
    net_weight: "0.74",
    net_weight_unit: "kg",
    outer_carton_quantity: "10",
    carton_length: "0.6",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.29",
    carton_height_unit: "m",
    carton_volume: "0.07",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6939-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6939-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6939-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Rolltop backpack 50C tarpaulin",
    long_description:
      "Rolltop backpack in 50C tarpaulin with padded back, base and shoulder straps reinforcement. Front zippered pockets.",
    material: "PVC",
    printable: "yes",
    variants: [
      {
        variant_id: "10224850",
        sku: "MO6939-03",
        release_date: "2023-07-28",
        product_proposition_category: "468",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Travel bags & trolleys",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941066373",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/4-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/4-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6939-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6939-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6939-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6939-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6939-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6939-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6939-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6939-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6939-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6939-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6939-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6939-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6939-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6939-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 0,
        price_in_aed: "59.60",
        final_price_with_margin: "133.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Backpacks-Laptop-Bags",
    master_code: "MO9294",
    master_id: "40006776",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BERLIN",
    category_code: "MOBT&B_BAC",
    product_class: "Backpack",
    dimensions: "26X13X45 CM",
    length: "26",
    length_unit: "cm",
    width: "26",
    width_unit: "cm",
    height: "13",
    height_unit: "cm",
    volume: "6.745",
    volume_unit: "cdm3",
    gross_weight: "0.622",
    gross_weight_unit: "kg",
    net_weight: "0.529",
    net_weight_unit: "kg",
    outer_carton_quantity: "10",
    carton_length: "0.47",
    carton_length_unit: "m",
    carton_width: "0.34",
    carton_width_unit: "m",
    carton_height: "0.42",
    carton_height_unit: "m",
    carton_volume: "0.067",
    carton_volume_unit: "m3",
    carton_gross_weight: "6.22",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9294-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9294-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9294-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9294-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "2 tone backpack incl USB plug",
    long_description:
      "600D 2 tone Polyester backpack with padded shoulder strap with main internal compartment. Includes one internal 13'' laptop compartment and an USB charging cable. Zipper main compartment on backside for better protection.",
    material: "PET-PVC",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10208868",
        sku: "MO9294-03",
        release_date: "2018-01-01",
        product_proposition_category: "204",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Laptop bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941011250",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/5-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/5-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9294-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9294-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9294-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9294-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9294-03c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9294-03c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9294-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9294-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9294-03d.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9294-03d.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9294-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9294-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9294-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9294-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 9899,
        price_in_aed: "51.24",
        final_price_with_margin: "112.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Backpacks-Laptop-Bags",
    master_code: "MO9439",
    master_id: "40006967",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "RIGA",
    category_code: "MOBT&B_LAP",
    product_class: "Backpack",
    dimensions: "27X14X42 CM",
    length: "27",
    length_unit: "cm",
    width: "27",
    width_unit: "cm",
    height: "14",
    height_unit: "cm",
    volume: "3.243",
    volume_unit: "cdm3",
    gross_weight: "0.61",
    gross_weight_unit: "kg",
    net_weight: "0.54",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.47",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.46",
    carton_height_unit: "m",
    carton_volume: "0.065",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.68",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9439-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9439-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9439-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
    ],
    short_description: "Backpack in 600D polyester",
    long_description:
      "Laptop backpack in 600D 2 tone polyester with padded shoulder strap including one main internal compartment for 15 inch laptop and a USB charging cable. Includes magnetic snap button closure and one zippered front compartment.",
    material: "PET-PVC",
    printable: "yes",
    variants: [
      {
        variant_id: "10210072",
        sku: "MO9439-03",
        release_date: "2018-08-01",
        product_proposition_category: "204",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Laptop bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941011212",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/6-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/6-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9439-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9439-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9439-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9439-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9439-03c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9439-03c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9439-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9439-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9439-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9439-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9439-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9439-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 6007,
        price_in_aed: "48.28",
        final_price_with_margin: "106.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Laptop-Sleeves",
    master_code: "MO2165",
    master_id: "40009723",
    type_of_products: "stock",
    commodity_code: "4202 1291",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "PLANA",
    category_code: "MOBT&B_LAP",
    product_class: "Laptop bag",
    dimensions: "40.5X7X28CM",
    length: "40.5",
    length_unit: "cm",
    width: "7",
    width_unit: "cm",
    height: "28",
    height_unit: "cm",
    volume: "4.12",
    volume_unit: "cdm3",
    gross_weight: "0.35",
    gross_weight_unit: "kg",
    net_weight: "0.34",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.45",
    carton_length_unit: "m",
    carton_width: "0.36",
    carton_width_unit: "m",
    carton_height: "0.42",
    carton_height_unit: "m",
    carton_volume: "0.103",
    carton_volume_unit: "m3",
    carton_gross_weight: "7",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2165-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2165-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2165-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "15 inch RPET felt laptop bag",
    long_description:
      "15 inch laptop bag in RPET felt including one main internal compartment and one zippered front pocket. Includes detachable and adjustable shoulder strap. Trolley holder on reverse side.",
    material: "RPET",
    printable: "yes",
    variants: [
      {
        variant_id: "10235983",
        sku: "MO2165-15",
        release_date: "2023-12-22",
        product_proposition_category: "204",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Laptop bags",
        color_description: "Stone Grey",
        color_group: "Grey",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941070141",
        color_code: "15",
        pms_color: "423U",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/7-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/7-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15-picture1.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15-picture1.jpg",
            type: "image",
            subtype: "item_picture_1",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15-picture2.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15-picture2.jpg",
            type: "image",
            subtype: "item_picture_2",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15-picture3.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15-picture3.jpg",
            type: "image",
            subtype: "item_picture_3",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15-picture6.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15-picture6.jpg",
            type: "image",
            subtype: "item_picture_6",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2165-15-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2165-15-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 4624,
        price_in_aed: "27.20",
        final_price_with_margin: "66.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Laptop-Sleeves",
    master_code: "MO2191",
    master_id: "40009748",
    type_of_products: "stock",
    commodity_code: "4202 1291",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "COTIN",
    category_code: "MOBT&B_LAP",
    product_class: "Laptop & tablet pouch",
    dimensions: "39.5X27CM",
    length: "39.5",
    length_unit: "cm",
    width: "27",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "1.26",
    volume_unit: "cdm3",
    gross_weight: "0.2",
    gross_weight_unit: "kg",
    net_weight: "0.19",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.6",
    carton_length_unit: "m",
    carton_width: "0.42",
    carton_width_unit: "m",
    carton_height: "0.25",
    carton_height_unit: "m",
    carton_volume: "0.063",
    carton_volume_unit: "m3",
    carton_gross_weight: "10",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2191-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2191-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2191-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "15 inch 220 gr/m² cotton pouch",
    long_description:
      "15 inch padded laptop pouch in  220 gr/m² cotton. Inner lining 120 gr/m². Bamboo button closure.",
    material: "Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10236054",
        sku: "MO2191-03",
        release_date: "2023-12-22",
        product_proposition_category: "204",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Laptop bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941070684",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/8-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/8-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2191-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2191-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2191-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2191-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2191-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2191-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2191-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2191-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 5387,
        price_in_aed: "15.64",
        final_price_with_margin: "36.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Laptop-Sleeves",
    master_code: "MO6186",
    master_id: "40007888",
    type_of_products: "stock",
    commodity_code: "4202 1291",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BAGLO",
    category_code: "MOBT&B_LAP",
    product_class: "Laptop bag",
    dimensions: "36X8X27 CM",
    length: "36",
    length_unit: "cm",
    width: "8",
    width_unit: "cm",
    height: "27",
    height_unit: "cm",
    volume: "2.718",
    volume_unit: "cdm3",
    gross_weight: "0.278",
    gross_weight_unit: "kg",
    net_weight: "0.237",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.6",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.55",
    carton_height_unit: "m",
    carton_volume: "0.132",
    carton_volume_unit: "m3",
    carton_gross_weight: "13.42",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6186-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6186-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6186-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "RPET felt laptop bag",
    long_description:
      "RPET felt messenger or laptop bag with hook and loop closure. Fits a 15 inch laptop.",
    material: "RPET",
    commercial_description:
      "RPET felt messenger or laptop bag with hook and loop closure. Arrive at yourwork in style with this felt shoulder bag partly made from recycled PET plastic.This bag fits a 15 inch laptop or smaller and can hold other office suppliessuch as notebooks and pens. Everything you need in a day packed in a compactmessenger bag.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10215558",
        sku: "MO6186-15",
        release_date: "2020-12-27",
        product_proposition_category: "204",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Laptop bags",
        color_description: "Stone Grey",
        color_group: "Grey",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941052055",
        color_code: "15",
        pms_color: "433U",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/9-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/9-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6186-15.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6186-15.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6186-15-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6186-15-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6186-15-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6186-15-side.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6186-15-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6186-15-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6186-15-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6186-15-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 13322,
        price_in_aed: "16.12",
        final_price_with_margin: "45.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Laptop-Sleeves",
    master_code: "MO6419",
    master_id: "40008314",
    type_of_products: "stock",
    commodity_code: "4202 1291",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TOPLO",
    category_code: "MOBT&B_LAP",
    product_class: "Laptop bag",
    dimensions: "36X26CM",
    length: "36",
    length_unit: "cm",
    width: "26",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.97",
    volume_unit: "cdm3",
    gross_weight: "0.12",
    gross_weight_unit: "kg",
    net_weight: "0.104",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.55",
    carton_length_unit: "m",
    carton_width: "0.39",
    carton_width_unit: "m",
    carton_height: "0.45",
    carton_height_unit: "m",
    carton_volume: "0.097",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.82",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6419-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6419-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6419-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "RPET felt zipped laptop bag",
    long_description:
      "RPET felt 14 inch zippered laptop bag with zippered front pocket.",
    material: "RPET",
    commercial_description:
      "RPET felt 14 inch zippered laptop bag with zippered front pocket. Take yourlaptop or tablet with you in this protective sleeve. The front pocket makes iteasy to store smaller items such as pens or notes, making it ideal for use atthe office. The bag is made from recycled PET plastic.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10221879",
        sku: "MO6419-15",
        release_date: "2021-12-24",
        product_proposition_category: "204",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Laptop bags",
        color_description: "Stone Grey",
        color_group: "Grey",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941055797",
        color_code: "15",
        pms_color: "423U",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/10-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/10-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6419-15.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6419-15.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6419-15-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6419-15-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6419-15-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6419-15-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
        ],
        available_quantity: 24998,
        price_in_aed: "7.52",
        final_price_with_margin: "26.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Laptop-Sleeves",
    master_code: "MO6718",
    master_id: "40008815",
    type_of_products: "stock",
    commodity_code: "4202 1291",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TAPLA",
    category_code: "MOBT&B_LAP",
    product_class: "Laptop bag",
    dimensions: "38X6.5X28CM",
    length: "38",
    length_unit: "cm",
    width: "6.5",
    width_unit: "cm",
    height: "28",
    height_unit: "cm",
    volume: "1.2",
    volume_unit: "cdm3",
    gross_weight: "0.15",
    gross_weight_unit: "kg",
    net_weight: "0.13",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.6",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.55",
    carton_height_unit: "m",
    carton_volume: "0.132",
    carton_volume_unit: "m3",
    carton_gross_weight: "14.86",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6718-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6718-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6718-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "RPET felt zippered laptop bag",
    long_description:
      "RPET felt 15 inch zippered laptop bag with zippered main compartment and inner pocket.",
    material: "RPET",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223086",
        sku: "MO6718-03",
        release_date: "2022-12-26",
        product_proposition_category: "204",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Laptop bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941061705",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/11-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/11-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6718-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6718-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6718-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6718-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6718-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6718-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6718-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6718-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 5739,
        price_in_aed: "9.04",
        final_price_with_margin: "29.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Laptop-Sleeves",
    master_code: "MO9202",
    master_id: "40006691",
    type_of_products: "stock",
    commodity_code: "4202 9298",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "DEOPAD 15",
    category_code: "MOBT&B_LAP",
    product_class: "Laptop bag",
    dimensions: "39X3,5X26,5 CM",
    length: "39",
    length_unit: "cm",
    width: "3.5",
    width_unit: "cm",
    height: "26.5",
    height_unit: "cm",
    volume: "1.978",
    volume_unit: "cdm3",
    gross_weight: "0.162",
    gross_weight_unit: "kg",
    net_weight: "0.108",
    net_weight_unit: "kg",
    outer_carton_quantity: "25",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.36",
    carton_height_unit: "m",
    carton_volume: "0.046",
    carton_volume_unit: "m3",
    carton_gross_weight: "3.46",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9202-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9202-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9202-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Laptop pouch in 15 inch",
    long_description:
      "15 inch laptop pouch made of high density foam (2,5 mm).",
    material: "EVA",
    printable: "yes",
    variants: [
      {
        variant_id: "10208399",
        sku: "MO9202-03",
        release_date: "2018-01-01",
        product_proposition_category: "204",
        category_level1: "Bags & Travel",
        category_level2: "Backpacks & Business bags",
        category_level3: "Laptop bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941031975",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/13-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/13-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "/assets/img/accessories/bags/12-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/12-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9202-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9202-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9202-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9202-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9202-03b.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9202-03b.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
        available_quantity: 10706,
        price_in_aed: "13.12",
        final_price_with_margin: "40.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Duffle-Bags",
    master_code: "MO6940",
    master_id: "40009154",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "JAYA DUFFLE",
    category_code: "MOBT&B_SPO",
    product_class: "Sport bag",
    dimensions: "49X28X28CM",
    length: "49",
    length_unit: "cm",
    width: "28",
    width_unit: "cm",
    height: "28",
    height_unit: "cm",
    volume: "7.5",
    volume_unit: "cdm3",
    gross_weight: "0.75",
    gross_weight_unit: "kg",
    net_weight: "0.65",
    net_weight_unit: "kg",
    outer_carton_quantity: "10",
    carton_length: "0.53",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.27",
    carton_height_unit: "m",
    carton_volume: "0.054",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6940-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6940-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6940-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Sports bag in 50C tarpaulin",
    long_description:
      "Duffle bag, sports or travel bag in 50C tarpaulin with zippered side compartment and zippered inner pocket. It has a comfort grip handle and padded base.",
    material: "PVC",
    printable: "yes",
    variants: [
      {
        variant_id: "10224851",
        sku: "MO6940-03",
        release_date: "2023-07-28",
        product_proposition_category: "200",
        category_level1: "Bags & Travel",
        category_level2: "Sport & Outdoor bags",
        category_level3: "Sport bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941066380",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6940-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6940-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6940-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6940-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6940-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6940-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6940-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6940-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6940-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6940-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6940-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6940-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6940-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6940-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6940-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6940-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 4602,
        price_in_aed: "56.28",
        final_price_with_margin: "111.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Duffle-Bags",
    master_code: "IT1639",
    master_id: "40000293",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "YATCH",
    category_code: "MOBT&B_DUFCOT",
    product_class: "Duffle bag",
    dimensions: "Ø25X45 CM",
    length: "0",
    length_unit: "cm",
    width: "25",
    width_unit: "cm",
    height: "45",
    height_unit: "cm",
    volume: "0.64",
    volume_unit: "cdm3",
    gross_weight: "0.143",
    gross_weight_unit: "kg",
    net_weight: "0.128",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.5",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.064",
    carton_volume_unit: "m3",
    carton_gross_weight: "13.62",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/it1639-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/it1639-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/it1639-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Cotton duffle bag bicolour",
    long_description:
      "Bicolour cotton navy duffle bag with white stripe. 200gr/m² cotton twill.",
    material: "Cotton",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10108397",
        sku: "IT1639-04",
        release_date: "2015-01-01",
        product_proposition_category: "198",
        category_level1: "Bags & Travel",
        category_level2: "Sport & Outdoor bags",
        category_level3: "Beach bags",
        color_description: "Blue",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941013247",
        color_code: "04",
        pms_color: "289U",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/14-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/14-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it1639-04.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it1639-04.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it1639-04-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it1639-04-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
        available_quantity: 10389,
        price_in_aed: "10.92",
        final_price_with_margin: "29.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Duffle-Bags",
    master_code: "MO8368",
    master_id: "40003493",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "YA",
    category_code: "MOBT&B_DUFCOT",
    product_class: "Duffle bag",
    dimensions: "Ø24X45 CM",
    length: "0",
    length_unit: "cm",
    width: "24",
    width_unit: "cm",
    height: "45",
    height_unit: "cm",
    volume: "0.65",
    volume_unit: "cdm3",
    gross_weight: "0.148",
    gross_weight_unit: "kg",
    net_weight: "0.131",
    net_weight_unit: "kg",
    inner_carton_quantity: "25",
    outer_carton_quantity: "100",
    carton_length: "0.5",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.064",
    carton_volume_unit: "m3",
    carton_gross_weight: "14.52",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo8368-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8368-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo8368-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Cotton duffle bag",
    long_description: "Cotton duffle bag . 200 g/m² cotton twill.",
    material: "Cotton",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10181736",
        sku: "MO8368-13",
        release_date: "2015-01-01",
        product_proposition_category: "198",
        category_level1: "Bags & Travel",
        category_level2: "Sport & Outdoor bags",
        category_level3: "Beach bags",
        color_description: "Beige",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941021969",
        color_code: "13",
        pms_color: "7499U",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/15-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/15-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8368-13.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8368-13.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
        ],
        available_quantity: 12987,
        price_in_aed: "9.40",
        final_price_with_margin: "27.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Duffle-Bags",
    master_code: "MO6209",
    master_id: "40007911",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TERRA +",
    category_code: "MOBT&B_SPO",
    product_class: "Sport bag",
    dimensions: "50X23X28CM",
    length: "50",
    length_unit: "cm",
    width: "23",
    width_unit: "cm",
    height: "28",
    height_unit: "cm",
    volume: "2.161",
    volume_unit: "cdm3",
    gross_weight: "0.375",
    gross_weight_unit: "kg",
    net_weight: "0.343",
    net_weight_unit: "kg",
    outer_carton_quantity: "30",
    carton_length: "0.5",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.4",
    carton_height_unit: "m",
    carton_volume: "0.06",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.16",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6209-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6209-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6209-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "600D RPET sports bag",
    long_description:
      "Sport or travelling bag in 600D RPET with large zippered front pocket, inside zippered pocket and adjustable and detachable shoulder strap.",
    material: "RPET-PU",
    commercial_description:
      "Sport or travelling bag in 600D RPET. This bag, made from recycled PET, is great to take with you to the gym or for a weekend away when you are on short vacation. Store your workout clothes and items in the large zippered main compartment. Smaller items you can store inside the zippered front pocked or the zippered inside pocket. The adjustable and detachable shoulder strap makes the bag comfortable and easy to carry, even when it is all filled up.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10215626",
        sku: "MO6209-04",
        release_date: "2020-12-27",
        product_proposition_category: "200",
        category_level1: "Bags & Travel",
        category_level2: "Sport & Outdoor bags",
        category_level3: "Sport bags",
        color_description: "Blue",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941052536",
        color_code: "04",
        pms_color: "287U",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/16-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/16-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6209-04.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6209-04.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6209-04-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6209-04-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6209-04-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6209-04-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6209-04-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6209-04-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6209-04-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6209-04-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6209-04-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6209-04-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6209-04-picture1.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6209-04-picture1.jpg",
            type: "image",
            subtype: "item_picture_1",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6209-04-picture2.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6209-04-picture2.jpg",
            type: "image",
            subtype: "item_picture_2",
          },
        ],
        available_quantity: 2284,
        price_in_aed: "32.92",
        final_price_with_margin: "64.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Duffle-Bags",
    master_code: "IT2558",
    master_id: "40000378",
    type_of_products: "stock",
    commodity_code: "4202 9211",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "MOONLIGHT",
    category_code: "MOBT&B_COS",
    product_class: "Travel accessories",
    dimensions: "24X7X20 CM",
    length: "24",
    length_unit: "cm",
    width: "7",
    width_unit: "cm",
    height: "20",
    height_unit: "cm",
    volume: "0.135",
    volume_unit: "cdm3",
    gross_weight: "0.041",
    gross_weight_unit: "kg",
    net_weight: "0.037",
    net_weight_unit: "kg",
    inner_carton_quantity: "1",
    outer_carton_quantity: "200",
    carton_length: "0.48",
    carton_length_unit: "m",
    carton_width: "0.295",
    carton_width_unit: "m",
    carton_height: "0.16",
    carton_height_unit: "m",
    carton_volume: "0.023",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.74",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/it2558-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/it2558-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/it2558-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Transparent cosmetic pouch",
    long_description: "Transparent PVC cosmetic pouch with zipper.",
    material: "PVC",
    printable: "yes",
    variants: [
      {
        variant_id: "10177071",
        sku: "IT2558-03",
        release_date: "2015-01-01",
        product_proposition_category: "343",
        category_level1: "Wellness & Care",
        category_level2: "Personal care",
        category_level3: "Cosmetic/Toiletry bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941013711",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/17-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/17-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it2558-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it2558-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
        ],
        available_quantity: 7168,
        price_in_aed: "3.28",
        final_price_with_margin: "16.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Travel-Kits",
    master_code: "MO6279",
    master_id: "40008105",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ZURICH",
    category_code: "MOBT&B_SPO",
    product_class: "Sport bag",
    dimensions: "55X24.5X36CM",
    length: "55",
    length_unit: "cm",
    width: "24.5",
    width_unit: "cm",
    height: "36",
    height_unit: "cm",
    volume: "7.3",
    volume_unit: "cdm3",
    gross_weight: "1.2",
    gross_weight_unit: "kg",
    net_weight: "1.066",
    net_weight_unit: "kg",
    outer_carton_quantity: "10",
    carton_length: "0.64",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.39",
    carton_height_unit: "m",
    carton_volume: "0.1",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.56",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6279-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6279-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6279-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Weekend bag in canvas 450gr/m²",
    long_description:
      "Weekend bag in high quality 450 gr/m² washed canvas with recyled PU details. Lining is in RPET 210D. Strong adjustable and detachable straps included. Perfect hand luggage size.",
    material: "Cotton",
    commercial_description:
      "Weekend bag in high quality 450 gr/m² washed canvas with PU details. The lining is in RPET 210D. The high quality washed canvas in combination with the PU details make this bag feel luxurious and premium. It is a perfect bag for a short vacation or a weekend citytrip. This bag as a special compartment to store an extra pair of shoes. This way your clothes do not get dirty and you have easy access. Carry the bag by the handles or use the included adjustable and detachable straps to carry the bag over your shoulder. Perfect hand luggage size.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10221283",
        sku: "MO6279-03",
        release_date: "2021-08-01",
        product_proposition_category: "208",
        category_level1: "Bags & Travel",
        category_level2: "Travel accessories",
        category_level3: "Travel bags & trolleys",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941053809",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/18-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/18-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6279-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6279-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6279-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6279-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6279-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6279-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6279-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6279-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6279-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6279-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6279-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6279-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 3375,
        price_in_aed: "94.36",
        final_price_with_margin: "174.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Trolley",
    master_code: "MO6807",
    master_id: "40008904",
    type_of_products: "stock",
    commodity_code: "4202 1299",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "VOYAGE",
    category_code: "MOBT&B_S&TTRO",
    product_class: "Travel accessories",
    dimensions: "32.5X20X46.5CM",
    length: "32.5",
    length_unit: "cm",
    width: "20",
    width_unit: "cm",
    height: "46.5",
    height_unit: "cm",
    volume: "36.5",
    volume_unit: "cdm3",
    gross_weight: "2.6",
    gross_weight_unit: "kg",
    net_weight: "2.188",
    net_weight_unit: "kg",
    outer_carton_quantity: "2",
    carton_length: "0.4",
    carton_length_unit: "m",
    carton_width: "0.325",
    carton_width_unit: "m",
    carton_height: "0.54",
    carton_height_unit: "m",
    carton_volume: "0.07",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.2",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6807-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6807-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6807-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "600D RPET Soft trolley",
    long_description:
      "600D RPET Polyester soft side cabin trolley. Including exterior zipped laptop compartment. Zipped front pocket. Combi lock. Bottom compartment with ribbons.",
    material: "RPET",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223668",
        sku: "MO6807-03",
        release_date: "2022-12-26",
        product_proposition_category: "208",
        category_level1: "Bags & Travel",
        category_level2: "Travel accessories",
        category_level3: "Travel bags & trolleys",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941063440",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/19-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/19-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6807-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6807-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6807-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6807-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6807-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6807-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6807-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6807-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
        ],
        available_quantity: 171,
        price_in_aed: "198.48",
        final_price_with_margin: "529.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Travel-Kits",
    master_code: "KC2226",
    master_id: "40000971",
    type_of_products: "stock",
    commodity_code: "7009 9200",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SORAIA",
    category_code: "MOBPER_STYMIR",
    product_class: "Wellness & Care accessories",
    dimensions: "Ø6X0,6 CM",
    length: "0",
    length_unit: "cm",
    width: "6",
    width_unit: "cm",
    height: "6",
    height_unit: "cm",
    volume: "0.11",
    volume_unit: "cdm3",
    gross_weight: "0.037",
    gross_weight_unit: "kg",
    net_weight: "0.029",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "300",
    carton_length: "0.35",
    carton_length_unit: "m",
    carton_width: "0.31",
    carton_width_unit: "m",
    carton_height: "0.3",
    carton_height_unit: "m",
    carton_volume: "0.033",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.24",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/kc2226-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/kc2226-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/kc2226-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Make-up mirror",
    long_description:
      "Aluminium make-up mirror with standard and magnifying mirrors in velvet case.",
    material: "Glass",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10121240",
        sku: "KC2226-16",
        release_date: "2015-01-01",
        product_proposition_category: "342",
        category_level1: "Wellness & Care",
        category_level2: "Personal care",
        category_level3: "Mirrors",
        color_description: "Matt Silver",
        color_group: "Silver",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941008328",
        color_code: "16",
        pms_color: "SILVER",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/20-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/20-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2226-16.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2226-16.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2226-16-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2226-16-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2226-16-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2226-16-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 11452,
        price_in_aed: "3.92",
        final_price_with_margin: "15.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Travel-Kits",
    master_code: "KC2683",
    master_id: "40001025",
    type_of_products: "stock",
    commodity_code: "9603 2930",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "B BEAUTY",
    category_code: "MOBPER_STYHAI",
    product_class: "Wellness & Care accessories",
    dimensions: "7X6,5X2,8 CM",
    length: "7",
    length_unit: "cm",
    width: "6.5",
    width_unit: "cm",
    height: "2.8",
    height_unit: "cm",
    volume: "0.208",
    volume_unit: "cdm3",
    gross_weight: "0.047",
    gross_weight_unit: "kg",
    net_weight: "0.041",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "200",
    carton_length: "0.375",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.37",
    carton_height_unit: "m",
    carton_volume: "0.042",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.3",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/kc2683-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/kc2683-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/kc2683-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Foldable brush/mirror",
    long_description:
      "Foldable hair brush and mirror in plastic housing. Silver spray finish.",
    material: "ABS",
    printable: "yes",
    variants: [
      {
        variant_id: "10144618",
        sku: "KC2683-14",
        release_date: "2015-01-01",
        product_proposition_category: "342",
        category_level1: "Wellness & Care",
        category_level2: "Personal care",
        category_level3: "Mirrors",
        color_description: "Silver",
        color_group: "Silver",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941015562",
        color_code: "14",
        pms_color: "SILVER",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/21-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/21-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2683-14.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2683-14.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2683-14-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2683-14-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2683-14b.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2683-14b.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/kc2683-14-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/kc2683-14-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 52294,
        price_in_aed: "3.00",
        final_price_with_margin: "15.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Travel-Kits",
    master_code: "MO2204",
    master_id: "40009761",
    type_of_products: "stock",
    commodity_code: "4202 9298",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TOSHI",
    category_code: "MOBT&B_WAB",
    product_class: "Waist bag",
    dimensions: "19X6X14CM",
    length: "19",
    length_unit: "cm",
    width: "6",
    width_unit: "cm",
    height: "14",
    height_unit: "cm",
    volume: "0.44",
    volume_unit: "cdm3",
    gross_weight: "0.09",
    gross_weight_unit: "kg",
    net_weight: "0.08",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.49",
    carton_length_unit: "m",
    carton_width: "0.23",
    carton_width_unit: "m",
    carton_height: "0.41",
    carton_height_unit: "m",
    carton_volume: "0.046",
    carton_volume_unit: "m3",
    carton_gross_weight: "9",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2204-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2204-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2204-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "300D RPET polyester waist bag",
    long_description: "Waist bag in 300D RPET with lining 210D RPET.",
    material: "RPET",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10236077",
        sku: "MO2204-03",
        release_date: "2023-12-22",
        product_proposition_category: "211",
        category_level1: "Bags & Travel",
        category_level2: "Travel accessories",
        category_level3: "Waist bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941070974",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/22-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/22-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2204-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2204-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2204-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2204-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2204-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2204-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2204-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2204-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2204-03-picture5.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2204-03-picture5.jpg",
            type: "image",
            subtype: "item_picture_5",
          },
        ],
        available_quantity: 5865,
        price_in_aed: "8.76",
        final_price_with_margin: "25.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Travel-Kits",
    master_code: "MO2379",
    master_id: "40010034",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "PESACARA",
    category_code: "MOBT&B_COS",
    product_class: "Toiletry and cosmetic bags",
    dimensions: "23X15CM",
    length: "23",
    length_unit: "cm",
    width: "15",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.12",
    volume_unit: "cdm3",
    gross_weight: "0.033",
    gross_weight_unit: "kg",
    net_weight: "0.032",
    net_weight_unit: "kg",
    outer_carton_quantity: "400",
    carton_length: "0.5",
    carton_length_unit: "m",
    carton_width: "0.34",
    carton_width_unit: "m",
    carton_height: "0.28",
    carton_height_unit: "m",
    carton_volume: "0.048",
    carton_volume_unit: "m3",
    carton_gross_weight: "13",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-14T15:00:18",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2379-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2379-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2379-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Cosmetic bag cotton 340 gr/m²",
    long_description:
      "Cosmetic bag in organic cotton with coloured zipper. 340 gr/m².",
    material: "Organic Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10239164",
        sku: "MO2379-03",
        release_date: "2024-07-19",
        product_proposition_category: "210",
        category_level1: "Bags & Travel",
        category_level2: "Travel accessories",
        category_level3: "Travel accessories",
        color_description: "Black",
        color_group: "Black",
        plc_status: "11",
        plc_status_description: "NEW",
        gtin: "8719941077126",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/23-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/23-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2379-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2379-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2379-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2379-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2379-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2379-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2379-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2379-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 11827,
        price_in_aed: "2.96",
        final_price_with_margin: "14.00",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Travel-Kits",
    master_code: "MO6629",
    master_id: "40008619",
    type_of_products: "stock",
    commodity_code: "8214 2000",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SEWA",
    category_code: "MOBPER_MAN",
    product_class: "Wellness & Care accessories",
    dimensions: "14X9,5X2CM",
    length: "14",
    length_unit: "cm",
    width: "9.5",
    width_unit: "cm",
    height: "2",
    height_unit: "cm",
    volume: "0.46",
    volume_unit: "cdm3",
    gross_weight: "0.241",
    gross_weight_unit: "kg",
    net_weight: "0.192",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.515",
    carton_length_unit: "m",
    carton_width: "0.16",
    carton_width_unit: "m",
    carton_height: "0.28",
    carton_height_unit: "m",
    carton_volume: "0.023",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.06",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6629-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6629-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6629-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Manicure nail tool set",
    long_description:
      "4 piece stainless steel manicure set in a bamboo case with magnetic closure. Including: curved nail scissors, nail clippers, nail file and tweezers. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    commercial_description:
      "4 piece stainless steel manicure set in a bamboo case with magnetic closure.Including: curved nail scissors, nail clippers, nail file and tweezers. Takegood care of your fingers and nails with this complete manicure set. It containseverything you need to maintain the perfect nails. The whole set is presented ina natural bamboo case. Bamboo is a natural product, there may be slightvariations in colour and size per item, which can affect the final decorationoutcome.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10222570",
        sku: "MO6629-40",
        release_date: "2022-07-31",
        product_proposition_category: "344",
        category_level1: "Wellness & Care",
        category_level2: "Personal care",
        category_level3: "Nail kits",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941059252",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/24-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/24-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6629-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6629-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6629-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6629-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6629-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6629-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6629-40-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6629-40-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6629-40-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6629-40-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6629-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6629-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6629-40-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6629-40-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 4050,
        price_in_aed: "14.28",
        final_price_with_margin: "36.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Travel-Kits",
    master_code: "MO6719",
    master_id: "40008816",
    type_of_products: "stock",
    commodity_code: "4202 9298",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BROTT",
    category_code: "MOBT&B_WAB",
    product_class: "Waist bag",
    dimensions: "26X12X15CM",
    length: "26",
    length_unit: "cm",
    width: "12",
    width_unit: "cm",
    height: "15",
    height_unit: "cm",
    volume: "0.68",
    volume_unit: "cdm3",
    gross_weight: "0.124",
    gross_weight_unit: "kg",
    net_weight: "0.118",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.46",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.4",
    carton_height_unit: "m",
    carton_volume: "0.055",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.44",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6719-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6719-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6719-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Waist bag in 600D RPET",
    long_description:
      "Waist bag in 600D RPET polyester with zippered main compartment and adjustable waist strap. Includes inner pocket.",
    material: "RPET",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223099",
        sku: "MO6719-03",
        release_date: "2022-12-26",
        product_proposition_category: "211",
        category_level1: "Bags & Travel",
        category_level2: "Travel accessories",
        category_level3: "Waist bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941061729",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/25-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/25-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6719-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6719-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6719-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6719-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6719-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6719-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6719-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6719-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6719-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6719-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 6684,
        price_in_aed: "12.48",
        final_price_with_margin: "30.50",
      },
    ],
  },
  {
    main_category_name: "Bags",
    category_name: "Travel-Kits",
    master_code: "MO9903",
    master_id: "40007603",
    type_of_products: "stock",
    commodity_code: "4419 1900",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "MAYEN SET",
    category_code: "MOBH&L_KACCUT",
    product_class: "Kitchen accessories",
    dimensions: "33.5X10X0.6CM",
    length: "33.5",
    length_unit: "cm",
    width: "10",
    width_unit: "cm",
    height: "0.6",
    height_unit: "cm",
    volume: "0.398",
    volume_unit: "cdm3",
    gross_weight: "0.098",
    gross_weight_unit: "kg",
    net_weight: "0.097",
    net_weight_unit: "kg",
    outer_carton_quantity: "120",
    carton_length: "0.38",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.33",
    carton_height_unit: "m",
    carton_volume: "0.04",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.664",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9903-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9903-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9903-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo9903-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Set bamboo utensils salad",
    long_description:
      "Set of bamboo salad utensils. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10214528",
        sku: "MO9903-13",
        release_date: "2020-01-01",
        product_proposition_category: "279",
        category_level1: "Eating & Drinking",
        category_level2: "Kitchenware",
        category_level3: "Utensils",
        color_description: "Beige",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941048522",
        color_code: "13",
        pms_color: "NATURE COLOR",
        digital_assets: [
          {
            url: "/assets/img/accessories/bags/26-with-logo.jpg",
            url_highress: "/assets/img/accessories/bags/26-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9903-13.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9903-13.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9903-13-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9903-13-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
        available_quantity: 10843,
        price_in_aed: "6.28",
        final_price_with_margin: "21.50",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Bottles",
    master_code: "MO2105",
    master_id: "40009137",
    type_of_products: "stock",
    commodity_code: "7013 9900",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ABE",
    category_code: "MOBH&L_GLA",
    product_class: "Drinking bottle",
    dimensions: "Ø6X22CM",
    length: "0",
    length_unit: "cm",
    width: "6",
    width_unit: "cm",
    height: "22",
    height_unit: "cm",
    volume: "1.433",
    volume_unit: "cdm3",
    gross_weight: "0.3",
    gross_weight_unit: "kg",
    net_weight: "0.267",
    net_weight_unit: "kg",
    outer_carton_quantity: "30",
    carton_length: "0.455",
    carton_length_unit: "m",
    carton_width: "0.25",
    carton_width_unit: "m",
    carton_height: "0.38",
    carton_height_unit: "m",
    carton_volume: "0.043",
    carton_volume_unit: "m3",
    carton_gross_weight: "9",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2105-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2105-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2105-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo2105-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-performance/mo2105-test-performance.pdf",
        type: "document",
        subtype: "test_performance",
      },
    ],
    short_description: "Frosted glass bottle 500ml",
    long_description:
      "Glass bottle with frosted finish and bamboo lid. Leak-free. Capacity: 500 ml. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Glass",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10226271",
        sku: "MO2105-23",
        release_date: "2023-07-28",
        product_proposition_category: "271",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Water bottles",
        color_description: "Transparent Blue",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941068858",
        color_code: "23",
        pms_color: "295U",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/1-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/1-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2105-23.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2105-23.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2105-23-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2105-23-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2105-23-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2105-23-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2105-23-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2105-23-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2105-23-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2105-23-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2105-23-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2105-23-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2105-23-picture1.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2105-23-picture1.jpg",
            type: "image",
            subtype: "item_picture_1",
          },
        ],
        available_quantity: 33572,
        price_in_aed: "13.32",
        final_price_with_margin: "36.00",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Bottles",
    master_code: "MO2107",
    master_id: "40009139",
    type_of_products: "stock",
    commodity_code: "9617 0000",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "FLORENCE",
    category_code: "MOBH&L_DRI",
    product_class: "Drinking bottle",
    dimensions: "Ø7X21.5CM",
    length: "0",
    length_unit: "cm",
    width: "7",
    width_unit: "cm",
    height: "21.5",
    height_unit: "cm",
    volume: "1.5",
    volume_unit: "cdm3",
    gross_weight: "0.325",
    gross_weight_unit: "kg",
    net_weight: "0.3",
    net_weight_unit: "kg",
    outer_carton_quantity: "40",
    carton_length: "0.63",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.24",
    carton_height_unit: "m",
    carton_volume: "0.06",
    carton_volume_unit: "m3",
    carton_gross_weight: "13",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-07T09:03:05",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2107-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2107-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2107-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo2107-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Double wall bottle 500 ml",
    long_description:
      "Double wall insulating vacuum bottle in recycled stainless steel (90 % recycled stainless steel and 10% stainless steel) with lid with handle. Leak-free. Capacity: 500 ml.",
    material: "Recycled Stainless Steel",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10235950",
        sku: "MO2107-03",
        release_date: "2023-12-22",
        product_proposition_category: "270",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Insulated bottles",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941069855",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/2-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/2-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2107-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2107-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2107-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2107-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2107-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2107-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2107-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2107-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2107-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2107-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2107-03-picture1.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2107-03-picture1.jpg",
            type: "image",
            subtype: "item_picture_1",
          },
        ],
        available_quantity: 39323,
        price_in_aed: "22.04",
        final_price_with_margin: "50.00",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Mugs",
    master_code: "MO2112",
    master_id: "40009610",
    type_of_products: "stock",
    commodity_code: "9617 0000",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "REDU",
    category_code: "MOBH&L_DRI",
    product_class: "Cups, mugs & glasses",
    dimensions: "Ø6.5X16.5CM",
    length: "0",
    length_unit: "cm",
    width: "6.5",
    width_unit: "cm",
    height: "16.5",
    height_unit: "cm",
    volume: "1.3",
    volume_unit: "cdm3",
    gross_weight: "0.243",
    gross_weight_unit: "kg",
    net_weight: "0.218",
    net_weight_unit: "kg",
    outer_carton_quantity: "40",
    carton_length: "0.63",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.205",
    carton_height_unit: "m",
    carton_volume: "0.052",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.7",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2112-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2112-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo2112-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Double wall bottle 330 ml",
    long_description:
      "Double wall Stainless Steel insulating vacuum bottle with lid. Capacity 330 ml. Leak free.",
    material: "Stainless Steel",
    printable: "yes",
    variants: [
      {
        variant_id: "10226264",
        sku: "MO2112-03",
        release_date: "2023-09-08",
        product_proposition_category: "270",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Insulated bottles",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941068988",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/8-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/8-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2112-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2112-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2112-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2112-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2112-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2112-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2112-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2112-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2112-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2112-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 2069,
        price_in_aed: "22.16",
        final_price_with_margin: "46.00",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Mugs",
    master_code: "MO2149",
    master_id: "40009709",
    type_of_products: "stock",
    commodity_code: "7323 9300",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "NUNAVUT",
    category_code: "MOBH&L_THEMUG",
    product_class: "Cups, mugs & glasses",
    dimensions: "Ø8X7.5CM",
    length: "0",
    length_unit: "cm",
    width: "8",
    width_unit: "cm",
    height: "7.5",
    height_unit: "cm",
    volume: "0.642",
    volume_unit: "cdm3",
    gross_weight: "0.108",
    gross_weight_unit: "kg",
    net_weight: "0.1",
    net_weight_unit: "kg",
    outer_carton_quantity: "120",
    carton_length: "0.525",
    carton_length_unit: "m",
    carton_width: "0.36",
    carton_width_unit: "m",
    carton_height: "0.41",
    carton_height_unit: "m",
    carton_volume: "0.077",
    carton_volume_unit: "m3",
    carton_gross_weight: "13",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2149-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2149-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2149-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo2149-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-performance/mo2149-test-performance.pdf",
        type: "document",
        subtype: "test_performance",
      },
    ],
    short_description: "Stainless steel mug 330 ml",
    long_description:
      "Single wall stainless steel mug with foldable handle. Capacity: 330 ml.",
    material: "Stainless Steel",
    printable: "yes",
    variants: [
      {
        variant_id: "10235893",
        sku: "MO2149-14",
        release_date: "2023-12-22",
        product_proposition_category: "243",
        category_level1: "Sports & Recreation",
        category_level2: "Outdoor",
        category_level3: "Picnic & Camping",
        color_description: "Silver",
        color_group: "Silver",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941069695",
        color_code: "14",
        pms_color: "SILVER",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/9-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/9-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2149-14.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2149-14.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2149-14-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2149-14-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2149-14-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2149-14-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2149-14-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2149-14-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2149-14-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2149-14-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 1935,
        price_in_aed: "8.16",
        final_price_with_margin: "25.00",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Mugs",
    master_code: "MO2187",
    master_id: "40009744",
    type_of_products: "stock",
    commodity_code: "9617 0000",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "NOMU",
    category_code: "MOBH&L_THEMUG",
    product_class: "Cups, mugs & glasses",
    dimensions: "Ø8.5X12CM",
    length: "0",
    length_unit: "cm",
    width: "8.5",
    width_unit: "cm",
    height: "12",
    height_unit: "cm",
    volume: "1.25",
    volume_unit: "cdm3",
    gross_weight: "0.27",
    gross_weight_unit: "kg",
    net_weight: "0.22",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.47",
    carton_length_unit: "m",
    carton_width: "0.19",
    carton_width_unit: "m",
    carton_height: "0.28",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.4",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2187-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2187-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2187-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo2187-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Double wall tumbler 300ml",
    long_description:
      "Double wall tumbler with stainless steel outer and PP liner inside, with cork base and with sliding sip hole. Leak-free. Capacity: 300 ml.",
    material: "Stainless Steel",
    printable: "yes",
    variants: [
      {
        variant_id: "10236206",
        sku: "MO2187-06",
        release_date: "2023-12-22",
        product_proposition_category: "270",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Insulated bottles",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941071926",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/10-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/10-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2187-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2187-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2187-06-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2187-06-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2187-06-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2187-06-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2187-06-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2187-06-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2187-06-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2187-06-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 20158,
        price_in_aed: "15.92",
        final_price_with_margin: "38.50",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Bottles",
    master_code: "MO2241",
    master_id: "40009797",
    type_of_products: "stock",
    commodity_code: "7323 9300",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BIRA",
    category_code: "MOBH&L_DRI",
    product_class: "Drinking bottle",
    liquid_volume: "650",
    liquid_volume_unit: "ml",
    dimensions: "Ø6X22CM",
    length: "0",
    length_unit: "cm",
    width: "6",
    width_unit: "cm",
    height: "22",
    height_unit: "cm",
    volume: "1.38",
    volume_unit: "cdm3",
    gross_weight: "0.19",
    gross_weight_unit: "kg",
    net_weight: "0.17",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.38",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.48",
    carton_height_unit: "m",
    carton_volume: "0.069",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2241-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2241-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2241-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo2241-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Stainless steel bottle 650ml",
    long_description:
      "Single wall stainless steel bottle with PP lid and silicone hanger. Capacity: 650 ml.",
    material: "Stainless Steel",
    printable: "yes",
    variants: [
      {
        variant_id: "10236164",
        sku: "MO2241-03",
        release_date: "2023-12-22",
        product_proposition_category: "271",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Water bottles",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941071605",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/3-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/3-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2241-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2241-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2241-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2241-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2241-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2241-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2241-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2241-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2241-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2241-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2241-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2241-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2241-03-picture5.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2241-03-picture5.jpg",
            type: "image",
            subtype: "item_picture_5",
          },
        ],
        available_quantity: 110,
        price_in_aed: "12.76",
        final_price_with_margin: "34.00",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Bottles",
    master_code: "MO6313",
    master_id: "40008138",
    type_of_products: "stock",
    commodity_code: "9617 0000",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ASPEN CORK",
    category_code: "MOBH&L_DRI",
    product_class: "Drinking bottle",
    liquid_volume: "600",
    liquid_volume_unit: "ml",
    dimensions: "Ø6.5X27CM",
    length: "0",
    length_unit: "cm",
    width: "6.5",
    width_unit: "cm",
    height: "27",
    height_unit: "cm",
    volume: "1.92",
    volume_unit: "cdm3",
    gross_weight: "0.38",
    gross_weight_unit: "kg",
    net_weight: "0.29",
    net_weight_unit: "kg",
    outer_carton_quantity: "25",
    carton_length: "0.395",
    carton_length_unit: "m",
    carton_width: "0.395",
    carton_width_unit: "m",
    carton_height: "0.305",
    carton_height_unit: "m",
    carton_volume: "0.048",
    carton_volume_unit: "m3",
    carton_gross_weight: "8.9",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6313-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6313-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6313-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6313-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Double wall bottle 500 ml",
    long_description:
      "Double wall stainless steel vacuum bottle with cork base detail. Capacity: 500 ml. Leak free. Cork is 100% natural material. Due to its structural nature and surface porosity the final print result per item may have deviations.",
    material: "Stainless Steel",
    commercial_description:
      "Double wall stainless steel vacuum bottle with a cork base detail. This bottle will keep your drinks hot or cold so you can enjoy it throughout the day and stay hydrated. The cork base give this flask a natural look. Capacity 500 ml. Leak free.",
    printable: "yes",
    variants: [
      {
        variant_id: "10221333",
        sku: "MO6313-03",
        release_date: "2021-08-01",
        product_proposition_category: "270",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Insulated bottles",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941054226",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/4-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/4-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6313-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6313-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6313-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6313-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6313-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6313-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 34082,
        price_in_aed: "24.04",
        final_price_with_margin: "51.00",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Bottles",
    master_code: "MO6413",
    master_id: "40008308",
    type_of_products: "stock",
    commodity_code: "7013 9900",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "AMELAND",
    category_code: "MOBH&L_DRI",
    product_class: "Drinking bottle",
    liquid_volume: "500",
    liquid_volume_unit: "ml",
    dimensions: "Ø6X20.5CM",
    length: "0",
    length_unit: "cm",
    width: "6",
    width_unit: "cm",
    height: "20.5",
    height_unit: "cm",
    volume: "1.36",
    volume_unit: "cdm3",
    gross_weight: "0.34",
    gross_weight_unit: "kg",
    net_weight: "0.307",
    net_weight_unit: "kg",
    outer_carton_quantity: "25",
    carton_length: "0.385",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.23",
    carton_height_unit: "m",
    carton_volume: "0.034",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.02",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6413-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6413-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6413-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6413-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-performance/mo6413-test-performance.pdf",
        type: "document",
        subtype: "test_performance",
      },
    ],
    short_description: "Glass bottle 500ml bamboo lid",
    long_description:
      "Glass drinking  bottle with bamboo lid with handle. Capacity: 500 ml. Leak free. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Glass Soda Lime",
    commercial_description:
      "Glass drinking bottle with bamboo lid with handle. Capacity: 500ml. Stay hydrated throughout the day and carry this flask with you wherever you go. The handle can be attached to your bag for convenience. The bamboo lid gives the bottle a nice, natural touch. Leak free. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10221875",
        sku: "MO6413-22",
        release_date: "2021-12-24",
        product_proposition_category: "271",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Water bottles",
        color_description: "Transparent",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941055728",
        color_code: "22",
        pms_color: "TRANSPARENT",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/5-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/5-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6413-22.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6413-22.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6413-22-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6413-22-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6413-22-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6413-22-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6413-22-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6413-22-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6413-22-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6413-22-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 34068,
        price_in_aed: "16.32",
        final_price_with_margin: "40.50",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Mugs",
    master_code: "MO6533",
    master_id: "40008427",
    type_of_products: "stock",
    commodity_code: "9617 0000",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "RUBY",
    category_code: "MOBH&L_DRI",
    product_class: "Cups, mugs & glasses",
    liquid_volume: "300",
    liquid_volume_unit: "ml",
    dimensions: "11.5X12.5CM",
    length: "12.5",
    length_unit: "cm",
    width: "11.5",
    width_unit: "cm",
    height: "12.5",
    height_unit: "cm",
    volume: "1.9",
    volume_unit: "cdm3",
    gross_weight: "0.34",
    gross_weight_unit: "kg",
    net_weight: "0.256",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.545",
    carton_length_unit: "m",
    carton_width: "0.225",
    carton_width_unit: "m",
    carton_height: "0.31",
    carton_height_unit: "m",
    carton_volume: "0.038",
    carton_volume_unit: "m3",
    carton_gross_weight: "6.42",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6533-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6533-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6533-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6533-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6533-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Double wall mug 300ml",
    long_description:
      "Double wall stainless steel mug with cork base and sliding sip hole cover. Capacity: 300 ml. Cork is 100% natural material. Due to its structural nature and surface porosity the final print result per item may have deviations.",
    material: "Stainless Steel",
    commercial_description:
      "Double wall stainless steel tumbler with cork base and sliding sip hole cover. Capacity: 300ml. Take this tumbler with you on the road to drink your favourite coffee, tea or hot chocolate wherever you are. Thanks to the sliding cover it can be closed so it will not spill. The cork base gives it a unique natural touch.",
    printable: "yes",
    variants: [
      {
        variant_id: "10222154",
        sku: "MO6533-03",
        release_date: "2021-12-24",
        product_proposition_category: "270",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Insulated bottles",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941057395",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/11-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/11-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6533-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6533-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6533-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6533-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6533-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6533-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6533-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6533-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6533-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6533-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 14675,
        price_in_aed: "25.16",
        final_price_with_margin: "54.00",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Mugs",
    master_code: "MO6579",
    master_id: "40008571",
    type_of_products: "stock",
    commodity_code: "9617 0000",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BRACE +",
    category_code: "MOBH&L_CUPONE",
    product_class: "Cups, mugs & glasses",
    liquid_volume: "510",
    liquid_volume_unit: "ml",
    dimensions: "Ø8.5X17CM",
    length: "0",
    length_unit: "cm",
    width: "0",
    width_unit: "cm",
    height: "17",
    height_unit: "cm",
    volume: "1.9",
    volume_unit: "cdm3",
    gross_weight: "0.34",
    gross_weight_unit: "kg",
    net_weight: "0.268",
    net_weight_unit: "kg",
    outer_carton_quantity: "40",
    carton_length: "0.49",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.39",
    carton_height_unit: "m",
    carton_volume: "0.076",
    carton_volume_unit: "m3",
    carton_gross_weight: "13.38",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-15T18:03:55",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6579-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6579-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6579-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6579-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-performance/mo6579-test-performance.pdf",
        type: "document",
        subtype: "test_performance",
      },
    ],
    short_description: "Double wall tumbler 510 ml",
    long_description:
      "Double wall stainless steel tumbler with PP lid. Capacity: 510 ml. Leak free.",
    material: "Stainless Steel",
    commercial_description:
      "Double wall stainless steel tumbler with PP lid. Capacity: 510 ml. Leak free. Bring your favourite coffee, tea or other hot drink with you with this tumbler. It will keep your drink hot or cold, so you can enjoy it throughout the day.",
    printable: "yes",
    variants: [
      {
        variant_id: "10222426",
        sku: "MO6579-03",
        release_date: "2022-07-31",
        product_proposition_category: "273",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Mugs & Tumblers",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941058040",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/12-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/12-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6579-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6579-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6579-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6579-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6579-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6579-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6579-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6579-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6579-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6579-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 4400,
        price_in_aed: "22.52",
        final_price_with_margin: "50.00",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Bottles",
    master_code: "MO9796",
    master_id: "40007499",
    type_of_products: "stock",
    commodity_code: "9617 0000",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "POLE",
    category_code: "MOBH&L_THEFSK",
    product_class: "Thermo flask",
    liquid_volume: "460",
    liquid_volume_unit: "ml",
    dimensions: "Ø6X23CM",
    length: "0",
    length_unit: "cm",
    width: "6",
    width_unit: "cm",
    height: "23",
    height_unit: "cm",
    volume: "1.415",
    volume_unit: "cdm3",
    gross_weight: "0.32",
    gross_weight_unit: "kg",
    net_weight: "0.267",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.37",
    carton_length_unit: "m",
    carton_width: "0.37",
    carton_width_unit: "m",
    carton_height: "0.5",
    carton_height_unit: "m",
    carton_volume: "0.068",
    carton_volume_unit: "m3",
    carton_gross_weight: "15.98",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-05T17:01:05",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9796-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9796-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9796-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9796-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo9796-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo9796-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo9796-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
      {
        url: "https://cdn1.midocean.com/document/test-performance/mo9796-test-performance.pdf",
        type: "document",
        subtype: "test_performance",
      },
    ],
    short_description: "Bottle with touch thermometer",
    long_description:
      "Double wall stainless steel insulating vacuum bottle. It has an LED touch thermometer incorporated in to the top of the lid and tea infuser inside. 1 replaceable CR2032 battery included. Leakproof. Capacity: 450 ml. Leak free.",
    material: "Stainless Steel",
    printable: "yes",
    variants: [
      {
        variant_id: "10213690",
        sku: "MO9796-03",
        release_date: "2020-01-01",
        product_proposition_category: "270",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Insulated bottles",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941045248",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/6-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/6-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9796-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9796-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9796-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9796-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9796-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9796-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9796-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9796-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 10495,
        price_in_aed: "24.40",
        final_price_with_margin: "51.00",
      },
    ],
  },
  {
    main_category_name: "Drinkware",
    category_name: "Bottles",
    master_code: "MO9840",
    master_id: "40007543",
    type_of_products: "stock",
    commodity_code: "7615 1080",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ATLANTA",
    category_code: "MOBH&L_DRI",
    product_class: "Drinking bottle",
    dimensions: "Ø7X22CM",
    length: "0",
    length_unit: "cm",
    width: "7",
    width_unit: "cm",
    height: "22",
    height_unit: "cm",
    volume: "1.6",
    volume_unit: "cdm3",
    gross_weight: "0.146",
    gross_weight_unit: "kg",
    net_weight: "0.126",
    net_weight_unit: "kg",
    outer_carton_quantity: "25",
    carton_length: "0.4",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.25",
    carton_height_unit: "m",
    carton_volume: "0.04",
    carton_volume_unit: "m3",
    carton_gross_weight: "3.82",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9840-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9840-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo9840-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Aluminium bottle 600 ml",
    long_description:
      "Aluminium single layer drinking bottle with foldable mouth piece and lid with hanger. Capacity 600 ml. Sublimation print available on white item only. Leak free.",
    material: "Aluminium",
    printable: "yes",
    variants: [
      {
        variant_id: "10213940",
        sku: "MO9840-03",
        release_date: "2020-01-01",
        product_proposition_category: "271",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Water bottles",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941046573",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/drinkware/7-with-logo.jpg",
            url_highress: "/assets/img/accessories/drinkware/7-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9840-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9840-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9840-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9840-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9840-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9840-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9840-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9840-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 0,
        price_in_aed: "10.60",
        final_price_with_margin: "32.50",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Mouse-Pads",
    master_code: "MO2174",
    master_id: "40009731",
    type_of_products: "stock",
    commodity_code: "3926 9097",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BETA",
    category_code: "MOBOFF_COMMOP",
    product_class: "Computer accessories",
    dimensions: "220X180X1,7 CM",
    length: "220",
    length_unit: "cm",
    width: "180",
    width_unit: "cm",
    height: "1.7",
    height_unit: "cm",
    volume: "0.067",
    volume_unit: "cdm3",
    gross_weight: "0.05",
    gross_weight_unit: "kg",
    net_weight: "0.045",
    net_weight_unit: "kg",
    outer_carton_quantity: "200",
    carton_length: "0.38",
    carton_length_unit: "m",
    carton_width: "0.24",
    carton_width_unit: "m",
    carton_height: "0.33",
    carton_height_unit: "m",
    carton_volume: "0.03",
    carton_volume_unit: "m3",
    carton_gross_weight: "10",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2174-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2174-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2174-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Recycled PU mouse mat",
    long_description:
      "Recycled PU mouse pad. With 58% recycled material (40% recycled polyester and 18% recycled PU).",
    material: "RPET-PU",
    printable: "yes",
    variants: [
      {
        variant_id: "10236009",
        sku: "MO2174-03",
        release_date: "2023-12-22",
        product_proposition_category: "304",
        category_level1: "Office & Writing",
        category_level2: "Office accessories ",
        category_level3: "Computer accessories",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941070271",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/desk-accessories/1-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/desk-accessories/1-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2174-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2174-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2174-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2174-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2174-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2174-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2174-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2174-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 9867,
        price_in_aed: "3.64",
        final_price_with_margin: "16.00",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Laptop-Mobile-Holders",
    master_code: "MO2289",
    master_id: "40009953",
    type_of_products: "stock",
    commodity_code: "4820 1090",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "MEMWEEK",
    category_code: "MOBOFF_DESORG",
    product_class: "Desk items",
    dimensions: "30.X1.6X21.4CM",
    length: "30",
    length_unit: "cm",
    width: "1.6",
    width_unit: "cm",
    height: "21.4",
    height_unit: "cm",
    volume: "1.25",
    volume_unit: "cdm3",
    gross_weight: "0.6",
    gross_weight_unit: "kg",
    net_weight: "0.59",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.33",
    carton_length_unit: "m",
    carton_width: "0.22",
    carton_width_unit: "m",
    carton_height: "0.33",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "12",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-14T15:00:11",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2289-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2289-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2289-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Weekly desktop planner",
    long_description:
      "125 page weekly desk planner in 6 languages (EN/DE/FR/ES/IT/NL/PL) with kraft paper cover, carton barrel blue ink push button pen, wooden ruler and sticky note memo pad.",
    material: "Paper",
    printable: "yes",
    variants: [
      {
        variant_id: "10237961",
        sku: "MO2289-13",
        release_date: "2024-07-19",
        product_proposition_category: "302",
        category_level1: "Office & Writing",
        category_level2: "Office accessories ",
        category_level3: "Memo pads/sticky notes",
        color_description: "Beige",
        color_group: "Brown",
        plc_status: "11",
        plc_status_description: "NEW",
        gtin: "8719941073654",
        color_code: "13",
        pms_color: "7499U",
        digital_assets: [
          {
            url: "/assets/img/accessories/desk-accessories/4-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/desk-accessories/4-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2289-13.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2289-13.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2289-13-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2289-13-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2289-13-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2289-13-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2289-13-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2289-13-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2289-13-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2289-13-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2289-13-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2289-13-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2289-13-picture3.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2289-13-picture3.jpg",
            type: "image",
            subtype: "item_picture_3",
          },
        ],
        available_quantity: 4262,
        price_in_aed: "24.60",
        final_price_with_margin: "59.00",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Mouse-Pads",
    master_code: "MO6411",
    master_id: "40008307",
    type_of_products: "stock",
    commodity_code: "4016 1000",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ERGOPAD",
    category_code: "MOBOFF_COMMOP",
    product_class: "Computer accessories",
    dimensions: "23,5X20X0,4 CM",
    length: "23.5",
    length_unit: "cm",
    width: "20",
    width_unit: "cm",
    height: "0.4",
    height_unit: "cm",
    volume: "0.63",
    volume_unit: "cdm3",
    gross_weight: "0.025",
    gross_weight_unit: "kg",
    net_weight: "0.016",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.42",
    carton_length_unit: "m",
    carton_width: "0.25",
    carton_width_unit: "m",
    carton_height: "0.42",
    carton_height_unit: "m",
    carton_volume: "0.044",
    carton_volume_unit: "m3",
    carton_gross_weight: "2.3",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6411-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6411-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6411-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "EVA ergonomic mouse mat",
    long_description: "EVA ergonomic mouse mat with wrist support.",
    material: "EVA",
    commercial_description:
      "EVA ergonomic mouse mat with wrist support. Spending a lot of time behind a computer or laptop at work can have quite an impact on your body. A good and healthy posture is important when sitting all day. An ergonomic mousepad can take some tension away from your wrist, resulting in less chance of pain and injuries.",
    printable: "yes",
    variants: [
      {
        variant_id: "10221886",
        sku: "MO6411-03",
        release_date: "2021-12-24",
        product_proposition_category: "304",
        category_level1: "Office & Writing",
        category_level2: "Office accessories ",
        category_level3: "Computer accessories",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941055711",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/desk-accessories/2-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/desk-accessories/2-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6411-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6411-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6411-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6411-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6411-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6411-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6411-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6411-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6411-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6411-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
        ],
        available_quantity: 37923,
        price_in_aed: "3.44",
        final_price_with_margin: "19.00",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Mouse-Pads",
    master_code: "MO6416",
    master_id: "40008311",
    type_of_products: "stock",
    commodity_code: "8504 4095",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SUPERPAD",
    category_code: "MOBOFF_TELMOC",
    product_class: "Chargers",
    dimensions: "31,5X27X0,5 CM",
    length: "31.5",
    length_unit: "cm",
    width: "27",
    width_unit: "cm",
    height: "0.5",
    height_unit: "cm",
    volume: "1.95",
    volume_unit: "cdm3",
    gross_weight: "0.355",
    gross_weight_unit: "kg",
    net_weight: "0.245",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.38",
    carton_length_unit: "m",
    carton_width: "0.305",
    carton_width_unit: "m",
    carton_height: "0.335",
    carton_height_unit: "m",
    carton_volume: "0.039",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.08",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6416-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6416-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6416-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6416-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo6416-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo6416-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo6416-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6416-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "RPET mouse mat charger 15W",
    long_description:
      "Multifunctional 2 tone 300D Polyester RPET mouse mat with 15W  wireless charger and phone stand functionality. Output: DC 9V/1.6A (15W ) for quick charging. Compatible with latest Androids, iPhone® 8 and newer.",
    material: "RPET",
    commercial_description:
      "Multifunctional 2 tone 300D polyester RPET mouse mat with 10W wireless charger and phone stand functionality.  Use a mousepad to enhance the speed and accuracy of your mouse compared to using it directly on the table or other surfaces. It also protects your desk from wear and tear over time. This mouse mat comes with a built-in 15W wireless charger to charge your smartphone when placed on the mat. Connect the mat to your computer or laptop to provide the charger with power. Output: DC 9V/1.6A (15W) for quick charging. Compatible with latest Androids, iPhone® 8 and newer.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10221866",
        sku: "MO6416-15",
        release_date: "2021-12-24",
        product_proposition_category: "183",
        category_level1: "Technology & Accessories",
        category_level2: "Wireless Chargers",
        category_level3: "Multifunctional",
        color_description: "Stone Grey",
        color_group: "Grey",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941055759",
        color_code: "15",
        pms_color: "COOL GRAY 11C",
        digital_assets: [
          {
            url: "/assets/img/accessories/desk-accessories/3-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/desk-accessories/3-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6416-15.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6416-15.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6416-15-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6416-15-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6416-15-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6416-15-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6416-15-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6416-15-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6416-15-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6416-15-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6416-15-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6416-15-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6416-15-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6416-15-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 8302,
        price_in_aed: "37.68",
        final_price_with_margin: "71.50",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Laptop-Mobile-Holders",
    master_code: "MO6603",
    master_id: "40008594",
    type_of_products: "stock",
    commodity_code: "4420 9099",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ROBIN",
    category_code: "MOBOFF_TELMOP",
    product_class: "Tablet accesories",
    dimensions: "17 X 7,6 X 1,5CM",
    length: "17",
    length_unit: "cm",
    width: "7.6",
    width_unit: "cm",
    height: "1.5",
    height_unit: "cm",
    volume: "0.25",
    volume_unit: "cdm3",
    gross_weight: "0.129",
    gross_weight_unit: "kg",
    net_weight: "0.114",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.41",
    carton_length_unit: "m",
    carton_width: "0.37",
    carton_width_unit: "m",
    carton_height: "0.2",
    carton_height_unit: "m",
    carton_volume: "0.03",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.86",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6603-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6603-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6603-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Bamboo tablet/smartphone stand",
    long_description:
      "Bamboo tablet or smartphone stand. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    commercial_description:
      "Bamboo tablet or smartphone stand. This natural phone stand can hold eithersmartphones or tablets in place. Ideal if you want to watch a movie or readsomething without having to hold the device. Bamboo is a natural product, theremay be slight variations in colour and size per item, which can affect the finaldecoration outcome.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10222476",
        sku: "MO6603-40",
        release_date: "2022-07-31",
        product_proposition_category: "186",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Phone stand/holders",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941058576",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/desk-accessories/5-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/desk-accessories/5-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6603-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6603-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6603-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6603-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6603-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6603-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6603-40-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6603-40-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6603-40-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6603-40-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6603-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6603-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6603-40-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6603-40-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6603-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6603-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 16402,
        price_in_aed: "6.60",
        final_price_with_margin: "23.00",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Laptop-Mobile-Holders",
    master_code: "MO6888",
    master_id: "40008979",
    type_of_products: "stock",
    commodity_code: "4420 9099",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "POY",
    category_code: "MOBOFF_TELMOP",
    product_class: "Smartphone accessories",
    dimensions: "7X2,5X1,9CM",
    length: "7",
    length_unit: "cm",
    width: "2.5",
    width_unit: "cm",
    height: "1.9",
    height_unit: "cm",
    volume: "0.043",
    volume_unit: "cdm3",
    gross_weight: "0.016",
    gross_weight_unit: "kg",
    net_weight: "0.012",
    net_weight_unit: "kg",
    outer_carton_quantity: "500",
    carton_length: "0.397",
    carton_length_unit: "m",
    carton_width: "0.19",
    carton_width_unit: "m",
    carton_height: "0.29",
    carton_height_unit: "m",
    carton_volume: "0.022",
    carton_volume_unit: "m3",
    carton_gross_weight: "6.86",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6888-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6888-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6888-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Mini bamboo phone stand",
    long_description:
      "Mini smartphone stand in bamboo. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10224380",
        sku: "MO6888-40",
        release_date: "2022-12-26",
        product_proposition_category: "186",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Phone stand/holders",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941065260",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/desk-accessories/6-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/desk-accessories/6-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6888-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6888-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6888-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6888-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6888-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6888-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
        available_quantity: 9851,
        price_in_aed: "1.36",
        final_price_with_margin: "11.00",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Laptop-Mobile-Holders",
    master_code: "MO9391",
    master_id: "40006917",
    type_of_products: "stock",
    commodity_code: "8504 4095",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "CLEANDESK",
    category_code: "MOBOFF_TELMOC",
    product_class: "Chargers",
    dimensions: "27X14,5X1,5 CM",
    length: "27",
    length_unit: "cm",
    width: "27",
    width_unit: "cm",
    height: "14.5",
    height_unit: "cm",
    volume: "1.55",
    volume_unit: "cdm3",
    gross_weight: "0.45",
    gross_weight_unit: "kg",
    net_weight: "0.331",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.325",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.3",
    carton_height_unit: "m",
    carton_volume: "0.031",
    carton_volume_unit: "m3",
    carton_gross_weight: "8.4",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9391-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9391-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9391-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9391-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9391-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo9391-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo9391-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9391-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Wireless charger 5W",
    long_description:
      "Desk storage desk box in bamboo with wireless charger. Including 80 cm USB cable charger. Output DC5V/1A (5W). Compatible with all QI enabled devices such as the latest androids, iPhone® 8, 8S and X and newer. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10209893",
        sku: "MO9391-40",
        release_date: "2018-08-01",
        product_proposition_category: "183",
        category_level1: "Technology & Accessories",
        category_level2: "Wireless Chargers",
        category_level3: "Multifunctional",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941001114",
        color_code: "40",
        pms_color: "BAMBOO",
        digital_assets: [
          {
            url: "/assets/img/accessories/desk-accessories/7-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/desk-accessories/7-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9391-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9391-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9391-40a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9391-40a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9391-40c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9391-40c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9391-40d.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9391-40d.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9391-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9391-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9391-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9391-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9391-40-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9391-40-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 4191,
        price_in_aed: "34.40",
        final_price_with_margin: "69.00",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Laptop-Mobile-Holders",
    master_code: "MO9692",
    master_id: "40007330",
    type_of_products: "stock",
    commodity_code: "8504 4095",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "WIRESTAND",
    category_code: "MOBOFF_TELMOC",
    product_class: "Chargers",
    dimensions: "7,1X8X11,4 CM",
    length: "7.1",
    length_unit: "cm",
    width: "8",
    width_unit: "cm",
    height: "11.4",
    height_unit: "cm",
    volume: "1.123",
    volume_unit: "cdm3",
    gross_weight: "0.18",
    gross_weight_unit: "kg",
    net_weight: "0.123",
    net_weight_unit: "kg",
    outer_carton_quantity: "40",
    carton_length: "0.42",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.35",
    carton_height_unit: "m",
    carton_volume: "0.044",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.46",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9692-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9692-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9692-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9692-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9692-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo9692-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo9692-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9692-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Bamboo wireless charge stand5W",
    long_description:
      "Double coil wireless charger for 1 device in bamboo casing and with stand functionality. Place smartphone on it to begin charging. Output: DC5V/1A (5W). Compatible with latest Androids, iPhone® 8, X and newer. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10224512",
        sku: "MO9692-03",
        release_date: "2022-12-26",
        product_proposition_category: "183",
        category_level1: "Technology & Accessories",
        category_level2: "Wireless Chargers",
        category_level3: "Multifunctional",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941065918",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9692-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9692-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9692-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9692-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9692-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9692-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9692-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9692-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9692-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9692-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9692-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9692-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 3144,
        price_in_aed: "26.16",
        final_price_with_margin: "52.50",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Laptop-Mobile-Holders",
    master_code: "MO9706",
    master_id: "40007339",
    type_of_products: "stock",
    commodity_code: "4420 9099",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "CARACOL",
    category_code: "MOBOFF_TELMOS",
    product_class: "Speakers",
    dimensions: "10X6X3,4 CM",
    length: "10",
    length_unit: "cm",
    width: "10",
    width_unit: "cm",
    height: "6",
    height_unit: "cm",
    volume: "0.421",
    volume_unit: "cdm3",
    gross_weight: "0.119",
    gross_weight_unit: "kg",
    net_weight: "0.105",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.235",
    carton_width_unit: "m",
    carton_height: "0.375",
    carton_height_unit: "m",
    carton_volume: "0.038",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.16",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9706-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9706-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9706-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Bamboo phone stand-amplifier",
    long_description:
      "Smartphone stand and amplifier in bamboo material. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10212670",
        sku: "MO9706-40",
        release_date: "2019-08-01",
        product_proposition_category: "186",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Phone stand/holders",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941042872",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/desk-accessories/9-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/desk-accessories/9-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9706-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9706-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9706-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9706-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9706-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9706-40-side.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9706-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9706-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9706-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9706-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9706-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9706-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 12815,
        price_in_aed: "7.72",
        final_price_with_margin: "23.50",
      },
    ],
  },
  {
    main_category_name: "Desk-Accessories",
    category_name: "Laptop-Mobile-Holders",
    master_code: "MO9914",
    master_id: "40007728",
    type_of_products: "stock",
    commodity_code: "8504 4095",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BAICOI",
    category_code: "MOBOFF_TELMOC",
    product_class: "Desk items",
    dimensions: "7,7X7,7X11,5 CM",
    length: "7.7",
    length_unit: "cm",
    width: "7.7",
    width_unit: "cm",
    height: "11.5",
    height_unit: "cm",
    volume: "1.325",
    volume_unit: "cdm3",
    gross_weight: "0.256",
    gross_weight_unit: "kg",
    net_weight: "0.19",
    net_weight_unit: "kg",
    outer_carton_quantity: "40",
    carton_length: "0.45",
    carton_length_unit: "m",
    carton_width: "0.375",
    carton_width_unit: "m",
    carton_height: "0.315",
    carton_height_unit: "m",
    carton_volume: "0.053",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.84",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9914-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9914-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9914-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9914-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9914-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo9914-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo9914-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9914-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Wireless charger pen holder 5W",
    long_description:
      "Double coil wireless charger for 1 device in bamboo casing and with stand  and pen holder functionality.  Output: DC5V/1A (5W). Compatible with latest Androids, iPhone® 8, X and newer. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10214857",
        sku: "MO9914-40",
        release_date: "2020-08-01",
        product_proposition_category: "183",
        category_level1: "Technology & Accessories",
        category_level2: "Wireless Chargers",
        category_level3: "Multifunctional",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941048867",
        color_code: "40",
        pms_color: "BAMBOO",
        digital_assets: [
          {
            url: "/assets/img/accessories/desk-accessories/10-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/desk-accessories/10-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9914-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9914-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9914-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9914-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9914-40c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9914-40c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9914-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9914-40-side.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9914-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9914-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9914-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9914-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 9152,
        price_in_aed: "33.80",
        final_price_with_margin: "62.50",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "NoteBooks",
    master_code: "AR1804",
    master_id: "40000190",
    type_of_products: "stock",
    commodity_code: "4820 1030",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ARCONOT",
    category_code: "MOBOFF_NOTOTH",
    product_class: "Notebooks & notepads",
    dimensions: "21X14X1,6 CM",
    length: "21",
    length_unit: "cm",
    width: "14",
    width_unit: "cm",
    height: "1.6",
    height_unit: "cm",
    volume: "0.62",
    volume_unit: "cdm3",
    gross_weight: "0.3",
    gross_weight_unit: "kg",
    net_weight: "0.288",
    net_weight_unit: "kg",
    outer_carton_quantity: "40",
    carton_length: "0.33",
    carton_length_unit: "m",
    carton_width: "0.23",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.024",
    carton_volume_unit: "m3",
    carton_gross_weight: "12",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-12T14:28:21",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/ar1804-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/ar1804-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/ar1804-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "A5 notebook 96 plain sheets",
    long_description:
      "A5 notebook with hard PU cover. Casebound. 192 plain pages (96 sheets). Matching elastic closure strap and ribbon page-marker.",
    material: "Paper",
    green: "yes",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10168709",
        sku: "AR1804-03",
        release_date: "2015-01-01",
        product_proposition_category: "295",
        category_level1: "Office & Writing",
        category_level2: "Notebooks",
        category_level3: "Hard cover",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941012868",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/stationaries/1-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/stationaries/1-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/ar1804-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/ar1804-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/ar1804-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/ar1804-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/ar1804-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/ar1804-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
        available_quantity: 64109,
        price_in_aed: "12.60",
        final_price_with_margin: "36.00",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "Pens",
    master_code: "IT3350",
    master_id: "40000558",
    type_of_products: "stock",
    commodity_code: "9608 1092",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "PREMIER",
    category_code: "MOBWRI_PENPBO",
    product_class: "Pen",
    dimensions: "Ø2,8X16 CM",
    length: "0",
    length_unit: "cm",
    width: "2.8",
    width_unit: "cm",
    height: "16",
    height_unit: "cm",
    volume: "0.214",
    volume_unit: "cdm3",
    gross_weight: "0.074",
    gross_weight_unit: "kg",
    net_weight: "0.062",
    net_weight_unit: "kg",
    inner_carton_quantity: "25",
    outer_carton_quantity: "200",
    carton_length: "0.32",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.37",
    carton_height_unit: "m",
    carton_volume: "0.038",
    carton_volume_unit: "m3",
    carton_gross_weight: "14.92",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/it3350-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/it3350-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/it3350-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Metal ball pen in box",
    long_description:
      "Metal ball pen with silver chrome finish and PU leather application in aluminium tube box. Black ink.",
    material: "Metal",
    printable: "yes",
    variants: [
      {
        variant_id: "10152576",
        sku: "IT3350-03",
        release_date: "2015-01-01",
        product_proposition_category: "311",
        category_level1: "Office & Writing",
        category_level2: "Writing",
        category_level3: "Pens",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941002630",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/stationaries/2-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/stationaries/2-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it3350-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it3350-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it3350-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it3350-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it3350-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it3350-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 2361,
        price_in_aed: "16.40",
        final_price_with_margin: "34.00",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "NoteBooks",
    master_code: "MO2195",
    master_id: "40009752",
    type_of_products: "stock",
    commodity_code: "4820 1030",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ELEGANOTE",
    category_code: "MOBOFF_NOTOTH",
    product_class: "Notebooks & notepads",
    dimensions: "24X1.6X19.4CM",
    length: "24",
    length_unit: "cm",
    width: "1.6",
    width_unit: "cm",
    height: "19.4",
    height_unit: "cm",
    volume: "0.52",
    volume_unit: "cdm3",
    gross_weight: "0.38",
    gross_weight_unit: "kg",
    net_weight: "0.37",
    net_weight_unit: "kg",
    inner_carton_quantity: "15",
    outer_carton_quantity: "30",
    carton_length: "0.44",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.27",
    carton_height_unit: "m",
    carton_volume: "0.038",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2195-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2195-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2195-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Recycled leather notebook set",
    long_description:
      "Gift set. Recycled leather (75%) A5 notebook with hard cover. Casebound. 160 lined pages made out of recycled paper (80 sheets). Matching elastic closure strap, ribbon page-marker and pen holder. Matching twist metal pen. Blue ink. Presented in a craft paper box.",
    printable: "yes",
    variants: [
      {
        variant_id: "10236087",
        sku: "MO2195-13",
        release_date: "2023-12-22",
        product_proposition_category: "297",
        category_level1: "Office & Writing",
        category_level2: "Notebooks",
        category_level3: "Sets",
        color_description: "Beige",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941070790",
        color_code: "13",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2195-13.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2195-13.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2195-13-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2195-13-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2195-13-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2195-13-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2195-13-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2195-13-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2195-13-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2195-13-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2195-13-picture1.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2195-13-picture1.jpg",
            type: "image",
            subtype: "item_picture_1",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2195-13-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2195-13-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 4700,
        price_in_aed: "22.60",
        final_price_with_margin: "50.00",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "NoteBooks",
    master_code: "MO6576",
    master_id: "40008569",
    type_of_products: "stock",
    commodity_code: "4820 1030",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BLAMA",
    category_code: "MOBOFF_NOTREC",
    product_class: "Notebooks & notepads",
    dimensions: "21X14.5X1.8CM",
    length: "21",
    length_unit: "cm",
    width: "14.5",
    width_unit: "cm",
    height: "1.8",
    height_unit: "cm",
    volume: "0.7",
    volume_unit: "cdm3",
    gross_weight: "0.3",
    gross_weight_unit: "kg",
    net_weight: "0.295",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.415",
    carton_length_unit: "m",
    carton_width: "0.335",
    carton_width_unit: "m",
    carton_height: "0.24",
    carton_height_unit: "m",
    carton_volume: "0.033",
    carton_volume_unit: "m3",
    carton_gross_weight: "15.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6576-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6576-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6576-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6576-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "A5 RPET notebook 80 lined",
    long_description:
      "A5 notebook with 600D RPET hard cover with bamboo detail with a smartphone stand function. Casebound. 160 lined pages (80 sheets). 70 gsm recycled paper. Matching elastic closure strap, ribbon page-marker and pen holder. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Paper",
    commercial_description:
      "A5 notebook with 600D RPET hard cover with bamboo detail with a smartphone standfunction. Casebound. 160 lined pages (80 sheets). 70 gsm recycled paper.Matching elastic closure strap, ribbon page-marker and pen holder. Take thisoffice essential with you to your next meeting. Having a remote meeting? Foldopen the phone holder and put in your device so you don't have to hold ityourself.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10222436",
        sku: "MO6576-03",
        release_date: "2022-07-31",
        product_proposition_category: "295",
        category_level1: "Office & Writing",
        category_level2: "Notebooks",
        category_level3: "Hard cover",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941057975",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/stationaries/4-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/stationaries/4-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6576-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6576-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6576-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6576-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6576-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6576-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6576-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6576-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6576-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6576-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6576-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6576-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6576-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6576-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6576-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6576-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 19042,
        price_in_aed: "14.56",
        final_price_with_margin: "37.50",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "Pens",
    master_code: "MO6795",
    master_id: "40008892",
    type_of_products: "stock",
    commodity_code: "9608 1092",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "GRAZ SET",
    category_code: "MOBWRI_PENSET",
    product_class: "Pen",
    dimensions: "17,5X5,5X2,5 CM",
    length: "17.5",
    length_unit: "cm",
    width: "5.5",
    width_unit: "cm",
    height: "2.5",
    height_unit: "cm",
    volume: "0.38",
    volume_unit: "cdm3",
    gross_weight: "0.115",
    gross_weight_unit: "kg",
    net_weight: "0.091",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "100",
    carton_length: "0.39",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.305",
    carton_height_unit: "m",
    carton_volume: "0.038",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.86",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6795-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6795-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6795-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Recycled stainless steel pens",
    long_description:
      "Recycled stainless steel pen set with ball pen and roller ball. Presented in matching cork box. Blue and black ink respectively. Cork is 100% natural material. Due to its structural nature and surface porosity the final print result per item may have deviations.",
    material: "Paper",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223630",
        sku: "MO6795-16",
        release_date: "2022-12-26",
        product_proposition_category: "315",
        category_level1: "Office & Writing",
        category_level2: "Writing",
        category_level3: "Sets",
        color_description: "Matt Silver",
        color_group: "Silver",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941063150",
        color_code: "16",
        pms_color: "SILVER",
        digital_assets: [
          {
            url: "/assets/img/accessories/stationaries/5-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/stationaries/5-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6795-16.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6795-16.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6795-16-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6795-16-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6795-16-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6795-16-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
        available_quantity: 7705,
        price_in_aed: "16.08",
        final_price_with_margin: "35.50",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "Pens",
    master_code: "MO7318",
    master_id: "40001864",
    type_of_products: "stock",
    commodity_code: "9608 1092",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SUMATRA",
    category_code: "MOBWRI_PENWOD",
    product_class: "Pen",
    dimensions: "Ø1X13,5 CM",
    length: "13.5",
    length_unit: "cm",
    width: "1",
    width_unit: "cm",
    height: "13.5",
    height_unit: "cm",
    volume: "0.034",
    volume_unit: "cdm3",
    gross_weight: "0.01",
    gross_weight_unit: "kg",
    net_weight: "0.009",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "1000",
    carton_length: "0.5",
    carton_length_unit: "m",
    carton_width: "0.31",
    carton_width_unit: "m",
    carton_height: "0.22",
    carton_height_unit: "m",
    carton_volume: "0.034",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.08",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo7318-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo7318-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo7318-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Bamboo automatic ball pen",
    long_description:
      "Push button ball pen in bamboo with shiny chrome fitting. Blue ink. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10172835",
        sku: "MO7318-40",
        release_date: "2015-01-01",
        product_proposition_category: "311",
        category_level1: "Office & Writing",
        category_level2: "Writing",
        category_level3: "Pens",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941010666",
        color_code: "40",
        pms_color: "WOOD COLOR",
        digital_assets: [
          {
            url: "/assets/img/accessories/stationaries/6-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/stationaries/6-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo7318-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo7318-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo7318-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo7318-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo7318-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo7318-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 1369408,
        price_in_aed: "0.84",
        final_price_with_margin: "10.00",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "Pens",
    master_code: "MO7475",
    master_id: "40002128",
    type_of_products: "stock",
    commodity_code: "9608 1092",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BALTIMORE",
    category_code: "MOBWRI_PENSET",
    product_class: "Pen",
    dimensions: "17X6,5X1,5 CM",
    length: "17",
    length_unit: "cm",
    width: "6.5",
    width_unit: "cm",
    height: "1.5",
    height_unit: "cm",
    volume: "0.359",
    volume_unit: "cdm3",
    gross_weight: "0.105",
    gross_weight_unit: "kg",
    net_weight: "0.078",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "100",
    carton_length: "0.37",
    carton_length_unit: "m",
    carton_width: "0.25",
    carton_width_unit: "m",
    carton_height: "0.365",
    carton_height_unit: "m",
    carton_volume: "0.034",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo7475-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo7475-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo7475-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Ball pen and roller set",
    long_description:
      "Metal pen set with ball pen and roller ball, presented in a zipped PU leather pouch in matching colour and contrasted stitching. Blue and black ink respectively.",
    material: "PU",
    printable: "yes",
    variants: [
      {
        variant_id: "10175726",
        sku: "MO7475-03",
        release_date: "2015-01-01",
        product_proposition_category: "315",
        category_level1: "Office & Writing",
        category_level2: "Writing",
        category_level3: "Sets",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941009134",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo7475-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo7475-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo7475-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo7475-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo7475-03b.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo7475-03b.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
        available_quantity: 10522,
        price_in_aed: "14.56",
        final_price_with_margin: "34.00",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "Pens",
    master_code: "MO8217",
    master_id: "40003440",
    type_of_products: "stock",
    commodity_code: "9608 1092",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "DERRICK",
    category_code: "MOBWRI_PENSET",
    product_class: "Pen",
    dimensions: "17X6,5X1,5 CM",
    length: "17",
    length_unit: "cm",
    width: "6.5",
    width_unit: "cm",
    height: "1.5",
    height_unit: "cm",
    volume: "0.713",
    volume_unit: "cdm3",
    gross_weight: "0.25",
    gross_weight_unit: "kg",
    net_weight: "0.203",
    net_weight_unit: "kg",
    inner_carton_quantity: "25",
    outer_carton_quantity: "50",
    carton_length: "0.435",
    carton_length_unit: "m",
    carton_width: "0.38",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.03",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.4",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo8217-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8217-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo8217-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Ball pen set in box",
    long_description:
      "Pen set in gift box. Brass twist ball pen and roller ball. Blue / black ink.",
    material: "PU",
    printable: "yes",
    variants: [
      {
        variant_id: "10181493",
        sku: "MO8217-18",
        release_date: "2015-01-01",
        product_proposition_category: "315",
        category_level1: "Office & Writing",
        category_level2: "Writing",
        category_level3: "Sets",
        color_description: "Titanium",
        color_group: "Silver",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941006058",
        color_code: "18",
        pms_color: "COOL GRAY 9C",
        digital_assets: [
          {
            url: "/assets/img/accessories/stationaries/7-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/stationaries/7-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8217-18.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8217-18.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8217-18-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8217-18-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8217-18c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8217-18c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8217-18d.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8217-18d.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8217-18-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8217-18-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8217-18-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8217-18-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8217-18-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8217-18-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 1941,
        price_in_aed: "32.12",
        final_price_with_margin: "62.00",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "NoteBooks",
    master_code: "MO9348",
    master_id: "40006824",
    type_of_products: "stock",
    commodity_code: "4820 1030",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "NEILO SET",
    category_code: "MOBOFF_NOTOTH",
    product_class: "Notebooks & notepads",
    dimensions: "14X1X21 CM",
    length: "14",
    length_unit: "cm",
    width: "1",
    width_unit: "cm",
    height: "21",
    height_unit: "cm",
    volume: "0.685",
    volume_unit: "cdm3",
    gross_weight: "0.315",
    gross_weight_unit: "kg",
    net_weight: "0.297",
    net_weight_unit: "kg",
    outer_carton_quantity: "30",
    carton_length: "0.315",
    carton_length_unit: "m",
    carton_width: "0.265",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.022",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.76",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9348-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9348-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
    ],
    short_description: "A5 notebook w/stylus 72 lined",
    long_description:
      "A5 notebook with 2-in-1 stylus ball pen. Hard PU cover. Casebound. 144 lined pages (72 sheets). Matching ball pen with touch screen stylus. Blue ink. Matching elastic closure strap and ribbon bookmark.",
    material: "Paper",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10208925",
        sku: "MO9348-03",
        release_date: "2018-01-01",
        product_proposition_category: "295",
        category_level1: "Office & Writing",
        category_level2: "Notebooks",
        category_level3: "Hard cover",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941005044",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/stationaries/8-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/stationaries/8-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9348-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9348-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9348-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9348-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9348-03c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9348-03c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9348-03d.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9348-03d.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
        ],
        available_quantity: 12783,
        price_in_aed: "16.96",
        final_price_with_margin: "41.50",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "Pens",
    master_code: "MO9393",
    master_id: "40006948",
    type_of_products: "stock",
    commodity_code: "9608 1092",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "NEGRITO",
    category_code: "MOBWRI_PENPMU",
    product_class: "Pen",
    dimensions: "Ø1X14 CM",
    length: "0",
    length_unit: "cm",
    width: "1",
    width_unit: "cm",
    height: "14",
    height_unit: "cm",
    volume: "0.027",
    volume_unit: "cdm3",
    gross_weight: "0.02",
    gross_weight_unit: "kg",
    net_weight: "0.018",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "600",
    carton_length: "0.31",
    carton_length_unit: "m",
    carton_width: "0.29",
    carton_width_unit: "m",
    carton_height: "0.2",
    carton_height_unit: "m",
    carton_volume: "0.018",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.58",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9393-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9393-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9393-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Aluminium stylus pen",
    long_description:
      "Push button stylus pen in aluminium. When the logo is engraved on to the barrel, the colour revealed matches the colour of the touch tip. Blue ink.",
    material: "Aluminium",
    printable: "yes",
    variants: [
      {
        variant_id: "10210025",
        sku: "MO9393-98",
        release_date: "2018-08-01",
        product_proposition_category: "314",
        category_level1: "Office & Writing",
        category_level2: "Writing",
        category_level3: "Multifunctional",
        color_description: "Gold",
        color_group: "Gold",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941010819",
        color_code: "98",
        pms_color: "457C",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9393-98.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9393-98.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9393-98a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9393-98a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9393-98b.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9393-98b.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
        available_quantity: 85933,
        price_in_aed: "1.56",
        final_price_with_margin: "11.00",
      },
    ],
  },
  {
    main_category_name: "Stationaries",
    category_name: "NoteBooks",
    master_code: "MO9859",
    master_id: "40007561",
    type_of_products: "stock",
    commodity_code: "4820 1030",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SONORA PLUSCORK",
    category_code: "MOBOFF_NOTREC",
    product_class: "Notebooks & notepads",
    dimensions: "18X13X0,7 CM",
    length: "18",
    length_unit: "cm",
    width: "13",
    width_unit: "cm",
    height: "0.7",
    height_unit: "cm",
    volume: "0.471",
    volume_unit: "cdm3",
    gross_weight: "0.162",
    gross_weight_unit: "kg",
    net_weight: "0.145",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.385",
    carton_length_unit: "m",
    carton_width: "0.31",
    carton_width_unit: "m",
    carton_height: "0.335",
    carton_height_unit: "m",
    carton_volume: "0.04",
    carton_volume_unit: "m3",
    carton_gross_weight: "15.39",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-09T13:33:09",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9859-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9859-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9859-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Cork notebook with pen",
    long_description:
      "Cork notebook with pen. Soft cork cover. Twin-wire. 140 lined recyced paper pages (70 sheets). Matching push button ball pen with recycled carton barrel and corn PLA details. Black ink. Elastic pen holder. Cork is 100% natural material. Due to its structural nature and surface porosity the final print result per item may have deviations.",
    material: "Recycled Paper",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10214000",
        sku: "MO9859-03",
        release_date: "2020-01-01",
        product_proposition_category: "294",
        category_level1: "Office & Writing",
        category_level2: "Notebooks",
        category_level3: "Soft cover",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941047082",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/stationaries/10-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/stationaries/10-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9859-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9859-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9859-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9859-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9859-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9859-03-side.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
        ],
        available_quantity: 44793,
        price_in_aed: "8.12",
        final_price_with_margin: "27.00",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Wireless-Chargers",
    master_code: "MO9434",
    master_id: "40006951",
    type_of_products: "stock",
    commodity_code: "8504 4095",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "RUNDO",
    category_code: "MOBOFF_TELMOC",
    product_class: "Chargers",
    dimensions: "Ø9X0,8 CM",
    length: "0",
    length_unit: "cm",
    width: "9",
    width_unit: "cm",
    height: "0.8",
    height_unit: "cm",
    volume: "0.25",
    volume_unit: "cdm3",
    gross_weight: "0.078",
    gross_weight_unit: "kg",
    net_weight: "0.046",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.5",
    carton_length_unit: "m",
    carton_width: "0.185",
    carton_width_unit: "m",
    carton_height: "0.27",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "6.16",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9434-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9434-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9434-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9434-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9434-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo9434-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo9434-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9434-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Wireless charger bamboo 5W",
    long_description:
      "Wireless charger in bamboo. Connect device to your computer, place smartphone on it and allow it tocharge. Blue light lit when charging. Output: DC5V/1A (5W). Compatible with all QI enabled devices such as latest Androids, iPhone® 8, X and newer. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10224387",
        sku: "MO9434-03",
        release_date: "2022-12-26",
        product_proposition_category: "446",
        category_level1: "Technology & Accessories",
        category_level2: "Wireless Chargers",
        category_level3: "Wireless charger 5W",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941065000",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/1-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/1-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9434-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9434-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9434-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9434-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9434-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9434-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9434-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9434-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 16,
        price_in_aed: "11.96",
        final_price_with_margin: "27.00",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Wireless-Chargers",
    master_code: "MO6761",
    master_id: "40008858",
    type_of_products: "stock",
    commodity_code: "8504 4095",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "RESS",
    category_code: "MOBOFF_TELMOC",
    product_class: "Chargers",
    dimensions: "Ø9,9X0,7 CM",
    length: "0",
    length_unit: "cm",
    width: "9.9",
    width_unit: "cm",
    height: "0.7",
    height_unit: "cm",
    volume: "0.3",
    volume_unit: "cdm3",
    gross_weight: "0.11",
    gross_weight_unit: "kg",
    net_weight: "0.1",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.385",
    carton_length_unit: "m",
    carton_width: "0.297",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.03",
    carton_volume_unit: "m3",
    carton_gross_weight: "11",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6761-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6761-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6761-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6761-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo6761-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo6761-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo6761-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
    ],
    short_description: "Glass wireless 10W charger",
    long_description:
      "Aluminium Wireless charger 10W with glass on top. Connect device to your computer, lay down smartphone on it and let it charge. Blue light when charging. Output: DC 9V/1.1A. Compatible latest androids, iPhone® 8, X and newer.",
    material: "Glass",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223814",
        sku: "MO6761-03",
        release_date: "2022-12-26",
        product_proposition_category: "447",
        category_level1: "Technology & Accessories",
        category_level2: "Wireless Chargers",
        category_level3: "Quick chargers ≥10W",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941062399",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/2-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/2-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6761-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6761-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6761-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6761-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6761-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6761-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6761-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6761-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6761-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6761-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 3090,
        price_in_aed: "10.32",
        final_price_with_margin: "36.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Wireless-Chargers",
    master_code: "MO2184",
    master_id: "40009741",
    type_of_products: "stock",
    commodity_code: "8504 4095",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ACAWAI",
    category_code: "MOBOFF_TELMOC",
    product_class: "Chargers",
    dimensions: "Ø9,1X0,9 CM",
    length: "0",
    length_unit: "cm",
    width: "9.1",
    width_unit: "cm",
    height: "0.9",
    height_unit: "cm",
    volume: "0.27",
    volume_unit: "cdm3",
    gross_weight: "0.078",
    gross_weight_unit: "kg",
    net_weight: "0.073",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.557",
    carton_length_unit: "m",
    carton_width: "0.173",
    carton_width_unit: "m",
    carton_height: "0.283",
    carton_height_unit: "m",
    carton_volume: "0.027",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.8",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2184-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2184-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2184-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo2184-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo2184-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo2184-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo2184-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
    ],
    short_description: "Wireless charger in acacia 15W",
    long_description:
      "Wireless charger in acacia wood and recycled PU, which is 58% recycled (40% recycled polyester and 18% recycled PU).  Output: DC 9V/1.67A (15W). Compatible with all QI enabled devices such as latest Androids, iPhone® 8, X and newer.",
    material: "Wood",
    printable: "yes",
    variants: [
      {
        variant_id: "10236067",
        sku: "MO2184-03",
        release_date: "2023-12-22",
        product_proposition_category: "447",
        category_level1: "Technology & Accessories",
        category_level2: "Wireless Chargers",
        category_level3: "Quick chargers ≥10W",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941070462",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/3-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/3-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2184-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2184-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2184-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2184-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2184-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2184-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2184-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2184-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2184-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2184-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2184-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2184-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2184-03-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2184-03-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 6039,
        price_in_aed: "16.88",
        final_price_with_margin: "34.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Power-Banks",
    master_code: "MO9498",
    master_id: "40007059",
    type_of_products: "stock",
    commodity_code: "8507 6000",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "POWER&WIRELESS",
    category_code: "MOBOFF_POW",
    product_class: "Powerbanks",
    dimensions: "12X6,5X0,9 CM",
    length: "12",
    length_unit: "cm",
    width: "6.5",
    width_unit: "cm",
    height: "0.9",
    height_unit: "cm",
    volume: "0.38",
    volume_unit: "cdm3",
    gross_weight: "0.18",
    gross_weight_unit: "kg",
    net_weight: "0.133",
    net_weight_unit: "kg",
    inner_carton_quantity: "25",
    outer_carton_quantity: "50",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.14",
    carton_height_unit: "m",
    carton_volume: "0.019",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.98",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9498-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9498-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9498-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9498-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9498-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo9498-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo9498-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo9498-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo9498-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
    ],
    short_description: "Wireless Power bank 4000mAh",
    long_description:
      "Wireless aluminium charging power bank with 4000 mAh capacity. Includes Type C connector. Output: DC5V/0.8A. Compatible latest androids, iPhone® 8, 8S and X and newer.",
    material: "Battery",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10210466",
        sku: "MO9498-03",
        release_date: "2019-01-01",
        product_proposition_category: "443",
        category_level1: "Technology & Accessories",
        category_level2: "Power banks",
        category_level3: "Mid capacity ≥4.000 ",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941036000",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/4-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/4-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9498-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9498-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9498-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9498-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9498-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9498-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9498-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9498-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 3718,
        price_in_aed: "27.56",
        final_price_with_margin: "55.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Power-Banks",
    master_code: "MO9051",
    master_id: "40006284",
    type_of_products: "stock",
    commodity_code: "8501 7100",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SOLAR POWERFLAT",
    category_code: "MOBOFF_POW",
    product_class: "Powerbanks",
    dimensions: "7,5X15X1 CM",
    length: "7.5",
    length_unit: "cm",
    width: "15",
    width_unit: "cm",
    height: "1",
    height_unit: "cm",
    volume: "0.42",
    volume_unit: "cdm3",
    gross_weight: "0.24",
    gross_weight_unit: "kg",
    net_weight: "0.22",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.53",
    carton_length_unit: "m",
    carton_width: "0.205",
    carton_width_unit: "m",
    carton_height: "0.19",
    carton_height_unit: "m",
    carton_volume: "0.021",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.16",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9051-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9051-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9051-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9051-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo9051-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo9051-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo9051-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
      {
        url: "https://cdn1.midocean.com/document/test-various/mo9051-test-various.pdf",
        type: "document",
        subtype: "test_various",
      },
    ],
    short_description: "Solar power bank 8000 mAh",
    long_description:
      "Power bank 8000 mAh in aluminium and solar panel. Capacity for smartphone use, output current DC5V/1A and DC5V/2A. Includes indicating light and USB cable with micro usb plug.",
    material: "Aluminium",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10205928",
        sku: "MO9051-03",
        release_date: "2017-01-01",
        product_proposition_category: "444",
        category_level1: "Technology & Accessories",
        category_level2: "Power banks",
        category_level3: "High capacity ≥8.000 ",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941002999",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/5-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/5-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9051-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9051-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9051-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9051-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9051-03c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9051-03c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9051-03d.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9051-03d.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9051-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9051-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 10100,
        price_in_aed: "33.04",
        final_price_with_margin: "64.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Power-Banks",
    master_code: "MO8839",
    master_id: "40006040",
    type_of_products: "stock",
    commodity_code: "8507 6000",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "POWERFLAT8",
    category_code: "MOBOFF_POW",
    product_class: "Powerbanks",
    dimensions: "15X7,5X0,9 CM",
    length: "15",
    length_unit: "cm",
    width: "7.5",
    width_unit: "cm",
    height: "0.9",
    height_unit: "cm",
    volume: "0.36",
    volume_unit: "cdm3",
    gross_weight: "0.25",
    gross_weight_unit: "kg",
    net_weight: "0.221",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.52",
    carton_length_unit: "m",
    carton_width: "0.19",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.018",
    carton_volume_unit: "m3",
    carton_gross_weight: "12",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo8839-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8839-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo8839-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo8839-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo8839-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo8839-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo8839-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo8839-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
      {
        url: "https://cdn1.midocean.com/document/test-various/mo8839-test-various.pdf",
        type: "document",
        subtype: "test_various",
      },
    ],
    short_description: "Power bank 8000 mAh",
    long_description:
      "Power bank 8000 mAh in aluminium. Capacity for smartphone use, output current DC5V/1A and DC5V/2A . Includes indicating light and USB cable with micro usb plug.",
    material: "Aluminium",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10203817",
        sku: "MO8839-03",
        release_date: "2016-08-01",
        product_proposition_category: "444",
        category_level1: "Technology & Accessories",
        category_level2: "Power banks",
        category_level3: "High capacity ≥8.000 ",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941007383",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/6-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/6-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8839-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8839-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8839-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8839-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8839-03b.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8839-03b.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8839-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8839-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 4606,
        price_in_aed: "27.84",
        final_price_with_margin: "58.00",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Electric-Accessories",
    master_code: "MO2141",
    master_id: "40009701",
    type_of_products: "stock",
    commodity_code: "8544 4290",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BLUE",
    category_code: "MOBOFF_TELEXT",
    product_class: "Key ring",
    dimensions: "17X2,4X1,7 CM",
    length: "17",
    length_unit: "cm",
    width: "2.4",
    width_unit: "cm",
    height: "1.7",
    height_unit: "cm",
    volume: "0.086",
    volume_unit: "cdm3",
    gross_weight: "0.024",
    gross_weight_unit: "kg",
    net_weight: "0.022",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "400",
    carton_length: "0.4",
    carton_length_unit: "m",
    carton_width: "0.292",
    carton_width_unit: "m",
    carton_height: "0.371",
    carton_height_unit: "m",
    carton_volume: "0.043",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.8",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2141-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2141-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2141-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo2141-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
    ],
    short_description: "4 in 1 charging cable type C",
    long_description:
      "Key ring with charging cable with input: 2-in-1 type-C & type-A and micro-B (2 in 1 pin) and Type C output.",
    material: "Aluminium",
    printable: "yes",
    variants: [
      {
        variant_id: "10235866",
        sku: "MO2141-03",
        release_date: "2023-12-22",
        product_proposition_category: "189",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Cables",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941069534",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/7-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/7-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2141-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2141-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2141-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2141-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2141-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2141-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2141-03-picture3.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2141-03-picture3.jpg",
            type: "image",
            subtype: "item_picture_3",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2141-03-picture4.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2141-03-picture4.jpg",
            type: "image",
            subtype: "item_picture_4",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2141-03-picture5.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2141-03-picture5.jpg",
            type: "image",
            subtype: "item_picture_5",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2141-03-picture6.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2141-03-picture6.jpg",
            type: "image",
            subtype: "item_picture_6",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2141-03-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2141-03-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 17752,
        price_in_aed: "4.64",
        final_price_with_margin: "16.00",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Electric-Accessories",
    master_code: "MO9654",
    master_id: "40007224",
    type_of_products: "stock",
    commodity_code: "8544 4290",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "LIGO CABLE",
    category_code: "MOBOFF_TELEXT",
    product_class: "Chargers",
    dimensions: "Ø4,4X0,7 CM",
    length: "0",
    length_unit: "cm",
    width: "4.4",
    width_unit: "cm",
    height: "0.7",
    height_unit: "cm",
    volume: "0.149",
    volume_unit: "cdm3",
    gross_weight: "0.043",
    gross_weight_unit: "kg",
    net_weight: "0.031",
    net_weight_unit: "kg",
    outer_carton_quantity: "200",
    carton_length: "0.318",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.299",
    carton_height_unit: "m",
    carton_volume: "0.029",
    carton_volume_unit: "m3",
    carton_gross_weight: "8.22",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9654-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9654-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9654-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9654-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9654-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
    ],
    short_description: "3 in 1 cable adapter",
    long_description:
      "3-in-1 charging cable with 3 different connectors, micro-USB, type-c and double sided connector. Suitable for iOS and Android devices. Ideal for full colour personalisation.",
    material: "PVC",
    printable: "yes",
    variants: [
      {
        variant_id: "10211243",
        sku: "MO9654-06",
        release_date: "2019-05-06",
        product_proposition_category: "189",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Cables",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941041646",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/8-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/8-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9654-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9654-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9654-06-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9654-06-side.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9654-06-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9654-06-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9654-06-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9654-06-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9654-06-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9654-06-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9654-06-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9654-06-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9654-06-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9654-06-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 12584,
        price_in_aed: "10.32",
        final_price_with_margin: "24.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Electric-Accessories",
    master_code: "MO9785",
    master_id: "40007491",
    type_of_products: "stock",
    commodity_code: "8504 4095",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "WIRELESS PLATO SET",
    category_code: "MOBOFF_TELMOC",
    product_class: "Chargers",
    dimensions: "9X9X4 CM",
    length: "9",
    length_unit: "cm",
    width: "9",
    width_unit: "cm",
    height: "4",
    height_unit: "cm",
    volume: "0.537",
    volume_unit: "cdm3",
    gross_weight: "0.146",
    gross_weight_unit: "kg",
    net_weight: "0.118",
    net_weight_unit: "kg",
    outer_carton_quantity: "80",
    carton_length: "0.525",
    carton_length_unit: "m",
    carton_width: "0.348",
    carton_width_unit: "m",
    carton_height: "0.233",
    carton_height_unit: "m",
    carton_volume: "0.043",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.04",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9785-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9785-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9785-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9785-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9785-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo9785-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo9785-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9785-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Wireless charger travel set",
    long_description:
      "Set with wireless charger in ABS. Presented in EVA/ PU pouch. Contains EU plug and charging cable USB A to Micro B. Output data: DC9V/1.66A (15W).",
    material: "Other",
    printable: "yes",
    variants: [
      {
        variant_id: "10213653",
        sku: "MO9785-06",
        release_date: "2020-01-01",
        product_proposition_category: "184",
        category_level1: "Technology & Accessories",
        category_level2: "Wireless Chargers",
        category_level3: "Sets",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941044944",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/9-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/9-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9785-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9785-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9785-06b.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9785-06b.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9785-06-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9785-06-side.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9785-06-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9785-06-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9785-06-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9785-06-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6946-06-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6946-06-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 4026,
        price_in_aed: "24.16",
        final_price_with_margin: "47.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Electric-Accessories",
    master_code: "MO6817",
    master_id: "40008914",
    type_of_products: "stock",
    commodity_code: "8544 4290",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "YAPO+",
    category_code: "MOBOFF_TELEXT",
    product_class: "Smartphone accessories",
    dimensions: "5,5X8,5X1,1 CM",
    length: "5.5",
    length_unit: "cm",
    width: "8.5",
    width_unit: "cm",
    height: "1.1",
    height_unit: "cm",
    volume: "0.14",
    volume_unit: "cdm3",
    gross_weight: "0.048",
    gross_weight_unit: "kg",
    net_weight: "0.044",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "250",
    carton_length: "0.54",
    carton_length_unit: "m",
    carton_width: "0.19",
    carton_width_unit: "m",
    carton_height: "0.34",
    carton_height_unit: "m",
    carton_volume: "0.035",
    carton_volume_unit: "m3",
    carton_gross_weight: "12",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6817-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6817-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6817-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6817-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo6817-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
    ],
    short_description: "3 in 1 Cable with phone stand",
    long_description:
      "Multi-functional cable and connector kit. Including data transfer and power transmission cables: Type-C to Type-C cable, Type-C to Type-A adapter. Type-C to Micro USB adapter. SIM ejector pin/eject tool. Nano and Micro SIM storage slot. Phone stand.",
    material: "ABS",
    printable: "yes",
    variants: [
      {
        variant_id: "10223702",
        sku: "MO6817-03",
        release_date: "2022-12-26",
        product_proposition_category: "189",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Cables",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941063556",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/10-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/10-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6817-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6817-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6817-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6817-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6817-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6817-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6817-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6817-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 33,
        price_in_aed: "8.88",
        final_price_with_margin: "22.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Electric-Accessories",
    master_code: "MO2146",
    master_id: "40009706",
    type_of_products: "stock",
    commodity_code: "8544 4290",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TOGOBAM",
    category_code: "MOBOFF_TELEXT",
    product_class: "Computer accessories",
    dimensions: "53X53X16 CM",
    length: "5.3",
    length_unit: "cm",
    width: "5.3",
    width_unit: "cm",
    height: "1.6",
    height_unit: "cm",
    volume: "0.045",
    volume_unit: "cdm3",
    gross_weight: "0.075",
    gross_weight_unit: "kg",
    net_weight: "0.063",
    net_weight_unit: "kg",
    inner_carton_quantity: "100",
    outer_carton_quantity: "80",
    carton_length: "0.3",
    carton_length_unit: "m",
    carton_width: "0.18",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.017",
    carton_volume_unit: "m3",
    carton_gross_weight: "6",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-13T17:00:50",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2146-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2146-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2146-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo2146-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
    ],
    short_description: "Retractable charging USB cable",
    long_description:
      "Multi-USB retractable charging cable in ABS with bamboo detail top. Input: 2-in-1 Type-C and Type-A. Output: Type-C and 2-in-1 micro-B connector. Cable length: 90 cm.",
    material: "Bamboo",
    printable: "yes",
    variants: [
      {
        variant_id: "10235870",
        sku: "MO2146-06",
        release_date: "2023-12-22",
        product_proposition_category: "189",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Cables",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941069619",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/11-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/11-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2146-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2146-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2146-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2146-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2146-06-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2146-06-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2146-06-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2146-06-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2146-06-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2146-06-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 12063,
        price_in_aed: "9.64",
        final_price_with_margin: "24.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "AudioDevices-HeadPhones",
    master_code: "MO9168",
    master_id: "40006657",
    type_of_products: "stock",
    commodity_code: "8518 3000",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "NEW ORLEANS",
    category_code: "MOBS&I_MP3",
    product_class: "Speakers",
    dimensions: "16X17X7 CM",
    length: "16",
    length_unit: "cm",
    width: "17",
    width_unit: "cm",
    height: "7",
    height_unit: "cm",
    volume: "2.322",
    volume_unit: "cdm3",
    gross_weight: "0.33",
    gross_weight_unit: "kg",
    net_weight: "0.2",
    net_weight_unit: "kg",
    outer_carton_quantity: "10",
    carton_length: "0.42",
    carton_length_unit: "m",
    carton_width: "0.3",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.023",
    carton_volume_unit: "m3",
    carton_gross_weight: "3.34",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9168-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9168-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9168-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9168-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9168-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo9168-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo9168-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo9168-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo9168-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
    ],
    short_description: "Wireless headphone",
    long_description:
      "5.0 wireless headphones in ABS. Rechargeable Li-Pol battery 300 mAh. Hands free function. Playing time approx. 6 hours. Includes jack plug cable and micro UBS charging cable. Operating range: 10m.",
    material: "ABS",
    printable: "yes",
    variants: [
      {
        variant_id: "10208434",
        sku: "MO9168-03",
        release_date: "2018-01-01",
        product_proposition_category: "168",
        category_level1: "Technology & Accessories",
        category_level2: "Audio & Sound",
        category_level3: "Headphones",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941004160",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/12-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/12-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9168-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9168-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9168-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9168-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9168-03c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9168-03c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9168-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9168-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 1631,
        price_in_aed: "30.44",
        final_price_with_margin: "64.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "AudioDevices-EarPhones",
    master_code: "MO2176",
    master_id: "40009733",
    type_of_products: "stock",
    commodity_code: "8518 3000",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SONORA",
    category_code: "MOBS&I_MP3",
    product_class: "Headphones",
    dimensions: "4,8X4,8X2,3 CM",
    length: "48",
    length_unit: "cm",
    width: "48",
    width_unit: "cm",
    height: "23",
    height_unit: "cm",
    volume: "0.17",
    volume_unit: "cdm3",
    gross_weight: "0.085",
    gross_weight_unit: "kg",
    net_weight: "0.075",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.37",
    carton_length_unit: "m",
    carton_width: "0.31",
    carton_width_unit: "m",
    carton_height: "0.15",
    carton_height_unit: "m",
    carton_volume: "0.017",
    carton_volume_unit: "m3",
    carton_gross_weight: "8.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2176-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2176-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2176-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo2176-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo2176-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo2176-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo2176-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo2176-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo2176-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2176-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
      {
        url: "https://cdn1.midocean.com/document/test-various/mo2176-test-various.pdf",
        type: "document",
        subtype: "test_various",
      },
    ],
    short_description: "TWS earbuds with solar charger",
    long_description:
      "ABS True Wireless Stereo (TWS) 5.3 wireless earbuds with microphone and a 30 mAh battery. Playing time approx. 4 hours. It has a solar charging battery. Auto-pairing. Including a Type C charging cable and a 200 mAh charging station.",
    material: "ABS",
    printable: "yes",
    variants: [
      {
        variant_id: "10236012",
        sku: "MO2176-06",
        release_date: "2023-12-22",
        product_proposition_category: "180",
        category_level1: "Technology & Accessories",
        category_level2: "Audio & Sound",
        category_level3: "Earphones/TWS",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941070295",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/13-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/13-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06-picture1.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06-picture1.jpg",
            type: "image",
            subtype: "item_picture_1",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06-picture5.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06-picture5.jpg",
            type: "image",
            subtype: "item_picture_5",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2176-06-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2176-06-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 3642,
        price_in_aed: "30.44",
        final_price_with_margin: "54.00",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "AudioDevices-EarPhones",
    master_code: "MO6780",
    master_id: "40008877",
    type_of_products: "stock",
    commodity_code: "8518 3000",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "JAZZ BAMBOO",
    category_code: "MOBS&I_MP3",
    product_class: "Headphones",
    dimensions: "5X6,5X2,5 CM",
    length: "5",
    length_unit: "cm",
    width: "6.5",
    width_unit: "cm",
    height: "2.5",
    height_unit: "cm",
    volume: "0.2",
    volume_unit: "cdm3",
    gross_weight: "0.076",
    gross_weight_unit: "kg",
    net_weight: "0.062",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.365",
    carton_length_unit: "m",
    carton_width: "0.165",
    carton_width_unit: "m",
    carton_height: "0.336",
    carton_height_unit: "m",
    carton_volume: "0.02",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.58",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6780-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6780-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6780-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6780-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo6780-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo6780-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo6780-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo6780-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo6780-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6780-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
      {
        url: "https://cdn1.midocean.com/document/test-various/mo6780-test-various.pdf",
        type: "document",
        subtype: "test_various",
      },
    ],
    short_description: "TWS earbuds in bamboo case",
    long_description:
      "Set of 2 True Wireless Stereo (TWS) 5.0 earbuds in bamboo case with microphone and 30 mAh battery built-in. Playing time approx. 3 hours. Auto-pairing. Including a micro USB charging cable and a 185 mAh charging station. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "ABS",
    printable: "yes",
    variants: [
      {
        variant_id: "10223633",
        sku: "MO6780-40",
        release_date: "2022-12-26",
        product_proposition_category: "180",
        category_level1: "Technology & Accessories",
        category_level2: "Audio & Sound",
        category_level3: "Earphones/TWS",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941063136",
        color_code: "40",
        pms_color: "BAMBOO",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/14-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/14-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6780-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6780-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6780-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6780-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6780-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6780-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6780-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6780-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6780-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6780-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6780-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6780-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 4368,
        price_in_aed: "24.72",
        final_price_with_margin: "52.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "AudioDevices-EarPhones",
    master_code: "MO6946",
    master_id: "40009159",
    type_of_products: "stock",
    commodity_code: "8518 3000",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "QUAVER",
    category_code: "MOBS&I_MP3",
    product_class: "Headphones",
    dimensions: "6,8X4,2X2,2 CM",
    length: "6.8",
    length_unit: "cm",
    width: "4.2",
    width_unit: "cm",
    height: "2.2",
    height_unit: "cm",
    volume: "0.19",
    volume_unit: "cdm3",
    gross_weight: "0.058",
    gross_weight_unit: "kg",
    net_weight: "0.054",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.345",
    carton_length_unit: "m",
    carton_width: "0.165",
    carton_width_unit: "m",
    carton_height: "0.34",
    carton_height_unit: "m",
    carton_volume: "0.019",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.8",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6946-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6946-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6946-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6946-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo6946-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo6946-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo6946-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo6946-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo6946-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6946-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
      {
        url: "https://cdn1.midocean.com/document/test-various/mo6946-test-various.pdf",
        type: "document",
        subtype: "test_various",
      },
    ],
    short_description: "Recycled ABS TWS earbuds",
    long_description:
      "Recycled ABS True Wireless Stereo (TWS) 5.3 wireless stereo earbuds with microphone and 25 mAh battery. Playing time approx. 3 hours. It has a charge level LCD display. Auto-pairing. Including a Type C charging cable and a 250 mAh charging station.",
    material: "ABS",
    printable: "yes",
    variants: [
      {
        variant_id: "10224884",
        sku: "MO6946-06",
        release_date: "2023-07-28",
        product_proposition_category: "180",
        category_level1: "Technology & Accessories",
        category_level2: "Audio & Sound",
        category_level3: "Earphones/TWS",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941066564",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/15-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/15-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6946-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6946-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6946-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6946-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6946-06-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6946-06-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6946-06-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6946-06-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6946-06-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6946-06-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6946-06-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6946-06-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6946-06-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6946-06-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6946-06-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6946-06-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 5660,
        price_in_aed: "22.72",
        final_price_with_margin: "42.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Lights",
    master_code: "MO6595",
    master_id: "40008586",
    type_of_products: "stock",
    commodity_code: "8513 1000",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "PINNY",
    category_code: "MOBOFF_TELACC",
    product_class: "Smartphone accessories",
    dimensions: "6,5 X 5X 3,5CM",
    length: "6.5",
    length_unit: "cm",
    width: "5",
    width_unit: "cm",
    height: "3.5",
    height_unit: "cm",
    volume: "0.133",
    volume_unit: "cdm3",
    gross_weight: "0.028",
    gross_weight_unit: "kg",
    net_weight: "0.018",
    net_weight_unit: "kg",
    outer_carton_quantity: "400",
    carton_length: "0.48",
    carton_length_unit: "m",
    carton_width: "0.31",
    carton_width_unit: "m",
    carton_height: "0.33",
    carton_height_unit: "m",
    carton_volume: "0.049",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.18",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6595-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6595-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6595-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6595-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo6595-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-various/mo6595-test-various.pdf",
        type: "document",
        subtype: "test_various",
      },
    ],
    short_description: "LED Clip-on LED selfie light",
    long_description:
      "3 cm portable selfie ring light in ABS material with 20 LED lights. With adjustable light to 3 different brightness levels. USB rechargeable 100 mAh battery.",
    material: "ABS",
    commercial_description:
      "3 cm portable selfie ring light in ABS material with 20 LED lights. Withadjustable light to 3 different brightness levels. USB rechargeable 100 mAhbattery. Take the perfect selfie with this clip-on LED light. Bright up yoursocial media feed with some amazing pictures. It works with any smartphonebecause it can be clipped on and removed easily.",
    printable: "yes",
    variants: [
      {
        variant_id: "10222475",
        sku: "MO6595-06",
        release_date: "2022-07-31",
        product_proposition_category: "186",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Phone stand/holders",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941058477",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/16-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/16-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6595-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6595-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6595-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6595-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6595-06-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6595-06-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6595-06-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6595-06-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6595-06-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6595-06-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6595-06-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6595-06-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6595-06-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6595-06-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6595-06-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6595-06-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 653,
        price_in_aed: "5.88",
        final_price_with_margin: "18.00",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Lights",
    master_code: "MO6242",
    master_id: "40008069",
    type_of_products: "stock",
    commodity_code: "8513 1000",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "HELIE",
    category_code: "MOBOFF_TELACC",
    product_class: "Smartphone accessories",
    dimensions: "Ø8,7X2,7CM",
    length: "0",
    length_unit: "cm",
    width: "8.7",
    width_unit: "cm",
    height: "2.7",
    height_unit: "cm",
    volume: "0.28",
    volume_unit: "cdm3",
    gross_weight: "0.062",
    gross_weight_unit: "kg",
    net_weight: "0.046",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.45",
    carton_length_unit: "m",
    carton_width: "0.33",
    carton_width_unit: "m",
    carton_height: "0.2",
    carton_height_unit: "m",
    carton_volume: "0.03",
    carton_volume_unit: "m3",
    carton_gross_weight: "6.02",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6242-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6242-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6242-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6242-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo6242-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo6242-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-various/mo6242-test-various.pdf",
        type: "document",
        subtype: "test_various",
      },
    ],
    short_description: "Portable selfie ring light",
    long_description:
      "8 cm portable selfie ring light in ABS material with 28 LED's. White light adjustable to 3 different brightness levels. USB rechargeable 80 mAh battery.",
    material: "PS",
    commercial_description:
      "Portable selfie ring light in ABS material with 28 LEDs. If you are a content creator and make a lot of Tik-tok® videos or take pictures for Instagram, then this light is a must have. The white light is adjustable in 3 different brightness levels for the perfect light exposure. Because the 28 LEDs are in a circle, you will have nice and even lighting across your face. The lamp has a USB rechargeable 80 mAh battery.",
    printable: "yes",
    variants: [
      {
        variant_id: "10215931",
        sku: "MO6242-03",
        release_date: "2021-08-01",
        product_proposition_category: "186",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Phone stand/holders",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941053212",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6242-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6242-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6242-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6242-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6242-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6242-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6242-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6242-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6242-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6242-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6242-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6242-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6242-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6242-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 58708,
        price_in_aed: "6.60",
        final_price_with_margin: "20.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Lights",
    master_code: "MO6816",
    master_id: "40008913",
    type_of_products: "stock",
    commodity_code: "9405 2140",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ESPURNA",
    category_code: "MOBOFF_BKALIT",
    product_class: "Chargers",
    dimensions: "17X5X4,1 CM",
    length: "17",
    length_unit: "cm",
    width: "5",
    width_unit: "cm",
    height: "4.1",
    height_unit: "cm",
    volume: "0.68",
    volume_unit: "cdm3",
    gross_weight: "0.196",
    gross_weight_unit: "kg",
    net_weight: "0.184",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.365",
    carton_length_unit: "m",
    carton_width: "0.25",
    carton_width_unit: "m",
    carton_height: "0.37",
    carton_height_unit: "m",
    carton_volume: "0.034",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.8",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6816-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6816-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6816-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6816-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo6816-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo6816-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo6816-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-various/mo6816-test-various.pdf",
        type: "document",
        subtype: "test_various",
      },
    ],
    short_description: "Lamp and wireless charger 10W",
    long_description:
      "ABS foldable desktop lamp with 14 LED lights which has 3 brightness settings, a 10W wireless charger. Output: DC 9V/1.1A (10W) for quick charging. Compatible with latest Androids, iPhone® 8 and newer.",
    material: "ABS",
    printable: "yes",
    variants: [
      {
        variant_id: "10223693",
        sku: "MO6816-06",
        release_date: "2022-12-26",
        product_proposition_category: "447",
        category_level1: "Technology & Accessories",
        category_level2: "Wireless Chargers",
        category_level3: "Quick chargers ≥10W",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941063549",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6816-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6816-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6816-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6816-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6816-06-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6816-06-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 5106,
        price_in_aed: "30.96",
        final_price_with_margin: "57.00",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Lights",
    master_code: "MO2124",
    master_id: "40009685",
    type_of_products: "stock",
    commodity_code: "9405 2140",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SPOT",
    category_code: "MOBS&I_SPK",
    product_class: "Chargers",
    dimensions: "Ø12X32 CM",
    length: "0",
    length_unit: "cm",
    width: "12",
    width_unit: "cm",
    height: "32",
    height_unit: "cm",
    volume: "2.75",
    volume_unit: "cdm3",
    gross_weight: "0.425",
    gross_weight_unit: "kg",
    net_weight: "0.384",
    net_weight_unit: "kg",
    outer_carton_quantity: "24",
    carton_length: "0.52",
    carton_length_unit: "m",
    carton_width: "0.388",
    carton_width_unit: "m",
    carton_height: "0.372",
    carton_height_unit: "m",
    carton_volume: "0.075",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.2",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2124-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2124-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2124-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo2124-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo2124-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo2124-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo2124-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2124-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
      {
        url: "https://cdn1.midocean.com/document/test-various/mo2124-test-various.pdf",
        type: "document",
        subtype: "test_various",
      },
    ],
    short_description: "Wireless charger, lamp speaker",
    long_description:
      "15W Wireless charging office lamp with 5.1 wireless speaker. It has adjustable light intensity with 3 types: day light, cold light and natural light. Touch activated on/off button. Output: DC5V/1A (5W). Compatible with all QI enabled devices such as latest Androids, iPhone® 8, X and newer.",
    material: "ABS",
    printable: "yes",
    variants: [
      {
        variant_id: "10235862",
        sku: "MO2124-06",
        release_date: "2023-12-22",
        product_proposition_category: "161",
        category_level1: "Technology & Accessories",
        category_level2: "Audio & Sound",
        category_level3: "Speakers",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941069169",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/gadgets-tech/17-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/gadgets-tech/17-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-picture1.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-picture1.jpg",
            type: "image",
            subtype: "item_picture_1",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-picture2.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-picture2.jpg",
            type: "image",
            subtype: "item_picture_2",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-picture3.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-picture3.jpg",
            type: "image",
            subtype: "item_picture_3",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-picture4.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-picture4.jpg",
            type: "image",
            subtype: "item_picture_4",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-picture5.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-picture5.jpg",
            type: "image",
            subtype: "item_picture_5",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2124-06-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2124-06-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 0,
        price_in_aed: "54.00",
        final_price_with_margin: "98.50",
      },
    ],
  },
  {
    main_category_name: "Gadgets-Tech",
    category_name: "Fans",
    master_code: "MO9063",
    master_id: "40006299",
    type_of_products: "stock",
    commodity_code: "8414 5100",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TATSUMAKI",
    category_code: "MOBL&G_FAN",
    product_class: "Desk items",
    dimensions: "9X1,5X14,5 CM",
    length: "9",
    length_unit: "cm",
    width: "1.5",
    width_unit: "cm",
    height: "14.5",
    height_unit: "cm",
    volume: "0.042",
    volume_unit: "cdm3",
    gross_weight: "0.03",
    gross_weight_unit: "kg",
    net_weight: "0.026",
    net_weight_unit: "kg",
    inner_carton_quantity: "250",
    outer_carton_quantity: "500",
    carton_length: "0.475",
    carton_length_unit: "m",
    carton_width: "0.215",
    carton_width_unit: "m",
    carton_height: "0.205",
    carton_height_unit: "m",
    carton_volume: "0.021",
    carton_volume_unit: "m3",
    carton_gross_weight: "14.78",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9063-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9063-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9063-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo9063-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo9063-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
    ],
    short_description: "Portable USB fan",
    long_description: "Portable USB fan in PVC.",
    material: "PVC",
    printable: "yes",
    variants: [
      {
        variant_id: "10206028",
        sku: "MO9063-06",
        release_date: "2017-01-01",
        product_proposition_category: "308",
        category_level1: "Office & Writing",
        category_level2: "Office accessories ",
        category_level3: "Desk lights & Accessories",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941003064",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9063-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9063-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9063-06a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9063-06a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
        ],
        available_quantity: 27148,
        price_in_aed: "3.44",
        final_price_with_margin: "43.00",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "A5-Notebook",
    master_code: "MO2093",
    master_id: "40009127",
    type_of_products: "stock",
    commodity_code: "4820 1030",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "FELTBOOK",
    category_code: "MOBOFF_NOTOTH",
    product_class: "Notebooks & notepads",
    dimensions: "15X22X1.6CM",
    length: "15",
    length_unit: "cm",
    width: "22",
    width_unit: "cm",
    height: "1.6",
    height_unit: "cm",
    volume: "0.66",
    volume_unit: "cdm3",
    gross_weight: "0.25",
    gross_weight_unit: "kg",
    net_weight: "0.23",
    net_weight_unit: "kg",
    outer_carton_quantity: "40",
    carton_length: "0.47",
    carton_length_unit: "m",
    carton_width: "0.24",
    carton_width_unit: "m",
    carton_height: "0.24",
    carton_height_unit: "m",
    carton_volume: "0.027",
    carton_volume_unit: "m3",
    carton_gross_weight: "10",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2093-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2093-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2093-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "A5 notebook RPET felt",
    long_description:
      "A5 notebook with RPET felt cover. 160 recycled paper lined pages (80 sheets / 70 gr/m²). Elasticated pen loop.",
    material: "Paper",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10226231",
        sku: "MO2093-15",
        release_date: "2023-07-28",
        product_proposition_category: "294",
        category_level1: "Office & Writing",
        category_level2: "Notebooks",
        category_level3: "Soft cover",
        color_description: "Stone Grey",
        color_group: "Grey",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941068421",
        color_code: "15",
        pms_color: "423U",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/1-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/1-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2093-15.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2093-15.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2093-15-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2093-15-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2093-15-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2093-15-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2093-15-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2093-15-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2093-15-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2093-15-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2093-15-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2093-15-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 9789,
        price_in_aed: "11.76",
        final_price_with_margin: "34.00",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "A5-Notebook",
    master_code: "MO6964",
    master_id: "40009177",
    type_of_products: "stock",
    commodity_code: "4820 1030",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "RINGFELT",
    category_code: "MOBOFF_NOTREC",
    product_class: "Notebooks & notepads",
    dimensions: "16X21X1CM",
    length: "16",
    length_unit: "cm",
    width: "21",
    width_unit: "cm",
    height: "1",
    height_unit: "cm",
    volume: "0.74",
    volume_unit: "cdm3",
    gross_weight: "0.25",
    gross_weight_unit: "kg",
    net_weight: "0.24",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.365",
    carton_length_unit: "m",
    carton_width: "0.22",
    carton_width_unit: "m",
    carton_height: "0.317",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6964-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6964-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6964-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "A5 RPET felt cover notebook",
    long_description:
      "A5 RPET felt cover notebook. Twin-wire bound, 160 recycled lined pages in 70 gr/m² (80 sheets) with elastic pen holder.",
    material: "Paper",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10225467",
        sku: "MO6964-15",
        release_date: "2023-07-28",
        product_proposition_category: "294",
        category_level1: "Office & Writing",
        category_level2: "Notebooks",
        category_level3: "Soft cover",
        color_description: "Stone Grey",
        color_group: "Grey",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941066786",
        color_code: "15",
        pms_color: "423U",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/2-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/2-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6964-15.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6964-15.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6964-15-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6964-15-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6964-15-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6964-15-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6964-15-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6964-15-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6964-15-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6964-15-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 4008,
        price_in_aed: "11.00",
        final_price_with_margin: "33.00",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Sticky-Note",
    master_code: "MO6912",
    master_id: "40008994",
    type_of_products: "stock",
    commodity_code: "4820 1030",
    number_of_print_positions: "2",
    country_of_origin: "PL",
    brand: "midocean",
    product_name: "MAUI",
    category_code: "MOBOFF_DESMEM",
    product_class: "Notebooks & notepads",
    dimensions: "10X7X0,9 CM",
    length: "10",
    length_unit: "cm",
    width: "7",
    width_unit: "cm",
    height: "0.9",
    height_unit: "cm",
    volume: "0.095",
    volume_unit: "cdm3",
    gross_weight: "0.053",
    gross_weight_unit: "kg",
    net_weight: "0.051",
    net_weight_unit: "kg",
    outer_carton_quantity: "136",
    carton_length: "0.32",
    carton_length_unit: "m",
    carton_width: "0.225",
    carton_width_unit: "m",
    carton_height: "0.2",
    carton_height_unit: "m",
    carton_volume: "0.014",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.26",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6912-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6912-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6912-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Recycled paper memo set",
    long_description:
      "2-piece sticky note memo pad (50 sheets)with recycled paper cover. Sticky notes pads (25 sheets) and 3 assorted colours page markers. Made in EU.",
    material: "Recycled Paper",
    green: "yes",
    themes: "Promotion7",
    printable: "yes",
    variants: [
      {
        variant_id: "10224514",
        sku: "MO6912-13",
        release_date: "2022-12-26",
        product_proposition_category: "302",
        category_level1: "Office & Writing",
        category_level2: "Office accessories ",
        category_level3: "Memo pads/sticky notes",
        color_description: "Beige",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941065864",
        color_code: "13",
        pms_color: "466U",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/3-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/3-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6912-13.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6912-13.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6912-13-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6912-13-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6912-13-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6912-13-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6912-13-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6912-13-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6912-13-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6912-13-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 5913,
        price_in_aed: "5.00",
        final_price_with_margin: "17.50",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Ball-Pen",
    master_code: "MO6991",
    master_id: "40009204",
    type_of_products: "stock",
    commodity_code: "9608 1092",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SIDE",
    category_code: "MOBWRI_PENREC",
    product_class: "Pen",
    dimensions: "Ø1X14 CM",
    length: "14",
    length_unit: "cm",
    width: "1",
    width_unit: "cm",
    height: "14",
    height_unit: "cm",
    volume: "0.025",
    volume_unit: "cdm3",
    gross_weight: "0.009",
    gross_weight_unit: "kg",
    net_weight: "0.008",
    net_weight_unit: "kg",
    inner_carton_quantity: "100",
    outer_carton_quantity: "1000",
    carton_length: "0.58",
    carton_length_unit: "m",
    carton_width: "0.17",
    carton_width_unit: "m",
    carton_height: "0.25",
    carton_height_unit: "m",
    carton_volume: "0.025",
    carton_volume_unit: "m3",
    carton_gross_weight: "9",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6991-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6991-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6991-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Recycled ABS push button pen",
    long_description: "Push button ball pen in recycled ABS. Blue ink.",
    material: "Recycled ABS",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10225524",
        sku: "MO6991-03",
        release_date: "2023-07-28",
        product_proposition_category: "311",
        category_level1: "Office & Writing",
        category_level2: "Writing",
        category_level3: "Pens",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941067318",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/4-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/4-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6991-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6991-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6991-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6991-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6991-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6991-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6991-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6991-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6991-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6991-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 31808,
        price_in_aed: "0.72",
        final_price_with_margin: "11.00",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Ball-Pen",
    master_code: "MO6794",
    master_id: "40008891",
    type_of_products: "stock",
    commodity_code: "9608 1092",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "GRAZ",
    category_code: "MOBWRI_PENPBO",
    product_class: "Pen",
    dimensions: "17,5X5,5X2,5 CM",
    length: "17.5",
    length_unit: "cm",
    width: "5.5",
    width_unit: "cm",
    height: "2.5",
    height_unit: "cm",
    volume: "0.38",
    volume_unit: "cdm3",
    gross_weight: "0.08",
    gross_weight_unit: "kg",
    net_weight: "0.06",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "100",
    carton_length: "0.39",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.305",
    carton_height_unit: "m",
    carton_volume: "0.038",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.88",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6794-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6794-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6794-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Recycled stainless steel pen",
    long_description:
      "Push button ball pen with recycled stainless steel barrel. Presented in matching cork box. Blue ink. Cork is 100% natural material. Due to its structural nature and surface porosity the final print result per item may have deviations.",
    material: "Paper",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223629",
        sku: "MO6794-16",
        release_date: "2022-12-26",
        product_proposition_category: "315",
        category_level1: "Office & Writing",
        category_level2: "Writing",
        category_level3: "Sets",
        color_description: "Matt Silver",
        color_group: "Silver",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941063143",
        color_code: "16",
        pms_color: "SILVER",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/5-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/5-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6794-16.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6794-16.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6794-16-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6794-16-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6794-16-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6794-16-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
        ],
        available_quantity: 12964,
        price_in_aed: "8.28",
        final_price_with_margin: "23.00",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Cutting-Board",
    master_code: "MO6966",
    master_id: "40009179",
    type_of_products: "stock",
    commodity_code: "4419 2090",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SERVIRO",
    category_code: "MOBH&L_KAC",
    product_class: "Kitchen accessories",
    dimensions: "19.5X15X0.9CM",
    length: "19.5",
    length_unit: "cm",
    width: "15",
    width_unit: "cm",
    height: "0.9",
    height_unit: "cm",
    volume: "0.36",
    volume_unit: "cdm3",
    gross_weight: "0.176",
    gross_weight_unit: "kg",
    net_weight: "0.156",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.515",
    carton_length_unit: "m",
    carton_width: "0.165",
    carton_width_unit: "m",
    carton_height: "0.21",
    carton_height_unit: "m",
    carton_volume: "0.018",
    carton_volume_unit: "m3",
    carton_gross_weight: "8.8",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6966-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6966-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6966-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Acacia wood cutting board",
    long_description:
      "Acacia wood cutting board with PU cord. Wood is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Wood",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10225471",
        sku: "MO6966-40",
        release_date: "2023-07-28",
        product_proposition_category: "280",
        category_level1: "Eating & Drinking",
        category_level2: "Kitchenware",
        category_level3: "Cutting boards",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941066793",
        color_code: "40",
        pms_color: "NATURE COLOR",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/6-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/6-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6966-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6966-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6966-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6966-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6966-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6966-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6966-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6966-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6966-40-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6966-40-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 0,
        price_in_aed: "8.56",
        final_price_with_margin: "27.00",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Apron",
    master_code: "MO2265",
    master_id: "40009817",
    type_of_products: "stock",
    commodity_code: "6211 4210",
    number_of_print_positions: "5",
    country_of_origin: "IN",
    brand: "midocean",
    product_name: "CUINA",
    category_code: "MOBH&L_KACA&M",
    product_class: "Kitchen accessories",
    dimensions: "81X90CM",
    length: "81",
    length_unit: "cm",
    width: "90",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.54",
    volume_unit: "cdm3",
    gross_weight: "0.12",
    gross_weight_unit: "kg",
    net_weight: "0.11",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.45",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.058",
    carton_volume_unit: "m3",
    carton_gross_weight: "12",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2265-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2265-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2265-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Recycled cotton Kitchen apron",
    long_description:
      "Adjustable kitchen apron with 1 front pocket in 220 gr/m² recycled cotton. 80% recycled cotton, 20% regular cotton.",
    material: "Recycled Cotton",
    printable: "yes",
    variants: [
      {
        variant_id: "10237151",
        sku: "MO2265-03",
        release_date: "2023-12-22",
        product_proposition_category: "278",
        category_level1: "Eating & Drinking",
        category_level2: "Kitchenware",
        category_level3: "Aprons",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941072251",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/7-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/7-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2265-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2265-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2265-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2265-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2265-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2265-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
        ],
        available_quantity: 17821,
        price_in_aed: "12.92",
        final_price_with_margin: "30.50",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Shopping-Bag",
    master_code: "MO6380",
    master_id: "40008278",
    type_of_products: "stock",
    commodity_code: "4202 9298",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "RESPECT COLOURED",
    category_code: "MOBT&B_SHPOCO",
    product_class: "Shopping & Beach bag",
    dimensions: "49X9X37CM",
    length: "49",
    length_unit: "cm",
    width: "9",
    width_unit: "cm",
    height: "37",
    height_unit: "cm",
    volume: "1.16",
    volume_unit: "cdm3",
    gross_weight: "0.21",
    gross_weight_unit: "kg",
    net_weight: "0.18",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.6",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.24",
    carton_height_unit: "m",
    carton_volume: "0.058",
    carton_volume_unit: "m3",
    carton_gross_weight: "10",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6380-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6380-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6380-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Canvas Recycled bag 280 gr/m²",
    long_description:
      "Recycled canvas shopping bag or beach bag, 50% cotton and 50% recycled cotton with long handles and gusset. 280 gr/m².",
    material: "Recycled Cotton",
    commercial_description:
      "280 gr/m² recycled canvas shopping bag or beach bag available in multiple colours. This tote bag has long handles so it can be carried by hand or over the shoulder to keep your hands free. The gusset base makes it very useful to bring a lot of items with you to the beach or when grocery shopping. The bag is made from 50% cotton and 50% recycled cotton.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10221840",
        sku: "MO6380-03",
        release_date: "2021-12-24",
        product_proposition_category: "193",
        category_level1: "Bags & Travel",
        category_level2: "Shopping bags",
        category_level3: "Tote bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941055209",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/8-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/8-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6380-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6380-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6380-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6380-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6380-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6380-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
        ],
        available_quantity: 331,
        price_in_aed: "16.52",
        final_price_with_margin: "38.50",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Shopping-Bag",
    master_code: "MO9441",
    master_id: "40006966",
    type_of_products: "stock",
    commodity_code: "4202 9298",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TOTEPET",
    category_code: "MOBT&B_SHPPET",
    product_class: "Shopping & Beach bag",
    dimensions: "38X42 CM",
    length: "38",
    length_unit: "cm",
    width: "38",
    width_unit: "cm",
    height: "42",
    height_unit: "cm",
    volume: "0.143",
    volume_unit: "cdm3",
    gross_weight: "0.026",
    gross_weight_unit: "kg",
    net_weight: "0.025",
    net_weight_unit: "kg",
    outer_carton_quantity: "300",
    carton_length: "0.46",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.2",
    carton_height_unit: "m",
    carton_volume: "0.037",
    carton_volume_unit: "m3",
    carton_gross_weight: "8.32",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9441-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9441-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9441-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Shopping bag in RPET",
    long_description:
      "Shopping bag in 190T RPET with long handles. Eco-friendly material made from recycled plastic bottles.",
    material: "RPET",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10210061",
        sku: "MO9441-03",
        release_date: "2018-08-01",
        product_proposition_category: "193",
        category_level1: "Bags & Travel",
        category_level2: "Shopping bags",
        category_level3: "Tote bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941001947",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/9-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/9-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9441-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9441-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9441-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9441-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9441-03b.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9441-03b.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
        available_quantity: 49368,
        price_in_aed: "3.00",
        final_price_with_margin: "14.00",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Lunch-Bag",
    master_code: "MO9915",
    master_id: "40007928",
    type_of_products: "stock",
    commodity_code: "4202 9298",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ICECUBE",
    category_code: "MOBL&G_BEACOO",
    product_class: "Shopping & Beach bag",
    dimensions: "30X20X25 CM",
    length: "30",
    length_unit: "cm",
    width: "20",
    width_unit: "cm",
    height: "25",
    height_unit: "cm",
    volume: "3.195",
    volume_unit: "cdm3",
    gross_weight: "0.342",
    gross_weight_unit: "kg",
    net_weight: "0.304",
    net_weight_unit: "kg",
    outer_carton_quantity: "25",
    carton_length: "0.485",
    carton_length_unit: "m",
    carton_width: "0.35",
    carton_width_unit: "m",
    carton_height: "0.465",
    carton_height_unit: "m",
    carton_volume: "0.079",
    carton_volume_unit: "m3",
    carton_gross_weight: "8.56",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9915-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9915-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9915-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "RPET cooler bag",
    long_description:
      "2 tone 600D RPET cooler bag with 2 compartments and PEVA lining. Adjustable shoulder strap and front pocket. Isolation material:  0.15mm PEVA + 3mm foam.",
    material: "RPET",
    commercial_description:
      "2 tone 600D RPET cooler bag with 2 compartments and PEVA lining. Great for a dayat the beach, a picnic or a vacation. Because it has two separate compartments,you can keep different items cool and have easy access to both. Place a fewdrinks in the bottom and some food in the top and you are good to go. It has afront pocket for you to store smaller items. The adjustable shoulder strap makesthis cooler bag easy to carry, even when completely full. Insulation material:0.15mm PEVA + 3mm foam.",
    green: "yes",
    top_seller: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10215521",
        sku: "MO9915-03",
        release_date: "2020-12-27",
        product_proposition_category: "175",
        category_level1: "Bags & Travel",
        category_level2: "Sport & Outdoor bags",
        category_level3: "Cooler bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941051881",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/10-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/10-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9915-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9915-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9915-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9915-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9915-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9915-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9915-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9915-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9915-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9915-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9915-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9915-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9915-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9915-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 2416,
        price_in_aed: "23.52",
        final_price_with_margin: "56.00",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Drawstring-Bag",
    master_code: "MO6550",
    master_id: "40008444",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "PANDA BAG",
    category_code: "MOBT&B_DUFCOT",
    product_class: "Shopping & Beach bag",
    dimensions: "38X42CM",
    length: "38",
    length_unit: "cm",
    width: "42",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.61",
    volume_unit: "cdm3",
    gross_weight: "0.145",
    gross_weight_unit: "kg",
    net_weight: "0.135",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.45",
    carton_length_unit: "m",
    carton_width: "0.45",
    carton_width_unit: "m",
    carton_height: "0.3",
    carton_height_unit: "m",
    carton_volume: "0.061",
    carton_volume_unit: "m3",
    carton_gross_weight: "14.28",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6550-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6550-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6550-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Recycled cotton drawstring bag",
    long_description:
      "Recycled cotton drawstring bag with natural cords 50% cotton and 50% recycled cotton. 220 gr/m².",
    material: "Recycled Cotton",
    commercial_description:
      "Drawstring bag made from recycled cotton with natural cords.220 gr/m². Drawstring bags are great for daily usage to bring your items to work, school or the gym. This bag has contrasting corners for a stylish design element. 50% cotton and 50% recycled cotton. Carry weight limit 2.5kgs.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10222337",
        sku: "MO6550-03",
        release_date: "2021-12-24",
        product_proposition_category: "197",
        category_level1: "Bags & Travel",
        category_level2: "Sport & Outdoor bags",
        category_level3: "Drawstring bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941057593",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/eco-friendly/11-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/eco-friendly/11-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6550-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6550-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6550-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6550-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6550-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6550-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6550-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6550-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6550-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6550-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 14121,
        price_in_aed: "10.64",
        final_price_with_margin: "29.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Slippers",
    master_code: "MO9782",
    master_id: "40007488",
    type_of_products: "stock",
    commodity_code: "6404 1910",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "FLIP FLAP",
    category_code: "MOBT&B_TAC",
    product_class: "Wellness & Care accessories",
    dimensions: "28,5X11 CM (EUR SIZE 43 - 44)",
    length: "28.5",
    length_unit: "cm",
    width: "11",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.824",
    volume_unit: "cdm3",
    gross_weight: "0.078",
    gross_weight_unit: "kg",
    net_weight: "0.048",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.46",
    carton_length_unit: "m",
    carton_width: "0.31",
    carton_width_unit: "m",
    carton_height: "0.55",
    carton_height_unit: "m",
    carton_volume: "0.078",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.76",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9782-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9782-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9782-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Pair of slippers in pouch",
    long_description:
      "Pair of polyester hotel slippers presented in a non woven pouch. EUR size 43 - 44.",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10213679",
        sku: "MO9782-03",
        release_date: "2020-01-01",
        product_proposition_category: "345",
        category_level1: "Wellness & Care",
        category_level2: "Personal care",
        category_level3: "Bath accessories",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941044876",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/1-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/1-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9782-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9782-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9782-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9782-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
        ],
        available_quantity: 6722,
        price_in_aed: "5.00",
        final_price_with_margin: "22.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Towel",
    master_code: "MO9933",
    master_id: "40007652",
    type_of_products: "stock",
    commodity_code: "6302 6000",
    number_of_print_positions: "5",
    country_of_origin: "PK",
    brand: "midocean",
    product_name: "MERRY",
    category_code: "MOBL&G_BEATOW",
    product_class: "Wellness & Care accessories",
    dimensions: "180X100 CM",
    length: "180",
    length_unit: "cm",
    width: "100",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "5.579",
    volume_unit: "cdm3",
    gross_weight: "0.746",
    gross_weight_unit: "kg",
    net_weight: "0.68",
    net_weight_unit: "kg",
    outer_carton_quantity: "12",
    carton_length: "0.455",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.385",
    carton_height_unit: "m",
    carton_volume: "0.07",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.62",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9933-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9933-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
    ],
    short_description: "Towel organic cotton 180x100cm",
    long_description:
      "Terry towel made of 100%  360 gsm organic cotton. 180x100 cm. Terry material is soft and absorbent.",
    material: "Organic Cotton",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10214978",
        sku: "MO9933-03",
        release_date: "2020-08-01",
        product_proposition_category: "345",
        category_level1: "Wellness & Care",
        category_level2: "Personal care",
        category_level3: "Bath accessories",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941049253",
        color_code: "03",
        pms_color: "19-0303 TPX",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/2-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/2-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9933-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9933-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9933-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9933-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9933-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9933-03-side.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9933-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9933-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9933-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9933-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 3369,
        price_in_aed: "61.24",
        final_price_with_margin: "118.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Sewing-Kit",
    master_code: "IT3552",
    master_id: "40000656",
    type_of_products: "stock",
    commodity_code: "9605 0000",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SASTRE",
    category_code: "MOBPER_SEW",
    product_class: "Travel accessories",
    dimensions: "7X6.5X1.5 CM",
    length: "7",
    length_unit: "cm",
    width: "6.5",
    width_unit: "cm",
    height: "1.5",
    height_unit: "cm",
    volume: "0.135",
    volume_unit: "cdm3",
    gross_weight: "0.048",
    gross_weight_unit: "kg",
    net_weight: "0.043",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "200",
    carton_length: "0.36",
    carton_length_unit: "m",
    carton_width: "0.205",
    carton_width_unit: "m",
    carton_height: "0.36",
    carton_height_unit: "m",
    carton_volume: "0.027",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.65",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/it3552-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/it3552-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/it3552-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Compact sewing kit",
    long_description:
      "Compact sewing kit with essential sewing accessories in a plastic box.",
    material: "PS",
    printable: "yes",
    variants: [
      {
        variant_id: "10157619",
        sku: "IT3552-06",
        release_date: "2015-01-01",
        product_proposition_category: "210",
        category_level1: "Bags & Travel",
        category_level2: "Travel accessories",
        category_level3: "Travel accessories",
        color_description: "White",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941008113",
        color_code: "06",
        pms_color: "WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/3-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/3-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it3552-06.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it3552-06.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it3552-06-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it3552-06-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/it3552-06c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/it3552-06c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
        available_quantity: 21331,
        price_in_aed: "3.92",
        final_price_with_margin: "16.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Tool-Kit",
    master_code: "MO6756",
    master_id: "40008853",
    type_of_products: "stock",
    commodity_code: "8206 0000",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "BAMTOOL",
    category_code: "MOBTLL_BRITOO",
    product_class: "Tools",
    dimensions: "14,8X8X2,4CM",
    length: "14.8",
    length_unit: "cm",
    width: "8",
    width_unit: "cm",
    height: "2.4",
    height_unit: "cm",
    volume: "0.4",
    volume_unit: "cdm3",
    gross_weight: "0.24",
    gross_weight_unit: "kg",
    net_weight: "0.224",
    net_weight_unit: "kg",
    outer_carton_quantity: "50",
    carton_length: "0.47",
    carton_length_unit: "m",
    carton_width: "0.325",
    carton_width_unit: "m",
    carton_height: "0.145",
    carton_height_unit: "m",
    carton_volume: "0.022",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.36",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6756-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6756-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6756-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6756-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "24 piece tool set",
    long_description:
      "24-piece small precision screwdriver repair tool kit in a bamboo case with buckle closure. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223342",
        sku: "MO6756-40",
        release_date: "2022-12-26",
        product_proposition_category: "329",
        category_level1: "Premiums & Tools",
        category_level2: "Tools & Torches",
        category_level3: "Sets",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941062283",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6756-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6756-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6756-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6756-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6756-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6756-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6756-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6756-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6756-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6756-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 6785,
        price_in_aed: "18.28",
        final_price_with_margin: "41.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Tool-Kit",
    master_code: "MO8914",
    master_id: "40006151",
    type_of_products: "stock",
    commodity_code: "8211 9300",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ALOQUIN",
    category_code: "MOBTLL_BRITOO",
    product_class: "Tools",
    dimensions: "11X5X2CM",
    length: "11",
    length_unit: "cm",
    width: "11",
    width_unit: "cm",
    height: "5",
    height_unit: "cm",
    volume: "0.228",
    volume_unit: "cdm3",
    gross_weight: "0.218",
    gross_weight_unit: "kg",
    net_weight: "0.208",
    net_weight_unit: "kg",
    inner_carton_quantity: "25",
    outer_carton_quantity: "50",
    carton_length: "0.28",
    carton_length_unit: "m",
    carton_width: "0.16",
    carton_width_unit: "m",
    carton_height: "0.255",
    carton_height_unit: "m",
    carton_volume: "0.011",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.84",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8914-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo8914-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo8914-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Foldable multi-tool knife",
    long_description:
      "Foldable multi-tool knife in stainless steel. Includes 9 tool functions in ABS including 600D polyester pouch.",
    material: "Stainless Steel",
    printable: "yes",
    variants: [
      {
        variant_id: "10205687",
        sku: "MO8914-03",
        release_date: "2017-01-01",
        product_proposition_category: "327",
        category_level1: "Premiums & Tools",
        category_level2: "Tools & Torches",
        category_level3: "Multitool knives",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941027572",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8914-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8914-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8914-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8914-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8914-03c.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8914-03c.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8914-03d.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8914-03d.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8914-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8914-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8914-03-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8914-03-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
        ],
        available_quantity: 5811,
        price_in_aed: "22.20",
        final_price_with_margin: "46.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Doormat",
    master_code: "MO2064",
    master_id: "40009097",
    type_of_products: "stock",
    commodity_code: "5702 9900",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ZAPZAP",
    category_code: "MOBH&L_KACPLM",
    product_class: "Tools",
    dimensions: "58 X 38CM",
    length: "58",
    length_unit: "cm",
    width: "38",
    width_unit: "cm",
    height: "38",
    height_unit: "cm",
    volume: "1.067",
    volume_unit: "cdm3",
    gross_weight: "0.5",
    gross_weight_unit: "kg",
    net_weight: "0.467",
    net_weight_unit: "kg",
    outer_carton_quantity: "30",
    carton_length: "0.6",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.135",
    carton_height_unit: "m",
    carton_volume: "0.032",
    carton_volume_unit: "m3",
    carton_gross_weight: "15",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2064-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2064-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2064-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Flax door mat",
    long_description:
      "Flax doormat with anti slip finish on the reverse side. Size: 58x38 cm.",
    material: "Jute",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10225859",
        sku: "MO2064-13",
        release_date: "2023-07-28",
        product_proposition_category: "348",
        category_level1: "Wellness & Care",
        category_level2: "Home & Living",
        category_level3: "Blankets",
        color_description: "Beige",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941067813",
        color_code: "13",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/4-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/4-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2064-13.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2064-13.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2064-13-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2064-13-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2064-13-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2064-13-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 2973,
        price_in_aed: "23.88",
        final_price_with_margin: "53.50",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Umbrella",
    master_code: "MO6968",
    master_id: "40009181",
    type_of_products: "stock",
    commodity_code: "6601 9100",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "MINIBRELLA",
    category_code: "MOBT&B_UMBUFL",
    product_class: "Umbrella",
    dimensions: "Ø50X90CM",
    length: "0",
    length_unit: "cm",
    width: "50",
    width_unit: "cm",
    height: "90",
    height_unit: "cm",
    volume: "0.37",
    volume_unit: "cdm3",
    gross_weight: "0.115",
    gross_weight_unit: "kg",
    net_weight: "0.11",
    net_weight_unit: "kg",
    inner_carton_quantity: "20",
    outer_carton_quantity: "100",
    carton_length: "0.24",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.425",
    carton_height_unit: "m",
    carton_volume: "0.041",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6968-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6968-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6968-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Light folding umbrella 100gr",
    long_description:
      "Mini 3 fold ultra light (100gr.) 19 inch manual open windproof umbrella in 30D pongee polyester with black carbon fibre shaft. Black carbon fibre ribs and plastic tips. Manual closure. Presented in pouch.",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10225465",
        sku: "MO6968-03",
        release_date: "2023-07-28",
        product_proposition_category: "222",
        category_level1: "Umbrellas & Rain garments",
        category_level2: "Umbrellas",
        category_level3: "Foldable",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941066823",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/5-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/5-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6968-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6968-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6968-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6968-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6968-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6968-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6968-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6968-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6968-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6968-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6968-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6968-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 0,
        price_in_aed: "31.56",
        final_price_with_margin: "55.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Umbrella",
    master_code: "MO2166",
    master_id: "40009724",
    type_of_products: "stock",
    commodity_code: "6601 9920",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "UGUA",
    category_code: "MOBT&B_UMBREG",
    product_class: "Umbrella",
    dimensions: "Ø132X96.3CM",
    length: "0",
    length_unit: "cm",
    width: "132",
    width_unit: "cm",
    height: "96.3",
    height_unit: "cm",
    volume: "2.2",
    volume_unit: "cdm3",
    gross_weight: "0.65",
    gross_weight_unit: "kg",
    net_weight: "0.64",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.98",
    carton_length_unit: "m",
    carton_width: "0.21",
    carton_width_unit: "m",
    carton_height: "0.17",
    carton_height_unit: "m",
    carton_volume: "0.035",
    carton_volume_unit: "m3",
    carton_gross_weight: "13",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2166-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2166-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2166-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "30 inch 4 panel umbrella",
    long_description:
      "30 inch manual open windproof umbrella in 190T pongee (4 panels) and reflective material (4 panels) with fiberglass frame, ribs and shaft. Straight ABS handle with rubber finish. Manual closure.",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10235984",
        sku: "MO2166-03",
        release_date: "2023-12-22",
        product_proposition_category: "221",
        category_level1: "Umbrellas & Rain garments",
        category_level2: "Umbrellas",
        category_level3: "Unfolded",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941070158",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/6-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/6-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2166-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2166-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2166-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2166-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2166-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2166-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2166-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2166-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2166-03-picture6.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2166-03-picture6.jpg",
            type: "image",
            subtype: "item_picture_6",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2166-03-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2166-03-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 4786,
        price_in_aed: "42.52",
        final_price_with_margin: "82.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Keychains",
    master_code: "MO2186",
    master_id: "40009743",
    type_of_products: "stock",
    commodity_code: "7326 9098",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "STANRIN",
    category_code: "MOBP&S_KERKRM",
    product_class: "Key ring",
    dimensions: "5,5X2,95X0,8CM",
    length: "5.5",
    length_unit: "cm",
    width: "2.95",
    width_unit: "cm",
    height: "0.8",
    height_unit: "cm",
    volume: "0.06",
    volume_unit: "cdm3",
    gross_weight: "0.035",
    gross_weight_unit: "kg",
    net_weight: "0.032",
    net_weight_unit: "kg",
    outer_carton_quantity: "300",
    carton_length: "0.4",
    carton_length_unit: "m",
    carton_width: "0.25",
    carton_width_unit: "m",
    carton_height: "0.18",
    carton_height_unit: "m",
    carton_volume: "0.018",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-06T07:33:30",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2186-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2186-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo2186-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Metal key ring phone stand",
    long_description: "Metal key ring with phone stand.",
    material: "Metal",
    printable: "yes",
    variants: [
      {
        variant_id: "10236066",
        sku: "MO2186-14",
        release_date: "2023-12-22",
        product_proposition_category: "186",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Phone stand/holders",
        color_description: "Silver",
        color_group: "Silver",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941070486",
        color_code: "14",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/7-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/7-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2186-14.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2186-14.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2186-14-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2186-14-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2186-14-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2186-14-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2186-14-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2186-14-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2186-14-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2186-14-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2186-14-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2186-14-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2186-14-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2186-14-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2186-14-picture5.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2186-14-picture5.jpg",
            type: "image",
            subtype: "item_picture_5",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2186-14-picture6.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2186-14-picture6.jpg",
            type: "image",
            subtype: "item_picture_6",
          },
        ],
        available_quantity: 23162,
        price_in_aed: "3.24",
        final_price_with_margin: "14.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Keychains",
    master_code: "MO9743",
    master_id: "40007375",
    type_of_products: "stock",
    commodity_code: "4420 9099",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TRINEU",
    category_code: "MOBP&S_KERMUL",
    product_class: "Key ring",
    dimensions: "8X3X1.5CM",
    length: "8",
    length_unit: "cm",
    width: "3",
    width_unit: "cm",
    height: "1.5",
    height_unit: "cm",
    volume: "0.075",
    volume_unit: "cdm3",
    gross_weight: "0.031",
    gross_weight_unit: "kg",
    net_weight: "0.026",
    net_weight_unit: "kg",
    outer_carton_quantity: "400",
    carton_length: "0.46",
    carton_length_unit: "m",
    carton_width: "0.195",
    carton_width_unit: "m",
    carton_height: "0.335",
    carton_height_unit: "m",
    carton_volume: "0.03",
    carton_volume_unit: "m3",
    carton_gross_weight: "13.84",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9743-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9743-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9743-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Key ring and Smartphone",
    long_description:
      "Key ring with Smartphone stand in bamboo. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10212990",
        sku: "MO9743-40",
        release_date: "2019-08-01",
        product_proposition_category: "321",
        category_level1: "Premiums & Tools",
        category_level2: "Key rings",
        category_level3: "Basic",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941043619",
        color_code: "40",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/8-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/8-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9743-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9743-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9743-40-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9743-40-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9743-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9743-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9743-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9743-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 11400,
        price_in_aed: "1.96",
        final_price_with_margin: "12.50",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Keychains",
    master_code: "MO6596",
    master_id: "40008587",
    type_of_products: "stock",
    commodity_code: "4420 9099",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "GANKEY",
    category_code: "MOBP&S_KERMUL",
    product_class: "Key ring",
    dimensions: "7,5X2,5X0,3CM",
    length: "7.5",
    length_unit: "cm",
    width: "2.5",
    width_unit: "cm",
    height: "0.3",
    height_unit: "cm",
    volume: "0.026",
    volume_unit: "cdm3",
    gross_weight: "0.007",
    gross_weight_unit: "kg",
    net_weight: "0.005",
    net_weight_unit: "kg",
    outer_carton_quantity: "1000",
    carton_length: "0.38",
    carton_length_unit: "m",
    carton_width: "0.23",
    carton_width_unit: "m",
    carton_height: "0.3",
    carton_height_unit: "m",
    carton_volume: "0.026",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.34",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6596-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6596-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6596-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Bamboo stand and key ring",
    long_description:
      "Bamboo key ring with phone stand functionality. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    commercial_description:
      "Bamboo key ring with phone stand functionality. A stylish bamboo keyring thatalso doubles as a phone stand. This way you can always carry a compact phonestand with you. Bamboo is a natural product, there may be slight variations incolour and size per item, which can affect the final decoration outcome.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10222477",
        sku: "MO6596-40",
        release_date: "2022-07-31",
        product_proposition_category: "186",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Phone stand/holders",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941058484",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/8-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/8-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6596-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6596-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6596-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6596-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6596-40-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6596-40-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6596-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6596-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6596-40-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6596-40-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 12939,
        price_in_aed: "0.84",
        final_price_with_margin: "10.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Keychains",
    master_code: "MO6897",
    master_id: "40009014",
    type_of_products: "stock",
    commodity_code: "8531 8070",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "FINIT",
    category_code: "MOBOFF_TELACC",
    product_class: "Tools",
    dimensions: "5,4X2,8X0,8 CM",
    length: "5.4",
    length_unit: "cm",
    width: "2.8",
    width_unit: "cm",
    height: "0.8",
    height_unit: "cm",
    volume: "0.075",
    volume_unit: "cdm3",
    gross_weight: "0.028",
    gross_weight_unit: "kg",
    net_weight: "0.023",
    net_weight_unit: "kg",
    outer_carton_quantity: "200",
    carton_length: "0.37",
    carton_length_unit: "m",
    carton_width: "0.23",
    carton_width_unit: "m",
    carton_height: "0.174",
    carton_height_unit: "m",
    carton_volume: "0.015",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.6",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6897-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6897-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6897-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo6897-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo6897-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo6897-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo6897-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo6897-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo6897-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6897-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Key finder device in bamboo",
    long_description:
      "4.2 Wireless anti-loss/ key finder device made in bamboo casing. Requires a free app available in both iOS and Android. Attach the device to your bag, keys or luggage and whenever your belongings are lost the device will help you to find them. The additional features available with this item are remote shutter/selfie button and phone finder function. 1 CR2032 battery included. Range distance 35m outside/15minside. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    printable: "yes",
    variants: [
      {
        variant_id: "10224467",
        sku: "MO6897-03",
        release_date: "2023-03-19",
        product_proposition_category: "321",
        category_level1: "Premiums & Tools",
        category_level2: "Key rings",
        category_level3: "Basic",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941065550",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/9-with-logo.jpg",
            url_highress: "/assets/img/accessories/home-living/9-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6897-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6897-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6897-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6897-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6897-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6897-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6897-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6897-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6897-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6897-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6897-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6897-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2080-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2080-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 2612,
        price_in_aed: "15.68",
        final_price_with_margin: "31.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Keychains",
    master_code: "MO8664",
    master_id: "40005778",
    type_of_products: "stock",
    commodity_code: "8205 5100",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "COLOUR TWICES",
    category_code: "MOBP&S_KERMUL",
    product_class: "Key ring",
    dimensions: "8X1X2CM",
    length: "8",
    length_unit: "cm",
    width: "8",
    width_unit: "cm",
    height: "1",
    height_unit: "cm",
    volume: "0.037",
    volume_unit: "cdm3",
    gross_weight: "0.011",
    gross_weight_unit: "kg",
    net_weight: "0.01",
    net_weight_unit: "kg",
    inner_carton_quantity: "200",
    outer_carton_quantity: "1000",
    carton_length: "0.675",
    carton_length_unit: "m",
    carton_width: "0.215",
    carton_width_unit: "m",
    carton_height: "0.275",
    carton_height_unit: "m",
    carton_volume: "0.04",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.58",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo8664-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8664-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo8664-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Key ring bottle opener",
    long_description: "Aluminium key ring bottle opener in shiny finish.",
    material: "Aluminium",
    printable: "yes",
    variants: [
      {
        variant_id: "10201320",
        sku: "MO8664-03",
        release_date: "2016-01-01",
        product_proposition_category: "288",
        category_level1: "Eating & Drinking",
        category_level2: "Barware",
        category_level3: "Bottle openers",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941023857",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/10-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/home-living/10-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8664-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8664-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
        ],
        available_quantity: 1819,
        price_in_aed: "0.92",
        final_price_with_margin: "10.50",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Keychains",
    master_code: "MO8622",
    master_id: "40005662",
    type_of_products: "stock",
    commodity_code: "8513 1000",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ARIZO",
    category_code: "MOBP&S_KERKRL",
    product_class: "Key ring",
    dimensions: "Ø1,6X6,5 CM",
    length: "6.5",
    length_unit: "cm",
    width: "1.6",
    width_unit: "cm",
    height: "6.5",
    height_unit: "cm",
    volume: "0.065",
    volume_unit: "cdm3",
    gross_weight: "0.03",
    gross_weight_unit: "kg",
    net_weight: "0.023",
    net_weight_unit: "kg",
    inner_carton_quantity: "50",
    outer_carton_quantity: "500",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.25",
    carton_width_unit: "m",
    carton_height: "0.3",
    carton_height_unit: "m",
    carton_volume: "0.032",
    carton_volume_unit: "m3",
    carton_gross_weight: "15.05",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo8622-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo8622-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo8622-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo8622-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo8622-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo8622-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
    ],
    short_description: "Aluminium torch with key ring",
    long_description:
      "Mini aluminium torch with 1 LED key ring . 4 batteries LR44 included. Small and compact to fit on your keyring. Perfect for walking your dog in the evening, and less struggle opening doors in the dark. To see or not to see, that is the question.",
    material: "Battery",
    printable: "yes",
    variants: [
      {
        variant_id: "10198914",
        sku: "MO8622-03",
        release_date: "2015-01-01",
        product_proposition_category: "323",
        category_level1: "Premiums & Tools",
        category_level2: "Key rings",
        category_level3: "Lights",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941006133",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/11-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/home-living/11-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8622-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8622-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo8622-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo8622-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
        ],
        available_quantity: 9342,
        price_in_aed: "3.72",
        final_price_with_margin: "15.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Picnic-Set",
    master_code: "MO6193",
    master_id: "40007895",
    type_of_products: "stock",
    commodity_code: "4602 1200",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "MIMBRE",
    category_code: "MOBL&G_PIC",
    product_class: "Sport & receation accessories",
    dimensions: "46X30X20CM",
    length: "46",
    length_unit: "cm",
    width: "30",
    width_unit: "cm",
    height: "20",
    height_unit: "cm",
    volume: "37.191",
    volume_unit: "cdm3",
    gross_weight: "3.22",
    gross_weight_unit: "kg",
    net_weight: "2.56",
    net_weight_unit: "kg",
    outer_carton_quantity: "1",
    carton_length: "0.48",
    carton_length_unit: "m",
    carton_width: "0.22",
    carton_width_unit: "m",
    carton_height: "0.32",
    carton_height_unit: "m",
    carton_volume: "0.034",
    carton_volume_unit: "m3",
    carton_gross_weight: "3.18",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6193-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6193-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6193-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6193-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Wicker picnic basket 2 people",
    long_description:
      "Wicker 2 person picnic basket set with 1 bottle opener, 2 stainless steel spoons, forks and knives, 2 ceramic plates, 2 glasses and 2 polycotton napkins.",
    material: "Wood",
    commercial_description:
      "2 person wicker picnic basket set. It comes with 1 bottle opener, 2 stainlesssteel spoons, forks and knives, 2 ceramic plates, 2 glasses and 2 polycottonnapkins. All nicely attached to the lid, so the entire basket can be filled withdelicious food and drinks. Easy to take with you by carrying it by the stylishwhite handle. Spending time outdoors whilst enjoying a picnic or barbeque isalways a great experience. The relaxing and easy going outdoor environment is agreat way to catch up and spend some quality time with family and friends.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10215563",
        sku: "MO6193-40",
        release_date: "2020-12-27",
        product_proposition_category: "243",
        category_level1: "Sports & Recreation",
        category_level2: "Outdoor",
        category_level3: "Picnic & Camping",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941052215",
        color_code: "40",
        pms_color: "WICKER",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6193-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6193-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6193-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6193-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6193-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6193-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6193-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6193-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6193-40-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6193-40-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 504,
        price_in_aed: "227.88",
        final_price_with_margin: "570.00",
      },
    ],
  },
  {
    main_category_name: "Home-Living",
    category_name: "Pizza-Cutter",
    master_code: "MO6592",
    master_id: "40008583",
    type_of_products: "stock",
    commodity_code: "8205 5100",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ZAZA",
    category_code: "MOBH&L_KACKKN",
    product_class: "Kitchen accessories",
    dimensions: "22X10X2CM",
    length: "22",
    length_unit: "cm",
    width: "10",
    width_unit: "cm",
    height: "2",
    height_unit: "cm",
    volume: "0.58",
    volume_unit: "cdm3",
    gross_weight: "0.101",
    gross_weight_unit: "kg",
    net_weight: "0.064",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.555",
    carton_length_unit: "m",
    carton_width: "0.23",
    carton_width_unit: "m",
    carton_height: "0.42",
    carton_height_unit: "m",
    carton_volume: "0.054",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.12",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6592-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6592-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6592-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Pizza cutter bamboo handle",
    long_description:
      "Axe shaped pizza cutter with bamboo handle. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Bamboo",
    commercial_description:
      "Axe shaped pizza cutter with bamboo handle. Cut through pizzas easily with thisfun axe cutter. A great gift for those who love funny, but practical gadgets.Bamboo is a natural product, there may be slight variations in colour and sizeper item, which can affect the final decoration outcome.",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10222474",
        sku: "MO6592-03",
        release_date: "2022-07-31",
        product_proposition_category: "279",
        category_level1: "Eating & Drinking",
        category_level2: "Kitchenware",
        category_level3: "Utensils",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941058446",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/home-living/12-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/home-living/12-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6592-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6592-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6592-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6592-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6592-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6592-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6592-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6592-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6592-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6592-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6592-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6592-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 627,
        price_in_aed: "7.28",
        final_price_with_margin: "24.50",
      },
    ],
  },
  {
    main_category_name: "Games",
    category_name: "Coast-Game",
    master_code: "MO6382",
    master_id: "40008280",
    type_of_products: "stock",
    commodity_code: "4419 9000",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "COASTGAME",
    category_code: "MOBL&G_GAMOTH",
    product_class: "Wine & bar accessoires",
    dimensions: "10,6X10,6X7CM",
    length: "10.6",
    length_unit: "cm",
    width: "10.6",
    width_unit: "cm",
    height: "7",
    height_unit: "cm",
    volume: "1.15",
    volume_unit: "cdm3",
    gross_weight: "0.452",
    gross_weight_unit: "kg",
    net_weight: "0.386",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.425",
    carton_length_unit: "m",
    carton_width: "0.245",
    carton_width_unit: "m",
    carton_height: "0.245",
    carton_height_unit: "m",
    carton_volume: "0.026",
    carton_volume_unit: "m3",
    carton_gross_weight: "9.04",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6382-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6382-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6382-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo6382-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "4-piece coaster game set",
    long_description:
      "Set of 4 coasters made of cork and MDF including a 4 game set: Tic-Tac- Toe, Backgammon, Chess and Chinese checkers. Cork is 100% natural material. Due to its structural nature and surface porosity the final print result per item may have deviations.",
    material: "MDF",
    commercial_description:
      "Set of 4 coasters made of cork and MDF including a 4 game set: Tic-Tac- Toe, Backgammon, Chess and Chinese checkers. When the coasters are not in use to hold glasses or other drinkware, you can play one of these 4 fun games on them. Great for kids, but also a guaranteed fun evening for adults!",
    printable: "yes",
    variants: [
      {
        variant_id: "10221838",
        sku: "MO6382-40",
        release_date: "2021-12-24",
        product_proposition_category: "289",
        category_level1: "Eating & Drinking",
        category_level2: "Barware",
        category_level3: "Coasters",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941055292",
        color_code: "40",
        pms_color: "WOOD",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6382-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6382-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6382-40-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6382-40-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6382-40-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6382-40-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6382-40-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6382-40-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6382-40-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6382-40-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6382-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6382-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 2131,
        price_in_aed: "30.28",
        final_price_with_margin: "42.39",
      },
    ],
  },
  {
    main_category_name: "Fitness-Wellness",
    category_name: "Workout-Bluetooth-Speaker",
    master_code: "MO2211",
    master_id: "40009768",
    type_of_products: "stock",
    commodity_code: "8518 2200",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "YELLOW",
    category_code: "MOBS&I_SPK",
    product_class: "Speakers",
    dimensions: "Ø6,7X17,1 CM",
    length: "188",
    length_unit: "cm",
    width: "6.7",
    width_unit: "cm",
    height: "17.1",
    height_unit: "cm",
    volume: "1.233",
    volume_unit: "cdm3",
    gross_weight: "0.417",
    gross_weight_unit: "kg",
    net_weight: "0.367",
    net_weight_unit: "kg",
    outer_carton_quantity: "30",
    carton_length: "0.395",
    carton_length_unit: "m",
    carton_width: "0.385",
    carton_width_unit: "m",
    carton_height: "0.242",
    carton_height_unit: "m",
    carton_volume: "0.037",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.5",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2211-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2211-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2211-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-rohs/mo2211-test-rohs.pdf",
        type: "document",
        subtype: "test_rohs",
      },
      {
        url: "https://cdn1.midocean.com/document/test-emc/mo2211-test-emc.pdf",
        type: "document",
        subtype: "test_emc",
      },
      {
        url: "https://cdn1.midocean.com/document/test-lowvolt/mo2211-test-lowvolt.pdf",
        type: "document",
        subtype: "test_lowvolt",
      },
      {
        url: "https://cdn1.midocean.com/document/test-red-rtte/mo2211-test-red-rtte.pdf",
        type: "document",
        subtype: "test_red_rtte",
      },
      {
        url: "https://cdn1.midocean.com/document/test-battery/mo2211-test-battery.pdf",
        type: "document",
        subtype: "test_battery",
      },
      {
        url: "https://cdn1.midocean.com/document/test-un38-3/mo2211-test-un38-3.pdf",
        type: "document",
        subtype: "test_un38_3",
      },
    ],
    short_description: "2x5 Waterproof speaker IPX4",
    long_description:
      "2x5W wireless portable speaker with 1200 mAh rechargeable battery. Includes AUX, USB, TF. Playing time approx. 5 hours.",
    material: "ABS",
    printable: "yes",
    variants: [
      {
        variant_id: "10236115",
        sku: "MO2211-03",
        release_date: "2023-12-22",
        product_proposition_category: "161",
        category_level1: "Technology & Accessories",
        category_level2: "Audio & Sound",
        category_level3: "Speakers",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941071124",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/fitness-wellness/1-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/fitness-wellness/1-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2211-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2211-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2211-03-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2211-03-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2211-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2211-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2211-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2211-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2211-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2211-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2211-03-picture5.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2211-03-picture5.jpg",
            type: "image",
            subtype: "item_picture_5",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2211-03-picture6.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2211-03-picture6.jpg",
            type: "image",
            subtype: "item_picture_6",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2211-03-picture7.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2211-03-picture7.jpg",
            type: "image",
            subtype: "item_picture_7",
          },
        ],
        available_quantity: 725,
        price_in_aed: "44.60",
        final_price_with_margin: "81.00",
      },
    ],
  },
  {
    main_category_name: "Fitness-Wellness",
    category_name: "Gym-Water-Bottle",
    master_code: "MO6799",
    master_id: "40008896",
    type_of_products: "stock",
    commodity_code: "9617 0000",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "LOUC",
    category_code: "MOBH&L_THE",
    product_class: "Drinking bottle",
    liquid_volume: "580",
    liquid_volume_unit: "ml",
    dimensions: "Ø7X24CM",
    length: "0",
    length_unit: "cm",
    width: "7",
    width_unit: "cm",
    height: "24",
    height_unit: "cm",
    volume: "1.7",
    volume_unit: "cdm3",
    gross_weight: "0.405",
    gross_weight_unit: "kg",
    net_weight: "0.303",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.395",
    carton_length_unit: "m",
    carton_width: "0.32",
    carton_width_unit: "m",
    carton_height: "0.27",
    carton_height_unit: "m",
    carton_volume: "0.034",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.4",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6799-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6799-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6799-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6799-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-performance/mo6799-test-performance.pdf",
        type: "document",
        subtype: "test_performance",
      },
    ],
    short_description: "Double wall bottle 580 ml",
    long_description:
      "Double wall stainless steel vacuum insulated bottle with integrated straw or mouth piece and lid with hanger. Capacity 580 ml.",
    material: "Stainless Steel",
    printable: "yes",
    variants: [
      {
        variant_id: "10223642",
        sku: "MO6799-03",
        release_date: "2022-12-26",
        product_proposition_category: "270",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Insulated bottles",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941063228",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/fitness-wellness/2-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/fitness-wellness/2-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6799-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6799-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6799-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6799-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6799-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6799-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6799-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6799-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 0,
        price_in_aed: "27.80",
        final_price_with_margin: "58.00",
      },
    ],
  },
  {
    main_category_name: "Fitness-Wellness",
    category_name: "Salad-Box",
    master_code: "MO6855",
    master_id: "40008949",
    type_of_products: "stock",
    commodity_code: "3924 1000",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "INDUS",
    category_code: "MOBH&L_KACFCO",
    product_class: "Kitchen accessories",
    dimensions: "21X11.5X5.5CM",
    length: "21",
    length_unit: "cm",
    width: "11.5",
    width_unit: "cm",
    height: "5.5",
    height_unit: "cm",
    volume: "1.9",
    volume_unit: "cdm3",
    gross_weight: "0.217",
    gross_weight_unit: "kg",
    net_weight: "0.159",
    net_weight_unit: "kg",
    outer_carton_quantity: "30",
    carton_length: "0.665",
    carton_length_unit: "m",
    carton_width: "0.255",
    carton_width_unit: "m",
    carton_height: "0.335",
    carton_height_unit: "m",
    carton_volume: "0.057",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.48",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-01T09:06:42",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6855-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6855-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6855-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6855-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-performance/mo6855-test-performance.pdf",
        type: "document",
        subtype: "test_performance",
      },
    ],
    short_description: "Recycled PP Lunch box 800 ml",
    long_description:
      "Recycled PP lunch box with airtight lid, silicone valve and two side buckles. Capacity: 800 ml.",
    material: "Recycled PP",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10223748",
        sku: "MO6855-03",
        release_date: "2022-12-26",
        product_proposition_category: "283",
        category_level1: "Eating & Drinking",
        category_level2: "Lunchware",
        category_level3: "Lunch boxes",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941064294",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/fitness-wellness/3-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/fitness-wellness/3-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6855-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6855-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6855-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6855-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6855-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6855-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
        ],
        available_quantity: 14669,
        price_in_aed: "13.44",
        final_price_with_margin: "39.00",
      },
    ],
  },
  {
    main_category_name: "Fitness-Wellness",
    category_name: "Fitness-Bag",
    master_code: "MO9577",
    master_id: "40007130",
    type_of_products: "stock",
    commodity_code: "4202 9291",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "TIRANA",
    category_code: "MOBT&B_SPO",
    product_class: "Sport bag",
    dimensions: "22X10X39 CM",
    length: "22",
    length_unit: "cm",
    width: "10",
    width_unit: "cm",
    height: "39",
    height_unit: "cm",
    volume: "1.52",
    volume_unit: "cdm3",
    gross_weight: "0.162",
    gross_weight_unit: "kg",
    net_weight: "0.127",
    net_weight_unit: "kg",
    outer_carton_quantity: "25",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.28",
    carton_width_unit: "m",
    carton_height: "0.29",
    carton_height_unit: "m",
    carton_volume: "0.035",
    carton_volume_unit: "m3",
    carton_gross_weight: "3.7",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-08-05T19:00:19",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9577-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9577-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9577-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Backpack with front pocket",
    long_description:
      "Backpack in 600D polyester with zippered outside pocket and for comfort a padded back section in 210D polyester.",
    material: "PET",
    printable: "yes",
    variants: [
      {
        variant_id: "10210767",
        sku: "MO9577-03",
        release_date: "2019-01-01",
        product_proposition_category: "200",
        category_level1: "Bags & Travel",
        category_level2: "Sport & Outdoor bags",
        category_level3: "Sport bags",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941038523",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/fitness-wellness/4-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/fitness-wellness/4-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9577-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9577-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9577-03a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9577-03a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
        ],
        available_quantity: 0,
        price_in_aed: "12.32",
        final_price_with_margin: "35.00",
      },
    ],
  },
  {
    main_category_name: "Fitness-Wellness",
    category_name: "Fitness-Armband",
    master_code: "MO6239",
    master_id: "40008066",
    type_of_products: "stock",
    commodity_code: "4202 9298",
    number_of_print_positions: "1",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ARMPHONE +",
    category_code: "MOBOFF_TELMOP",
    product_class: "Smartphone accessories",
    dimensions: "45X18,5X0,4 CM",
    length: "45",
    length_unit: "cm",
    width: "18.5",
    width_unit: "cm",
    height: "0.4",
    height_unit: "cm",
    volume: "0.255",
    volume_unit: "cdm3",
    gross_weight: "0.056",
    gross_weight_unit: "kg",
    net_weight: "0.041",
    net_weight_unit: "kg",
    outer_carton_quantity: "200",
    carton_length: "0.49",
    carton_length_unit: "m",
    carton_width: "0.275",
    carton_width_unit: "m",
    carton_height: "0.54",
    carton_height_unit: "m",
    carton_volume: "0.073",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.1",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6239-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6239-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo6239-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Large neoprene phone pouch",
    long_description:
      "Adjustable armband in neoprene with transparent front with reflective detail. Fit up to 6.5 inch screen phones.",
    material: "Neoprene",
    commercial_description:
      "Adjustable smartphone armband in neoprene with a transparent front withreflective detail. This armband is great for when you are working out and stillwant to listen to music or keep your mobile phone with your. A must have whenyou are jogging or sporting in the gym. Thanks to the reflective detail yourvisibility in the dark will also be better, which is a lot safer when you workout late. The holder fits up to 6.5 inch screen smartphones.",
    printable: "yes",
    variants: [
      {
        variant_id: "10215831",
        sku: "MO6239-03",
        release_date: "2021-08-01",
        product_proposition_category: "186",
        category_level1: "Technology & Accessories",
        category_level2: "Phone accessories",
        category_level3: "Phone stand/holders",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941053106",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/fitness-wellness/5-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/fitness-wellness/5-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6239-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6239-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6239-03-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6239-03-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6239-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6239-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6239-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6239-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 29605,
        price_in_aed: "6.52",
        final_price_with_margin: "19.50",
      },
    ],
  },
  {
    main_category_name: "Fitness-Wellness",
    category_name: "Skipping-Rope",
    master_code: "MO6217",
    master_id: "40007919",
    type_of_products: "stock",
    commodity_code: "9506 9190",
    number_of_print_positions: "5",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "SNEL",
    category_code: "MOBL&G_GAMOTH",
    product_class: "Games & Events",
    dimensions: "14.5X300CM",
    length: "14.5",
    length_unit: "cm",
    width: "300",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.38",
    volume_unit: "cdm3",
    gross_weight: "0.126",
    gross_weight_unit: "kg",
    net_weight: "0.116",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.35",
    carton_length_unit: "m",
    carton_width: "0.35",
    carton_width_unit: "m",
    carton_height: "0.31",
    carton_height_unit: "m",
    carton_volume: "0.038",
    carton_volume_unit: "m3",
    carton_gross_weight: "12.62",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6217-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6217-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6217-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-toys/mo6217-test-toys.pdf",
        type: "document",
        subtype: "test_toys",
      },
    ],
    short_description: "Speed jumping rope RPET pouch",
    long_description: "Adjustable speed jumping rope in 210D RPET pouch.",
    material: "PP",
    commercial_description:
      "Adjustable speed jumping rope in a 210D RPET pouch. You can adjust the handleson this skipping rope to increase or decrease the speed. A jumping rope is ahealthy way to workout your entire body. Take the rope with you in the pouch tothe gym or anywhere else where you want to exercise.",
    printable: "yes",
    variants: [
      {
        variant_id: "10215639",
        sku: "MO6217-03",
        release_date: "2020-12-27",
        product_proposition_category: "237",
        category_level1: "Sports & Recreation",
        category_level2: "Sport & Health",
        category_level3: "Fitness",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941052666",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "/assets/img/accessories/fitness-wellness/6-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/fitness-wellness/6-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6217-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6217-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6217-03-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6217-03-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6217-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6217-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
        ],
        available_quantity: 4213,
        price_in_aed: "9.60",
        final_price_with_margin: "27.00",
      },
    ],
  },
  {
    main_category_name: "Fitness-Wellness",
    category_name: "Yoga-Mat",
    master_code: "MO9463",
    master_id: "40007026",
    type_of_products: "stock",
    commodity_code: "3921 1900",
    number_of_print_positions: "3",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "YOGI",
    category_code: "MOBS&R_FIT",
    product_class: "Sport & receation accessories",
    dimensions: "180X60X0.4CM",
    length: "180",
    length_unit: "cm",
    width: "60",
    width_unit: "cm",
    height: "0.4",
    height_unit: "cm",
    volume: "7.762",
    volume_unit: "cdm3",
    gross_weight: "0.283",
    gross_weight_unit: "kg",
    net_weight: "0.224",
    net_weight_unit: "kg",
    outer_carton_quantity: "25",
    carton_length: "0.63",
    carton_length_unit: "m",
    carton_width: "0.55",
    carton_width_unit: "m",
    carton_height: "0.55",
    carton_height_unit: "m",
    carton_volume: "0.191",
    carton_volume_unit: "m3",
    carton_gross_weight: "7.08",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9463-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9463-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9463-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Yoga mat EVA 4.0 mm with pouch",
    long_description:
      "Yoga exercise mat made of 0,4 EVA material. Including a mesh/polyester pouch.",
    material: "EVA",
    printable: "yes",
    variants: [
      {
        variant_id: "10210382",
        sku: "MO9463-04",
        release_date: "2019-01-01",
        product_proposition_category: "237",
        category_level1: "Sports & Recreation",
        category_level2: "Sport & Health",
        category_level3: "Fitness",
        color_description: "Blue",
        color_group: "Blue",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941035010",
        color_code: "04",
        pms_color: "287U",
        digital_assets: [
          {
            url: "/assets/img/accessories/fitness-wellness/7-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/fitness-wellness/7-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9463-04.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9463-04.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9463-04a.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9463-04a.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9463-04b.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9463-04b.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
        ],
        available_quantity: 3260,
        price_in_aed: "24.64",
        final_price_with_margin: "83.50",
      },
    ],
  },
  {
    main_category_name: "Fitness-Wellness",
    category_name: "Bath-Set",
    master_code: "MO9872",
    master_id: "40007574",
    type_of_products: "stock",
    commodity_code: "6302 9390",
    number_of_print_positions: "2",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "CUIDA SET",
    category_code: "MOBPER_BTA",
    product_class: "Wellness & Care accessories",
    dimensions: "22,5X15 CM",
    length: "22.5",
    length_unit: "cm",
    width: "15",
    width_unit: "cm",
    height: "0",
    height_unit: "cm",
    volume: "0.995",
    volume_unit: "cdm3",
    gross_weight: "0.101",
    gross_weight_unit: "kg",
    net_weight: "0.098",
    net_weight_unit: "kg",
    outer_carton_quantity: "100",
    carton_length: "0.46",
    carton_length_unit: "m",
    carton_width: "0.4",
    carton_width_unit: "m",
    carton_height: "0.55",
    carton_height_unit: "m",
    carton_volume: "0.101",
    carton_volume_unit: "m3",
    carton_gross_weight: "10.72",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9872-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9872-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9872-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Bath set in cotton pouch",
    long_description:
      "Bath set including sponge, stone and puff presented in jute bag with PEVA window.",
    material: "EVA",
    printable: "yes",
    variants: [
      {
        variant_id: "10214019",
        sku: "MO9872-13",
        release_date: "2020-01-01",
        product_proposition_category: "345",
        category_level1: "Wellness & Care",
        category_level2: "Personal care",
        category_level3: "Bath accessories",
        color_description: "Beige",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941047419",
        color_code: "13",
        pms_color: "NATURAL & WHITE",
        digital_assets: [
          {
            url: "/assets/img/accessories/fitness-wellness/8-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/fitness-wellness/8-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9872-13.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9872-13.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9872-13-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9872-13-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9872-13-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9872-13-side.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
        ],
        available_quantity: 7827,
        price_in_aed: "9.44",
        final_price_with_margin: "28.50",
      },
    ],
  },
  {
    main_category_name: "Fitness-Wellness",
    category_name: "Fragrance-Set",
    master_code: "MO9702",
    master_id: "40007336",
    type_of_products: "stock",
    commodity_code: "3307 4900",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "AROMA",
    category_code: "MOBH&L_DECOTH",
    product_class: "Wellness & Care accessories",
    dimensions: "5X5X22 CM",
    length: "5",
    length_unit: "cm",
    width: "5",
    width_unit: "cm",
    height: "22",
    height_unit: "cm",
    volume: "0.991",
    volume_unit: "cdm3",
    gross_weight: "0.237",
    gross_weight_unit: "kg",
    net_weight: "0.168",
    net_weight_unit: "kg",
    inner_carton_quantity: "25",
    outer_carton_quantity: "50",
    carton_length: "0.6",
    carton_length_unit: "m",
    carton_width: "0.31",
    carton_width_unit: "m",
    carton_height: "0.264",
    carton_height_unit: "m",
    carton_volume: "0.049",
    carton_volume_unit: "m3",
    carton_gross_weight: "11.86",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-07-30T09:26:46",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9702-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9702-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9702-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-reach/mo9702-test-reach.pdf",
        type: "document",
        subtype: "test_reach",
      },
    ],
    short_description: "Aroma diffusor",
    long_description:
      "Aroma diffusor with 3 reed sticks. Beech wood top and glass. Fragrance Lily and Jasmine. Capacity 30 ml. As wood is a natural material, the colour per item can vary. Top wood part absorbs/assimilates oil which helps spread fragrance.",
    material: "Glass Soda Lime",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10212666",
        sku: "MO9702-40",
        release_date: "2019-08-01",
        product_proposition_category: "350",
        category_level1: "Wellness & Care",
        category_level2: "Home & Living",
        category_level3: "Aroma diffusers",
        color_description: "Wood",
        color_group: "Brown",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941042810",
        color_code: "40",
        pms_color: "NATURAL",
        digital_assets: [
          {
            url: "/assets/img/accessories/fitness-wellness/9-with-logo.jpg",
            url_highress:
              "/assets/img/accessories/fitness-wellness/9-with-logo.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9702-40.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9702-40.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9702-40-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9702-40-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9702-40-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9702-40-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9702-40-ambiant.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9702-40-ambiant.jpg",
            type: "image",
            subtype: "item_ambiant_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9702-40-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9702-40-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 0,
        price_in_aed: "21.96",
        final_price_with_margin: "46.50",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Eco-Mugs",
    master_code: "MO6934",
    master_id: "40009149",
    type_of_products: "stock",
    commodity_code: "9617 0000",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "INARI",
    category_code: "MOBH&L_THEMUG",
    product_class: "Cups, mugs & glasses",
    dimensions: "11.5X11.3CM",
    length: "0",
    length_unit: "cm",
    width: "11.3",
    width_unit: "cm",
    height: "11.5",
    height_unit: "cm",
    volume: "1.7",
    volume_unit: "cdm3",
    gross_weight: "0.265",
    gross_weight_unit: "kg",
    net_weight: "0.215",
    net_weight_unit: "kg",
    outer_carton_quantity: "20",
    carton_length: "0.57",
    carton_length_unit: "m",
    carton_width: "0.23",
    carton_width_unit: "m",
    carton_height: "0.26",
    carton_height_unit: "m",
    carton_volume: "0.034",
    carton_volume_unit: "m3",
    carton_gross_weight: "5.3",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-10-08T16:36:15",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo6934-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo6934-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo6934-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo6934-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
      {
        url: "https://cdn1.midocean.com/document/test-performance/mo6934-test-performance.pdf",
        type: "document",
        subtype: "test_performance",
      },
    ],
    short_description: "Double wall mug 300 ml",
    long_description:
      "Double wall recycled stainless steel (90% recycled stainless and 10% stainless steel) mug with sliding sip hole cover. Capacity: 300 ml.",
    material: "Recycled Stainless Steel",
    printable: "yes",
    variants: [
      {
        variant_id: "10224866",
        sku: "MO6934-03",
        release_date: "2023-07-28",
        product_proposition_category: "273",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Mugs & Tumblers",
        color_description: "Black",
        color_group: "Black",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941066243",
        color_code: "03",
        pms_color: "BLACK",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6934-03.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6934-03.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6934-03-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6934-03-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6934-03-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6934-03-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6934-03-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6934-03-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo6934-03-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo6934-03-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 10711,
        price_in_aed: "21.96",
        final_price_with_margin: "48.50",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Eco-Mugs",
    master_code: "MO2091",
    master_id: "40009125",
    type_of_products: "stock",
    commodity_code: "7013 3799",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "CELESTIAL",
    category_code: "MOBH&L_CUPONE",
    product_class: "Cups, mugs & glasses",
    dimensions: "11X10CM",
    length: "11",
    length_unit: "cm",
    width: "10",
    width_unit: "cm",
    height: "10",
    height_unit: "cm",
    volume: "1.2",
    volume_unit: "cdm3",
    gross_weight: "0.433",
    gross_weight_unit: "kg",
    net_weight: "0.4",
    net_weight_unit: "kg",
    outer_carton_quantity: "30",
    carton_length: "0.43",
    carton_length_unit: "m",
    carton_width: "0.355",
    carton_width_unit: "m",
    carton_height: "0.235",
    carton_height_unit: "m",
    carton_volume: "0.036",
    carton_volume_unit: "m3",
    carton_gross_weight: "13",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-11-15T09:09:48",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo2091-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo2091-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo2091-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo2091-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "Recycled glass mug 300 ml",
    long_description:
      "Recycled glass mug with bamboo lid. Capacity: 300 ml. Bamboo is a natural product, there may be slight variations in colour and size per item, which can affect the final decoration outcome.",
    material: "Glass",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10226222",
        sku: "MO2091-22",
        release_date: "2023-07-28",
        product_proposition_category: "271",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Water bottles",
        color_description: "Transparent",
        color_group: "White",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941068407",
        color_code: "22",
        pms_color: "TRANSPARENT",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2091-22.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2091-22.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2091-22-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2091-22-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2091-22-side.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2091-22-side.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2091-22-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2091-22-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2091-22-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2091-22-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2091-22-print.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2091-22-print.jpg",
            type: "image",
            subtype: "item_picture_printed",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2091-22-detail.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2091-22-detail.jpg",
            type: "image",
            subtype: "item_detail_picture",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo2091-22-box.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo2091-22-box.jpg",
            type: "image",
            subtype: "item_picture_box",
          },
        ],
        available_quantity: 8671,
        price_in_aed: "21.96",
        final_price_with_margin: "44.50",
      },
    ],
  },
  {
    main_category_name: "Eco-Friendly",
    category_name: "Eco-Bottles",
    master_code: "MO9940",
    master_id: "40007747",
    type_of_products: "stock",
    commodity_code: "3924 9000",
    number_of_print_positions: "4",
    country_of_origin: "CN",
    brand: "midocean",
    product_name: "ICELAND RPET",
    category_code: "MOBH&L_DRI",
    product_class: "Drinking bottle",
    liquid_volume: "780",
    liquid_volume_unit: "ml",
    dimensions: "Ø7X24.5CM",
    length: "0",
    length_unit: "cm",
    width: "7",
    width_unit: "cm",
    height: "24.5",
    height_unit: "cm",
    volume: "1.728",
    volume_unit: "cdm3",
    gross_weight: "0.158",
    gross_weight_unit: "kg",
    net_weight: "0.125",
    net_weight_unit: "kg",
    outer_carton_quantity: "25",
    carton_length: "0.4",
    carton_length_unit: "m",
    carton_width: "0.27",
    carton_width_unit: "m",
    carton_height: "0.395",
    carton_height_unit: "m",
    carton_volume: "0.043",
    carton_volume_unit: "m3",
    carton_gross_weight: "3.86",
    carton_gross_weight_unit: "kg",
    timestamp: "2024-11-15T18:04:07",
    digital_assets: [
      {
        url: "https://cdn1.midocean.com/document/declaration-of-sustainability/mo9940-green.pdf",
        type: "document",
        subtype: "declaration_of_sustainability",
      },
      {
        url: "https://cdn1.midocean.com/document/declaration-of-conformity/mo9940-doc.pdf",
        type: "document",
        subtype: "declaration_of_conformity",
      },
      {
        url: "https://cdn1.midocean.com/document/manual/mo9940-manual.pdf",
        type: "document",
        subtype: "manual",
      },
      {
        url: "https://cdn1.midocean.com/document/test-fca/mo9940-test-fca.pdf",
        type: "document",
        subtype: "test_fca",
      },
    ],
    short_description: "RPET bottle 780ml",
    long_description:
      "Drinking bottle in RPET with lid in stainless steel and TPR grip making it easy to carry. BPA free. Leak free. Capacity: 780 ml. Not suitable  for carbonated drinks.",
    material: "RPET",
    green: "yes",
    printable: "yes",
    variants: [
      {
        variant_id: "10215014",
        sku: "MO9940-27",
        release_date: "2020-08-01",
        product_proposition_category: "271",
        category_level1: "Eating & Drinking",
        category_level2: "Drinkware",
        category_level3: "Water bottles",
        color_description: "Transparent Grey",
        color_group: "Grey",
        plc_status: "16",
        plc_status_description: "COLLECTION",
        gtin: "8719941049413",
        color_code: "27",
        pms_color: "BLACKC",
        digital_assets: [
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9940-27.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9940-27.jpg",
            type: "image",
            subtype: "item_picture_front",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9940-27-back.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9940-27-back.jpg",
            type: "image",
            subtype: "item_picture_back",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9940-27b.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9940-27b.jpg",
            type: "image",
            subtype: "item_picture_side",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9940-27-top.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9940-27-top.jpg",
            type: "image",
            subtype: "item_picture_top",
          },
          {
            url: "https://cdn1.midocean.com/image/700X700/mo9940-27-open.jpg",
            url_highress:
              "https://cdn1.midocean.com/image/original/mo9940-27-open.jpg",
            type: "image",
            subtype: "item_picture_open",
          },
        ],
        available_quantity: 19978,
        price_in_aed: "21.96",
        final_price_with_margin: "33.00",
      },
    ],
  },
];

export default AccessoriesProducts;
