import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Login() {

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        {/* Start Page Title */}
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>My Account</h2>
              <ul>
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li>Login</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Title */}
        {/* Start Login Area */}
        <section className="login-area ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="login-content">
                  <h2>Login</h2>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Username or email address"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                      />
                    </div>
                    <button type="submit" className="default-btn">
                      Login
                    </button>
                    <Link to="/Home" className="forgot-password">
                      Lost your password?
                    </Link>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="new-customer-content">
                  <h2>New Customer</h2>
                  <span>Create A Account</span>
                  <p>
                    Sign up for a free account at our store. Registration is
                    quick and easy. It allows you to be able to order from our
                    shop. To start shopping click register.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Login Area */}
      </div>
    </div>
  );
}

export default Login;
